const directions = {
    month : {next:'day'},
    day: {prev:'month',next:'year'},
    year: {prev:'day',next:'hours'},
    hours: {prev:'year',next:'minutes'},
    minutes: {prev:'hours'}
}

const months = {
    1: 31,
    2: 28,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31
  };

 const handleDateChange = (e,attr,data,setData,dayRefs) => {
    let name = e.target.name
    let value = e.target.value
    if (!(/^\d*$/.test(value))){
        setData({...data,
            [attr]: {...data[attr],[name]:data[attr][name]}
        })
    }
    else{
        value = value.replace(/[^0-9]/g, '')
        value = parseInt(value,10)
        if (name === "month"){
            let paddedValue = String(value).padStart(2, '0')
            if (value >= 2 && String(value).length === 1){
                setData({...data,
                    [attr]: {...data[attr],[name]:paddedValue}
                })
                dayRefs.current[attr].day.focus()
            }
            else if (parseInt(value) > 12){
                return
            }
            else if (parseInt(value) >= 10){
                setData({...data,
                    [attr]: {...data[attr],[name]:value}
                })
                dayRefs.current[attr].day.focus() 
            }
            else{
                setData({...data,
                    [attr]: {...data[attr],[name]:paddedValue}
                })
            }
        }

        if (name === "day"){
            let paddedValue = String(value).padStart(2, '0')
            if (value >= 4 && String(value).length === 1){
                setData({...data,
                    [attr]: {...data[attr],[name]:paddedValue}
                })
                dayRefs.current[attr].year.focus()
            }
            else if ((parseInt(value) > 31 && !data[attr].month) || (data[attr].month && parseInt(value) > months[parseInt(data[attr].month)])){
                return
            }
            else if (parseInt(value) >= 10){
                setData({...data,
                    [attr]: {...data[attr],[name]:value}
                })
                dayRefs.current[attr].year.focus() 
            }
            else{
                setData({...data,
                    [attr]: {...data[attr],[name]:paddedValue}
                })
            }
        }

        if (name === "year"){
            if (String(value).length > 4){
                return
            }
            let paddedValue = String(value).padStart(4, '0')
            setData({...data,
                [attr]: {...data[attr],[name]:paddedValue}
            })
            if (value > 1000){
                dayRefs.current[attr].hours.focus() 
            }
        }
    }
}

 const formatDate = (data,attr) => {
    let year = parseInt(data[attr].year)
    let monthIndex = parseInt(data[attr].month) - 1
    let day = parseInt(data[attr].day)
    let hours = parseInt(data[attr === "start_date" ? "start_time" : "end_time"].hours) || 0
    let minutes = parseInt(data[attr === "start_date" ? "start_time" : "end_time"].minutes) || 0
    let formatted_date = new Date(year, monthIndex, day, hours, minutes)
    if (!(formatted_date instanceof Date && !isNaN(formatted_date))){
        return null
    } 
    else{
        return  (formatted_date.getFullYear() === year &&
        formatted_date.getMonth() === monthIndex &&
        formatted_date.getDate() === day) ? formatted_date : null
    }
}


 const handleDateBlur = (e,attr,data,setData,errors,setErrors) => {
    let value = e.target.value
    let name = e.target.name
    value = parseInt(value)
    if (!value){
        setData({...data,
            [attr]: {...data[attr],[name]:null}
        })
        e.target.value = null
    }
}

const validateDate = (e,dateBox,data,attr,errors,setErrors) => {
    if (!dateBox?.contains(e.relatedTarget)){
        if (data[attr].month !== null && data[attr].year !== null && data[attr].day !== null){
            if (!formatDate(data,attr)){
                setErrors({...errors,[attr]:'Invalid date'})
            }
            if (formatDate(data,'start_date') && formatDate(data,'end_date')){
                if (formatDate(data,'start_date') > formatDate(data,'end_date')){
                    setErrors({...errors,end_date:"End date should be bigger than start date"})
                }
            }
        }
    }
}


const handleTimeChange = (e,attr,data,setData,dayRefs) => {
    let name = e.target.name
    let value = e.target.value
    value = value.replace(/[^0-9]/g, '')
    value = parseInt(value,10)
    if (name === "hours"){
        let paddedValue = String(value).padStart(2, '0')
        if (value >= 3 && String(value).length === 1){
            setData({...data,
                [attr]: {...data[attr],[name]:paddedValue}
            })
            dayRefs.current[attr === "end_time" ? "end_date" : "start_date"].minutes.focus()
        }
        else if (parseInt(value) > 23){
            return
        }
        else if (parseInt(value) >= 10){
            setData({...data,
                [attr]: {...data[attr],[name]:value}
            })
            dayRefs.current[attr === "end_time" ? "end_date" : "start_date"].minutes.focus() 
        }
        else{
            setData({...data,
                [attr]: {...data[attr],[name]:paddedValue}
            })
        }
    }

    if (name === "minutes"){
        let paddedValue = String(value).padStart(2, '0')
        if (value >= 6 && String(value).length === 1){
            setData({...data,
                [attr]: {...data[attr],[name]:paddedValue}
            })
        }
        else if (parseInt(value) > 59){
            return
        }
        else if (parseInt(value) >= 10){
            setData({...data,
                [attr]: {...data[attr],[name]:value}
            })
        }
        else{
            setData({...data,
                [attr]: {...data[attr],[name]:paddedValue}
            })
        }
    }
}

const handleDateKeyDown = (e,attr,dayRefs) => {
    let name = e.target.name
    if (e.keyCode === 37 && directions[name].prev){
        let previousEl = directions[name].prev
        dayRefs.current[attr][previousEl].focus()
    }
    if (e.keyCode === 39 && directions[name].next){
        let nextEl = directions[name].next
        dayRefs.current[attr][nextEl].focus()
    }
}

export const FormUtils = {
    handleDateChange,
    formatDate,
    handleDateBlur,
    handleTimeChange,
    handleDateKeyDown,
    validateDate
}