import React, { useEffect, useRef, useState } from 'react'
import styles from "../css/DeleteModal.module.css"
import { IoClose } from 'react-icons/io5'
import { RiGift2Fill } from "react-icons/ri";

const DeletePromoCode = ({id,closeModal}) => {
    const [data,setData] = useState(null)
    const [code,setCode] = useState("")
    const [loading,setLoading] = useState(false)
    const fetching = useRef(true)

    useEffect(()=>{
        if (fetching.current){
            fetching.current = false
            setTimeout(()=>{
                setData({
                    code: "CHRISTMAS20",
                    num_diamonds:85
                })
            },500)
        }
    },[])

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
    }

  return (
    <div className = {styles.wrapper}>
        {data ? <form className = {styles.box} onSubmit = {handleSubmit}>
            <header>
                <div className = {styles.title}>Permanently Delete<span>&nbsp;-</span> <div className = {styles.giftIcon}><RiGift2Fill/></div> Promo Code</div>
                <div className = {styles.closeIcon} onClick = {()=>closeModal()}><IoClose/></div>
            </header>
            <main className = {styles.body}>
                <div className = {styles.text}>You are about to permanently delete promocode - <span>{data.code}</span> that offers free <span>{data.num_diamonds} diamonds</span> to Astroora users. You will not be able to recover this promo code and users will no longer be able to use it during the payement process. <span>This action cannot be undone</span></div>
                <label htmlFor = "code">Please Type in the promo code to confirm</label>
                <input type = "text" name = "code" value = {code} onChange = {(e)=>setCode(e.target.value)}/>
            </main>
            <footer>
                {loading ? 
                <div className = {styles.loadingBtn}><div className = {styles.spinner}></div></div> :
                <button type = "submit" disabled = {code.trim() !== data.code}>Delete this promo code</button>
                }
            </footer>
        </form> : <div className = {styles.box}>
            <header>
                <div className = {styles.title}>Permanently Delete<span>&nbsp;-</span> <div className = {styles.giftIcon}><RiGift2Fill/></div> Promo Code</div>
                <div className = {styles.closeIcon} onClick = {()=>closeModal()}><IoClose/></div>
            </header>
            <div className = {styles.loadingArea}>
                <div className = {styles.loadingSpinner}></div>
            </div>
        </div>}
    </div>
  )
}

export default DeletePromoCode