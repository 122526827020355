import React, { useRef, useState } from 'react'
import styles from "../css/IntentionText.module.css"
import Feather from "../media/feather.png"

const IntentionText = ({intention,setIntention,handleCheck}) => {
  const inputRef = useRef(null)
  const [val,setVal] = useState(intention)

  const handleChange = (e) => {
    handleCheck()
    let value = e.target.value
    if (value.length > 250){
      inputRef.current.classList.add(styles.shake)
      setTimeout(()=>{
        inputRef.current.classList.remove(styles.shake)
      },850)
    }
    value = value.substring(0,270)
    setVal(value)
  }

  const submit = () => {
    setIntention(val)
    console.log("value",val)
  }

  return (
    <div className = {styles.wrapper}>
      <div className = {styles.innerContainer}>
        <div className = {styles.gameTitle}>Tarots</div>
        <div className = {styles.menuBtn}>Menu</div>
      <div className = {styles.box}>
        <div className = {styles.stone}></div>
        <div className = {styles.container}>
            <div className = {styles.titleWrapper}>
              <div className = {styles.leftDec}></div>
              <div className = {styles.titleBox}>
                <div className = {styles.icon}><img src = {Feather} alt = ""/></div>
                <div className = {styles.title}>Intention</div>
              </div>
              <div className = {styles.rightDec}></div>
            </div>
            <div className = {styles.textContainer} ref = {inputRef}>
                <textarea rows = {9} name = "intention" value = {val} onChange = {handleChange} placeholder = "Write your intention in details here."/>
            </div>
            <div className = {styles.btns}>
            <button type = "button" className = {styles.sendBtn} onClick = {()=>submit()}>
              <div className = {styles.innerBtn}>Send</div>
            </button>
            <div className = {styles.characterLength}>Character Count: {intention.length}/270</div>
            </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default IntentionText