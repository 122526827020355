import React from 'react'
import styles from "../css/Menu.module.css"
import { TbUserCircle } from "react-icons/tb";
import { TbShieldLock } from "react-icons/tb";
import { CgScreen } from "react-icons/cg";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { BiDollarCircle } from "react-icons/bi";

const menuItems = {
    profile: {icon:<TbUserCircle/>,name:"Profile Settings"},
    security: {icon:<TbShieldLock/>,name:"Account Security"},
    appearance: {icon:<CgScreen/>,name:"Appearance"},
    subscription: {icon:<HiOutlineViewGridAdd/>,name:"Subscription Plans"},
    billing: {icon:<BiDollarCircle/>,name:"Billing & Payments"},
}
const Menu = ({modal,onHandleMenu}) => {
  return (
    <div className = {styles.menu}>
        <div className = {styles.title}>Settings</div>
        <div className = {styles.menuItems}>
            {Object.keys(menuItems).map((key,index)=>(
                <div className = {`${styles.menuItem} ${modal === key && styles.active}`} key = {index} onClick = {()=>onHandleMenu(key)}>
                    <div className = {styles.menuIcon}>{menuItems[key].icon}</div>
                    <div className = {styles.menuText}>{menuItems[key].name}</div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Menu