import React, { useEffect, useRef, useState } from 'react'
import styles from "../css/DeleteUser.module.css"
import { IoClose } from 'react-icons/io5'
import { PiTrash } from "react-icons/pi";
import { BsCheckLg } from "react-icons/bs";
import axios from 'axios';

const DeleteUser = ({id,reload,closeModal}) => {
  const [email,setEmail] = useState(null)
  const [confirmEmail,setConfirmEmail] = useState("")
  const [deleteData,setDeleteData] = useState(false)
  const [crashError,setCrashError] = useState(false)
  const [notFound,setNotFound] = useState(false)
  const [loading,setLoading] = useState(false)
  const fetching = useRef(true)

  useEffect(()=>{
    if (fetching.current){
      fetching.current = false
      const start  = performance.now()
      axios.get(`${process.env.REACT_APP_API_URL}/admin/users/${id}`)
      .then((res)=>{
          const end = performance.now()
          const waiting_time = end - start < 500 ? (500 - end - start) : 0
          setTimeout(()=>{
              setEmail(res.data.email)
          },waiting_time)
      })
      .catch((err)=>{
          if (err?.response?.status === 404){
              setNotFound(true)
          }
          else{
              setCrashError(true)
          }
      })
    }
  },[id])

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const start = performance.now()
    axios.delete(`${process.env.REACT_APP_API_URL}/admin/users/delete/${id}`,{delete_date: deleteData})
    .then((_)=>{
        const end = performance.now()
        const waiting_time = end - start < 500 ? (500 - end - start) : 0
        setTimeout(()=>{
            reload()
            closeModal()
        },waiting_time)
    })
    .catch((_)=>{
        const end = performance.now()
        const waiting_time = end - start < 500 ? (500 - end - start) : 0
        setTimeout(()=>{
             setCrashError(true)
        },waiting_time)
    })
  }

  return (
    <div className = {styles.wrapper}>
      {email ? <form className = {styles.box} onSubmit = {handleSubmit}>
        <header>
          <div className = {styles.deleteIcon}><PiTrash/></div>
          <div className = {styles.title}>Delete User</div>
          <div className = {styles.closeIcon} onClick = {()=>closeModal()}><IoClose/></div>
        </header>
        <main>
          <div className = {styles.warningBox}>
            This will permanently delete the selected user. All the user profile details, dream interpertations, tarot readings and transactions will also be deleted if the data deletion is checked. This action cannot be undone.
          </div>
          <div className = {styles.content}>You are about to delete the account linked to <span>{email}</span>. Check the box below if you want to delete <span>all data</span> pertaining to the selected user as well. To finish the deletion process, type the user's email in the input area for confirmation.</div>
          <div className = {styles.checkArea}>
            <div className = {`${styles.checkSquare} ${deleteData && styles.checked}`} onClick = {()=>setDeleteData(!deleteData)}>
              <div className = {styles.checkIcon}><BsCheckLg/></div>
            </div>
            <div className = {styles.text}>Delete User Data</div>
          </div>
          <div className = {styles.inputArea}>
            <label htmlFor = {confirmEmail}>Type user's email to confirm</label>
            <input type = "text" name = "confirmEmail" value = {confirmEmail} onChange = {(e)=>setConfirmEmail(e.target.value)}/>
          </div>
        </main>
        <footer>
          {loading ? 
            <div className = {styles.loadingBtn}><div className = {styles.spinner}></div></div> :
            <button type = "submit" disabled = {email !== confirmEmail}>Delete this user</button>
          }
        </footer>
      </form> : 
      <div className = {styles.box}>
        <header>
          <div className = {styles.deleteIcon}><PiTrash/></div>
          <div className = {styles.title}>Delete User</div>
          <div className = {styles.closeIcon} onClick = {()=>closeModal()}><IoClose/></div>
        </header>
        <div className = {styles.loadingArea}>
          <div className = {styles.loadingSpinner}></div>
        </div>
      </div>}
    </div>
  )
}

export default DeleteUser