import React, { useEffect, useState } from 'react'
import NavigationBar from './NavigationBar'
import styles from "../css/PageComponent.module.css"
import UserMenu from './UserMenu'
import { getUserData } from '../../../utils/auth'
import { CgFontSpacing } from 'react-icons/cg'

const PageComponent = ({element,page,loading = false}) => {
    const [showMenu,setShowMenu] = useState(false)
    const [name,setName] = useState(getUserData().fullname || "")
    const [avatar,setAvatar] = useState(getUserData().avatar || null)

    useEffect(()=>{
        console.log("LocalStorage = ",localStorage.getItem("user"),`url(${process.env.REACT_APP_API_URL}/profileImages/${avatar})` )
        const handleStorage = (e) => {
          if (e.key === "user"){
            setName(getUserData().fullname || "")
            setAvatar(getUserData().avatar || "")
          }
        }
        window.addEventListener("storage",handleStorage)
        return () => {
          window.removeEventListener("storage",handleStorage)
        }
      },[])

  return (
    <div className = {styles.wrapper}>
        <NavigationBar page = {page} loading = {loading}/>
        <div className = {styles.box}>
            {element}
        </div>
        <div className = {styles.menuSection}>
            <div className = {styles.profileWrapper} id = "menu-box">
                <div className = {styles.profileBtn} onClick = {()=>setShowMenu(!showMenu)} style = {{  backgroundImage: avatar ? `url(${process.env.REACT_APP_API_URL}/profileImages/${avatar})` : "none"}}>{!avatar && name?.charAt(0)}</div>
                {showMenu && <UserMenu close = {()=>setShowMenu(false)}/>}
            </div>
        </div>
    </div>

        
  )
}

export default PageComponent