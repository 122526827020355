import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from  "../css/Users.module.css"
import NavBar from './NavBar'
import { FaUsers } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { TiArrowSortedDown } from "react-icons/ti";
import { FaUserPlus } from "react-icons/fa6";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import CreateUser from './CreateUser';
import { FiEdit3 } from 'react-icons/fi';
import { CgTrash } from 'react-icons/cg';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';
import ViewUser from './ViewUser';
import useFormatTable from './useFormatTable';
import { BiSortAlt2 } from "react-icons/bi";
import axios from 'axios';
import { debounce } from 'lodash';


const Users = ({skeleton = false}) => {
    const [searchValue,setSearchValue] = useState("")
    const [showSort,setShowSort] = useState("")
    const [sortParams,setSortParams] = useState({field:"date_joined",order:"desc"})
    const [users,setUsers] = useState(null)
    const [filtering,setFiltering] = useState(false)
    const [searching,setSearching] = useState(false)
    const searchTimer = useRef(null)
    const fetchParams = useRef({fetching:true,fetched:false})
    const [tab,setTab] = useState("all")
    const [page,setPage] = useState(1)
    const [pageInput,setPageInput] = useState("1")
    const [numPages,setNumPages] = useState(1)
    const pageRef = useRef(null)
    const [showAddModal,setShowAddModal] = useState(false)
    const [showEditModal,setShowEditModal] = useState(null)
    const [showDeleteModal,setShowDeleteModal] = useState(null)
    const [showViewModal,setShowViewModal] = useState(null)
    const tableHeader = useRef(null)
    const tableBody = useRef(null)
    const _ = useFormatTable(tableHeader.current,tableBody.current)
    const cancelToken = useRef(null)

    const fetchUsers = useCallback(()=>{
        if (cancelToken.current) {
            cancelToken.current.cancel();
          }
        const source = axios.CancelToken.source();
        cancelToken.current = source;
        setFiltering(true)
        let url = `${process.env.REACT_APP_API_URL}/admin/users/?search=${encodeURIComponent(searchValue)}`;
        if (tab !== "all") {
            if (tab === "admin"){
                url += '&is_admin=true'
            }
            else if (tab === "active"){
                url += '&is_active=true'
            }
            else{
                url += '&is_active=false'
            }
        }
        url += `&sort_by=${sortParams.field}&sort_order=${sortParams.order}`
        axios.get(url,{cancelToken: source.token})
        .then((res)=>{
            setUsers(res.data.items)
            setPage(res.data.page)
            setNumPages(res.data.pages)
            setFiltering(false)
        })
        .catch((err)=>{
            if (!axios.isCancel(err)) {
                setFiltering(false)
            }
        })
    },[searchValue,sortParams,tab,setFiltering])

    useEffect(()=>{
        if (fetchParams.current?.fetching){
            fetchParams.current.fetching = false
            axios.get(`${process.env.REACT_APP_API_URL}/admin/users`)
            .then((res)=>{
                fetchParams.current.fetched = true
                setUsers(res.data.items)
                setPage(res.data.page)
                setNumPages(res.data.pages)
            })
        }
        if (fetchParams.current?.fetched){
            const debouncedFetch = debounce(fetchUsers, 300)
            debouncedFetch()
        }
    },[fetchUsers])

    useEffect(() => {
        const input = pageRef.current;
        if (input) {
          input.style.width = `${input.value.length}ch`;
        }
      }, [page]);

    const handlePageChange = (e) => {
        let value = e.target.value
        if (value === '' || /^[0-9]*$/.test(value)) {
            setPageInput(value)
        }
    }

    const setCurrentPage = () => {
        if (pageInput){
            setPage(Number(pageInput))
        }
        else{
            setPageInput(page.toString())
        }
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13){
            setCurrentPage()
        }
    }

    const setTableBody = useCallback((el)=>{
        if (el){
            if (el?.previousElementSibling){
                const header = el.previousElementSibling
                if (el.scrollHeight > el.clientHeight) {
                    const scrollbarWidth = el.offsetWidth - el.clientWidth;
                    header.style.paddingRight = `${scrollbarWidth}px`;
                }
                else{
                    header.style.paddingRight = '0px';
                }
            }
            tableBody.current = el
        }
    },[])

    const debouncedFetchUsers = debounce(fetchUsers, 350);

    const handleSearchChange = (e) => {
        let value = e.target.value
        setSearchValue(value)
        if (searchTimer.current){
            clearTimeout(searchTimer.current)
            setSearching(true)
        }
        searchTimer.current = setTimeout(()=>{
            setSearching(false)
        },500)
    }

  return (
    <div className = {`${styles.wrapper} ${styles.users}`}>
        <NavBar page = "users"/>
        <div className = {`${styles.box} ${styles.users}`}>
            <header>
                <div className = {styles.mainHeader}>
                    <div className = {styles.userIcon}><FaUsers/></div>
                    <div className = {styles.title}>Users</div>
                </div>
                <div className = {styles.playerBtn}>
                    Switch to player mode
                </div>
            </header>
            {!users ? <SkeletonPage/> : <main>
                <div className = {styles.categories}>
                    <div className = {`${styles.category} ${tab === "all" && styles.selected}`} onClick = {()=>setTab("all")}>
                        <div className = {styles.categoryTitle}>All</div>
                        <div className = {styles.categoryLength}>48</div>
                    </div>
                    <div className = {`${styles.category} ${tab === "admin" && styles.selected}`} onClick = {()=>setTab("admin")}>
                        <div className = {styles.categoryTitle}>Admin</div>
                        <div className = {styles.categoryLength}>8</div>
                    </div>
                    <div className = {`${styles.category} ${tab === "active" && styles.selected}`} onClick = {()=>setTab("active")}>
                        <div className = {styles.categoryTitle}>Active</div>
                        <div className = {styles.categoryLength}>30</div>
                    </div>
                    <div className = {`${styles.category} ${tab === "inactive" && styles.selected}`} onClick = {()=>setTab("inactive")}>
                        <div className = {styles.categoryTitle}>Pending</div>
                        <div className = {styles.categoryLength}>10</div>
                    </div>
                </div>
                <div className = {styles.searchWrapper}>
                    <div className = {styles.searchBox}>
                        <div className = {styles.searchIcon}><IoIosSearch/></div>
                        <input type = "text" name = "searchValue" value = {searchValue} onChange = {handleSearchChange} placeholder = "Search by name or email"/>
                    </div>
                    <div className = {styles.btns}>
                        <div className = {styles.sortBtnWrapper} tabIndex = {-1} onBlur = {()=>setShowSort(false)}>
                            <div className = {styles.sortBtn} onClick = {()=>setShowSort(!showSort)}>
                                <div className = {styles.text}>Sort by</div>
                                <div className = {styles.arrow}><TiArrowSortedDown/></div>
                                <div className = {styles.sortIcon}><BiSortAlt2/></div>
                            </div>
                            {showSort && <div className = {styles.sortMenu}>
                                <div className = {styles.sortItem} onClick = {()=>setSortParams({field:"fullname",order:"asc"})}>Name <span>- A To Z</span></div>
                                <div className = {styles.sortItem} onClick = {()=>setSortParams({field:"fullname",order:"asc"})}>Name <span>- Z to A</span></div>
                                <div className = {styles.sortItem} onClick = {()=>setSortParams({field:"balance",order:"desc"})}>Balance <span>- Highest To Lowest</span></div>
                                <div className = {styles.sortItem} onClick = {()=>setSortParams({field:"balance",order:"asc"})}>Balance <span>- Lowest To Highest</span></div>
                                <div className = {styles.sortItem} onClick = {()=>setSortParams({field:"date_joined",order:"desc"})}>Date Joined <span>- Newest To Oldest</span></div>
                                <div className = {styles.sortItem} onClick = {()=>setSortParams({field:"date_joined",order:"asc"})}>Date Joined <span>- Oldest To newest</span></div>
                            </div>}
                        </div>
                        <div className = {styles.addBtn} onClick = {()=>setShowAddModal(true)}>
                            <div className = {styles.addIcon}><FaUserPlus/></div>
                            <div className = {styles.addText}>New User</div>
                        </div>
                    </div>
                </div>
                {(filtering || searching) ? <SkeletonTable/> : <div className = {styles.content}>
                    <div className = {styles.table}>
                        <div className = {styles.tableContent}>
                            <div className = {styles.tableHeader} ref = {tableHeader}>
                                <div className = {`${styles.headerCol} ${styles.name}`}>Name</div>
                                <div className = {`${styles.headerCol} ${styles.email}`}>Email</div>
                                <div className = {`${styles.headerCol} ${styles.role}`}>Role</div>
                                <div className = {`${styles.headerCol} ${styles.status}`}>Status</div>
                                <div className = {`${styles.headerCol} ${styles.balance}`}>Balance</div>
                                <div className = {`${styles.headerCol} ${styles.num_dreams}`}>N&deg; Dream Readings</div>
                                <div className = {`${styles.headerCol} ${styles.num_tarots}`}>N&deg; Tarot Readings</div>
                                <div className = {`${styles.headerCol} ${styles.actions}`}>actions</div>
                            </div>
                            <div className = {styles.tableBody} ref = {setTableBody}>
                                {users.map((user, index) => (
                                    <div className = {styles.row} key={index} onClick = {()=>setShowViewModal(user.id)}>
                                        <div data-label = "Name" className = {`${styles.bodyCol} ${styles.name}`}>{user.fullname}</div>
                                        <div data-label = "Email" className = {`${styles.bodyCol} ${styles.email}`}>{user.email}</div>
                                        <div data-label = "Role" className = {`${styles.bodyCol} ${styles.role}`}><div className = {`${styles.statusBox}`}>{user.is_admin ? "Admin" : "Basic User"}</div></div>
                                        <div data-label = "Status" className = {`${styles.bodyCol} ${styles.status}`}><div className = {`${styles.statusBox} ${user.is_active ? "active": "inactive"}`}>{user.is_active ? "Active" : "Inactive"}</div></div>
                                        <div data-label = "Balance" className = {`${styles.bodyCol} ${styles.balance}`}>{user.balance}</div>
                                        <div data-label = "N&deg; Dream Readings" className = {`${styles.bodyCol} ${styles.num_dreams}`}>{user.num_dreams}</div>
                                        <div data-label = "N&deg; Tarot Readings" className = {`${styles.bodyCol} ${styles.num_tarots}`}>{user.num_tarots}</div>
                                        <div data-label = "Actions" className = {`${styles.bodyCol} ${styles.actions}`}>
                                            <div className = {styles.btns}>
                                                <div className = {styles.editBtn} onClick = {()=>setShowEditModal(user.id)}><FiEdit3/></div>
                                                <div className = {styles.deleteBtn} onClick = {()=>setShowDeleteModal(user.id)}><CgTrash/></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className = {styles.tableFooter}>
                            <div className = {styles.displayedLength}>Showing 20 out of 245</div>
                            <div className = {styles.pager}>
                                {page !== 1 && <div className = {`${styles.pageArrow} ${styles.prev}`}><MdKeyboardArrowLeft/></div>}
                                <div className = {styles.pagerBox}>
                                    <input ref = {pageRef} type = "text" name = "pageInput" value = {pageInput} onChange = {handlePageChange} onBlur = {()=>setCurrentPage()} onKeyDown = {handleKeyDown}/>
                                    / {numPages}
                                </div>
                                <div className = {`${styles.pageArrow} ${styles.next}`}><MdKeyboardArrowRight/></div>
                            </div>
                        </div>
                    </div>
                </div>}
            </main>}
       </div>
       {showAddModal && <CreateUser reload = {()=>debouncedFetchUsers()} closeModal = {()=>setShowAddModal(false)}/>}
       {showEditModal !== null && <EditUser reload = {()=>debouncedFetchUsers()} id = {showEditModal} closeModal = {()=>setShowEditModal(null)}/>}
       {showDeleteModal !== null && <DeleteUser reload = {()=>debouncedFetchUsers()} id = {showDeleteModal} closeModal={()=>setShowDeleteModal(null)}/>}
       {showViewModal !== null && <ViewUser reload = {()=>debouncedFetchUsers()} id = {showViewModal} closeModal={()=>setShowViewModal(null)} handleEdit={()=>{setShowEditModal(showViewModal);setShowViewModal(null);}} handleDelete={()=>{setShowDeleteModal(showViewModal);setShowViewModal(null)}}/>}
    </div>
  )
}

const SkeletonTable = () => {
    return (
    <div className = {`${styles.content} ${styles.skeleton}`}>
        <div className = {styles.table}>
            <div className = {styles.tableContent}>
                <div className = {styles.tableHeader}>
                    <div className = {`${styles.headerCol} ${styles.name}`}>Name</div>
                    <div className = {`${styles.headerCol} ${styles.email}`}>Email</div>
                    <div className = {`${styles.headerCol} ${styles.role}`}>Status</div>
                    <div className = {`${styles.headerCol} ${styles.status}`}>Status</div>
                    <div className = {`${styles.headerCol} ${styles.balance}`}>Balance</div>
                    <div className = {`${styles.headerCol} ${styles.num_dreams}`}>N&deg; Dream Readings</div>
                    <div className = {`${styles.headerCol} ${styles.num_tarots}`}>N&deg; Tarot Readings</div>
                    <div className = {`${styles.headerCol} ${styles.actions}`}>actions</div>
                </div>
                <div className = {styles.tableBody}>
                    {[...Array(7).keys()].map((_, index) => (
                        <div className = {styles.row} key={index}>
                            <div data-label = "Name" className = {`${styles.bodyCol} ${styles.name}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "Email" className = {`${styles.bodyCol} ${styles.email}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "Role" className = {`${styles.bodyCol} ${styles.role}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "Status" className = {`${styles.bodyCol} ${styles.status}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "Balance" className = {`${styles.bodyCol} ${styles.balance}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "N&deg; Dream Readings" className = {`${styles.bodyCol} ${styles.num_dreams}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "N&deg; Tarot Readings" className = {`${styles.bodyCol} ${styles.num_tarots}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "Actions" className = {`${styles.bodyCol} ${styles.actions}`}>
                                <div className = {styles.btns}>
                                    <div className = {styles.editBtn}></div>
                                    <div className = {styles.deleteBtn}></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className = {styles.tableFooter}>
                <div className = {styles.skeletonLength}></div>
            </div>
        </div>
    </div>
    )
}

const SkeletonPage = () => {
    return(
        <main className = {styles.skeleton}>
            <div className = {styles.categories}>
                <div className = {styles.skeletonCategory}></div>
                <div className =  {styles.skeletonCategory}></div>
                <div className =  {styles.skeletonCategory}></div>
                <div className =  {styles.skeletonCategory}></div>
            </div>
            <div className = {styles.searchWrapper}>
                <div className = {styles.searchBox}></div>
                <div className = {styles.btns}>
                    <div className = {styles.skeletonSortBtn}></div>
                    <div className = {styles.skeletonAddBtn}></div>
                </div>
            </div>
            <SkeletonTable/>
        </main>
    )
}

export default Users