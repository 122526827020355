import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/TarotBox.module.css"
import { TbTargetArrow } from "react-icons/tb";
import { FaPlay } from "react-icons/fa";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { HiOutlineCalendar } from "react-icons/hi2";
import data from "../../tarotGame/data/cardInfo.json"
import spread_data from "../../tarotGame/data/spread.json"
import { icons } from './ViewTarots';
import { IoBookmarkOutline } from "react-icons/io5";
import { IoBookmark } from "react-icons/io5";
import { BsFillQuestionDiamondFill } from "react-icons/bs";
import TarotMenu from './TarotMenu';


const monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const spreads = {...spread_data['three_cards'],...spread_data['five_cards']}

const TarotBox = ({tarot_data,handleMenu,card_index}) => {
    const [showMenu,setShowMenu] = useState(false)
    const tooltips = useRef({})
    const btnRef = useRef(null)

    useEffect(()=>{
        const handleScroll = () => {
            if (showMenu){
                setShowMenu(false)
            }
        }
        let grid = document.getElementById("tarots-grid")
        if (grid){
            grid.addEventListener("scroll",handleScroll)
        }
        return () => {
            if (grid){
                grid.removeEventListener("scroll",handleScroll)
            }
        }
    },[showMenu])

    const creation_date = useMemo(()=>{
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        yesterday.setHours(0, 0, 0, 0)
        const inputDate = new Date(tarot_data.created_at)
        inputDate.setHours(0, 0, 0, 0)
        if (inputDate.getTime() === today.getTime()) {
            return "Today"
        }
        else if (inputDate.getTime() === yesterday.getTime()) {
            return "Yesterday"
        } 
        else {
            return monthsShort[inputDate.getMonth()] + " " + inputDate.getDate() + ", " + inputDate.getFullYear()
        }
    },[tarot_data.created_at])

    const handleView = (e) => {
        if (!btnRef.current.contains(e.target)){
            handleMenu("view")
        }
    }

    const positionToolTip = useCallback((el)=>{
        if (el){
            const top = el.getBoundingClientRect().top
            let grid = document.getElementById("tarots-grid")
            const isTopVisible = top >= grid?.getBoundingClientRect().top
            if (!isTopVisible){
                el.classList.add(styles.bottom)
            }
            else{
                el.classList.remove(styles.bottom)
            }
        }
    },[])

    const setTooltips = useCallback((el,index)=>{
        if (el){
            tooltips.current[index] = el
        }
    },[])

    const resume = (e) =>  {
        e.stopPropagation()
        handleMenu("resume")
    }

    const handleMouseEnter = (index) => {
        positionToolTip(tooltips.current[index]);
      };


  return (
    <div className = {styles.box} onClick = {handleView}>
        <div className = {`${styles.cardsArea} ${tarot_data.cards.length === 3 ? styles.max : ""}`}>
            {tarot_data.cards.map((card,index)=>(
                <div className = {styles.card} key = {index} onMouseEnter = {()=>handleMouseEnter(index)}>
                    <img src = {`${process.env.PUBLIC_URL}/tarotImages/${card.card_id}.jpg`} alt = ""/>
                    <div className = {styles.tooltip} ref = {(el)=>setTooltips(el,index)}>{data[(card.card_id).toString()].dataLabel}</div>
                </div>
            ))}
        </div>
        <div className = {styles.content}>
            <div className = {styles.doubleWrapper}>
                <div className = {styles.spreadBox}>
                    <div className = {styles.spreadIcon}>{icons[tarot_data.spread]}</div>
                    <div className = {styles.spreadName}>{spreads[tarot_data.spread]?.name}</div>
                </div>
                <div className = {`${styles.bookMarkButton} ${!tarot_data.bookMarked && styles.selected}`}>
                    <div className = {styles.bookMarked}><IoBookmark/></div>
                    <div className = {styles.unbookMarked}><IoBookmarkOutline/></div>
                </div>
            </div>
            <div className = {styles.intentionBox}>
                <div className = {styles.labelBox}>
                    <div className = {styles.targetIcon}><TbTargetArrow/></div> Intention: 
                </div>
                <div className = {styles.intention}>{tarot_data.intention}</div>
            </div>
        </div>
        <div className = {styles.footer}>
            <div className = {styles.dateBox}>
                <div className = {styles.calendarIcon}><HiOutlineCalendar/></div>
                <div className = {styles.date}>{creation_date}</div>
            </div>
            <div className = {styles.btns}>
                {tarot_data.questions === 0 && <div className = {styles.questionBox}>
                    <div className = {styles.questionIcon}><BsFillQuestionDiamondFill/></div>
                    <div className = {styles.questionNum}>{tarot_data.questions}</div>
                </div>}
                <div type = "button" className = {styles.resumeWrapper} onClick = {resume}><div className = {styles.resumeBtn} onClick = {resume}><FaPlay/></div></div>
                <div className = {styles.menuWrapper} ref = {btnRef}>
                    <div type = "button" className = {`${styles.ellipsisBtn} ${showMenu && styles.active}`} onClick = {()=>setShowMenu(!showMenu)}><IoEllipsisVerticalSharp/></div>
                    {showMenu && <TarotMenu tarotMenu = {true} handleMenu = {(key)=>handleMenu(key)} close = {()=>setShowMenu(false)}/>}
                </div>
            </div>
        </div>
    </div>
  )
}

export default TarotBox