import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/Readings.module.css"
import SortMenu from './SortMenu'
import FilterMenu from './FilterMenu'
import TarotBox from './TarotBox'
import Game from "../../tarotGame/js/Game"
import DeleteTarot from './DeleteTarot'
import ViewTarots from './ViewTarots'
import { LuSearch } from "react-icons/lu";
import { IoFilterSharp } from "react-icons/io5";
import { PiArrowsDownUpBold } from "react-icons/pi";
import { TbPlus } from "react-icons/tb";
import api from '../../../utils/api'
import LoadingReadings, { LoadingTarot } from './LoadingReadings'
import axios from 'axios'
import CrashPage from '../../errors/js/CrashPage'
import { IoSearch } from "react-icons/io5";
import { GiCardRandom } from 'react-icons/gi'
import CardImage from "../media/cards.png"

const Readings = () => {
  const [showFilterMenu,setShowFilterMenu] = useState(false)
  const [showSortMenu,setShowSortMenu] = useState(false)
  const [filterParams,setFilterParams] = useState({created_at:null,spreads:[],cards:[]})
  const [sortParams,setSortParams] = useState({field:"updated_at",order:"-"})
  const [showPopUps,setShowPopUps] = useState({new_tarot:null,delete:null,view:null})
  const [searchValue,setSearchValue] = useState("")
  const [tarots,setTarots] = useState(null)
  const [crashError,setCrashError] = useState("")
  const fetching = useRef(true)
  const fetched = useRef(false)
  const [searching,setSearching] = useState(false)
  const [filtering,setFiltering] = useState(false)
  const searchTimer = useRef(null)
  const cancelToken = useRef(null)

    const fetchData = useCallback(()=>{
        if (cancelToken.current) {
            cancelToken.current.cancel('Request canceled due to new search');
          }
        const source = axios.CancelToken.source();
        cancelToken.current = source;
        setFiltering(true)
        let url = `/tarotReadings?search=${encodeURIComponent(searchValue)}`;
        if (filterParams.spreads.length > 0) {
          url += '&' + filterParams.spreads.map(spread => `spreads=${encodeURIComponent(spread)}`).join('&')
        }
        if (filterParams.created_at !== null) {
          url += `&created_at=${filterParams.created_at}`
        }
        if (filterParams.cards.length > 0){
            url += '&' + filterParams.cards.map(card => `cards=${encodeURIComponent(card)}`).join('&')
        }
        url += `&sort_by=${sortParams.field}&sort_order=${sortParams.order}`
        api.get(url,{cancelToken: source.token})
        .then((res)=>{
            setTarots(res.data)
            setFiltering(false)
        })
        .catch((err)=>{
            if (!axios.isCancel(err)) {
                setFiltering(false)
            }
            else{
                setCrashError("Something unexpected occured while trying to retrieve your tarot readings. Please, try again later")
            }
        })
    },[searchValue,filterParams,sortParams])

  useEffect(()=>{
        if (fetching.current){
            fetching.current = false
            api.get('/tarotReadings')
            .then((res)=>{
                setTarots(res.data)
                fetched.current = true
            })
            .catch((err)=>{
                setCrashError("Something unexpected occured while trying to retrieve your tarot readings. Please, try again later")
            })
        }

        if (fetched.current){
            fetchData()
        }
  },[fetchData])


  const [elementsPerRow, setElementsPerRow] = useState(3);

  useEffect(() => {
      const handleResize = () => {
          const width = window.innerWidth;
          if (width > 1320){
            setElementsPerRow(4)
          }
          else if (width <= 1320 & width > 1000) { 
            setElementsPerRow(3);
          }
          else if (width > 640) {
            setElementsPerRow(2)
          }
          else { 
              setElementsPerRow(1);
          }
      };

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => {
          window.removeEventListener('resize', handleResize);
      };
    }, []);

    const rows = useMemo(()=>{
        if (tarots){
            return tarots.reduce((rowsArray, item, index) => {
                if (index % elementsPerRow === 0) {
                    rowsArray.push([]);
                }
                rowsArray[rowsArray.length - 1].push(item);
                return rowsArray;
            }, []);
        }
        else{
            return []
        }

    },[tarots,elementsPerRow])

    const deleteTarot = (tarot_id) => {
        setTarots((prevTarots)=>{
            let copy = [...prevTarots]
            copy = copy.filter(item => item.id !== tarot_id)
            return copy
        })
        setShowPopUps({...showPopUps,delete:null})
    }

    const handleSearchChange = (e) => {
        setSearching(true)
        if (searchTimer.current){
            clearTimeout(searchTimer.current)
        }
        const value = e.target.value
        setSearchValue(value)

        searchTimer.current = setTimeout(()=>{
            setSearching(false)
        },200)
    }

    const activeFilter = useMemo(()=>{
        return filterParams.cards.length || filterParams.spreads.length || filterParams.created_at
    },[filterParams])

return (
    crashError ? <div className = {styles.wrapper}>
        <div className = {styles.box}>
            <div className = {styles.header}>
                <div className = {styles.title}>Tarot Readings</div>
                <div className = {styles.profileArea}></div>
            </div>
            <CrashPage msg = {crashError}/>
        </div>
    </div> :
    tarots ? <div className = {styles.wrapper}>
      <div className = {styles.box}>
          <div className = {styles.header}>
              <div className = {styles.title}>Tarot Readings</div>
              <div className = {styles.searchBox}>
                  <div className = {styles.searchIcon}><LuSearch/></div>
                  <input type = "text" placeholder = "Search..." value = {searchValue} onChange = {handleSearchChange}/>
              </div>
              <div className = {styles.profileArea}></div>
          </div>
          <div className = {styles.subHeader}>
              <div className = {styles.lengthBox}>
                  <div className = {styles.lengthText}>My Readings</div>
                  <div className = {styles.length}>{tarots?.length}</div>
              </div>
              <div className = {styles.btns}>
                  <div className = {styles.sortWrapper}>
                      <div className = {`${styles.sortBtn} ${showSortMenu  && styles.active}`} onClick={()=>setShowSortMenu(!showSortMenu)}>
                          <div className = {styles.sortIcon}><PiArrowsDownUpBold/></div>
                          <div className = {styles.sortText}>Sort</div>
                      </div>
                      {showSortMenu && <SortMenu sortValue={sortParams} onChangeSortValue = {(value)=>setSortParams(value)} close = {()=>setShowSortMenu(false)}/>}
                  </div>
                  <div className = {styles.filterWrapper}>
                      <div className = {`${styles.filterBtn} ${showFilterMenu  && styles.active}`} onClick={()=>setShowFilterMenu(!showFilterMenu)}>
                          <div className = {styles.filterIcon}><IoFilterSharp/></div>
                          <div className = {styles.filterText}>Filter</div>
                      </div>
                      {showFilterMenu && <FilterMenu filterParams={filterParams} onChangeFilterParams = {(value)=>setFilterParams(value)} close = {()=>setShowFilterMenu(false)}/>}
                  </div>
                  <div className = {styles.addBtn} onClick = {()=>setShowPopUps({...showPopUps,new_tarot:true})}>
                      <div className = {styles.plusIcon}><TbPlus/></div>
                      <div className = {styles.addText}>New Tarots</div>
                  </div>
              </div>
          </div>
          <div className = {`${styles.searchWrapper} ${styles.min}`}>
              <div className = {`${styles.searchBox} ${styles.min}`}>
                  <div className = {styles.searchIcon}><LuSearch/></div>
                  <input type = "text" placeholder = "Search..."/>
              </div>
          </div>
            {tarots.length > 0  ? <div className = {styles.grid} id = "tarots-grid" style = {{ overflowY: (searching || filtering) ? "hidden" : "auto"}}>
              {(!searching && !filtering) ? <div className = {styles.tarots}>
                {rows.map((row,rowIndex)=>(
                    <div className = {styles.row} key = {rowIndex}>
                        {row.map((data,index)=>(
                            <div className = {styles.tarotBox} key = {index}>
                                <TarotBox tarot_data = {data} handleMenu = {(key)=>setShowPopUps({...showPopUps,[key]:data.id})} card_index = {index}/>
                        </div>
                        ))}
                    </div>
                ))}</div> : <div className  = {styles.tarots}>
                    {[...Array(2).keys()].map((_,rowIndex)=>(
                            <div className = {styles.row} key = {rowIndex}>
                            {[...Array(elementsPerRow).keys()].map((_,index)=>(
                                <div className = {styles.tarotBox} key = {index}>
                                    <LoadingTarot card_index = {index}/>
                            </div>
                            ))}
                        </div>
                    ))}
                </div>}
          </div> :  ((activeFilter || searchValue.trim()) ? <div className = {styles.emptyContent}>
            <div className = {styles.cards}>
                <div className = {styles.cardImage}>
                    <img src = {CardImage} alt = ""/>
                </div>
            </div>
            <div className = {`${styles.emptyTitle} ${styles.minMargin}`}>No<span> Tarot</span> Readings Yet</div>
            <div className = {styles.emptyMsg}>Click on the add button to get started</div>
        </div> : <div className = {styles.emptyContent}>
            <div className = {styles.cards}>
                <div className = {styles.cardIcon}><GiCardRandom/></div>
                <div className = {styles.search}><IoSearch/></div>
            </div>
            <div className = {styles.emptyTitle}>No<span> Tarot</span> Readings Found</div>
            <div className = {styles.emptyMsg}>Try adjusting your search keyword or filters</div>
        </div>)}
      </div>
      {showPopUps.new_tarot && <Game close = {()=>setShowPopUps({...showPopUps,new_tarot:null})}/>}
      {showPopUps.delete !== null && <DeleteTarot id = {showPopUps.delete} close = {()=>setShowPopUps({...showPopUps,delete:null})} handleDelete = {()=>deleteTarot(showPopUps.delete)}/>}
      {showPopUps.view !== null && <ViewTarots id = {showPopUps.view} handleMenu = {(key)=>setShowPopUps({...showPopUps,[key]:showPopUps.view,view:null})} close = {()=>setShowPopUps({...showPopUps,view:null})}/>}
      {showPopUps.resume && <Game id = {showPopUps.resume} close = {()=>setShowPopUps({...showPopUps,resume:null})}/>}
  </div> : <LoadingReadings/>
)
}

export default Readings