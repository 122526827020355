import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/PromoCodeModal.module.css"
import { IoClose } from "react-icons/io5";
import { ReactCountryFlag } from 'react-country-flag';
import { getName } from 'country-list';
import { LuCalendarDays } from "react-icons/lu";
import { FiClock } from "react-icons/fi";
import { RiVipDiamondFill } from "react-icons/ri";
import { TfiWorld } from "react-icons/tfi";
import CountryDropDown from './CountryDropDown';
import { FormUtils } from './utils';
import { RiEditFill } from "react-icons/ri";

const EditPromoCode = ({closeModal}) => {
    const [originalData,setOriginalData] = useState(null)
    const [modifiedData,setModifiedData] = useState(null)
    const [errors,setErrors] = useState({})
    const [loading,setLoading] = useState(false)
    const [showCountries,setShowCountries] = useState(false)
    const dayRefs = useRef({start_date:{},end_date:{}})
    const codeInput = useRef(null)
    const fetching = useRef(true)

    useEffect(()=>{
        if (fetching.current){
            fetching.current = false
            let start = new Date(2024,4,3,12,45)
            let end  = new Date(2024,9,25,17,3)
            setTimeout(()=>{
                let data = {
                    code: "CHRISTMAS20",
                    num_diamonds: 15,
                    start_date: {month: String(start.getMonth()).padStart(2, '0'), day: String(start.getDate()).padStart(2, '0'),year:String(start.getFullYear())},
                    start_time: {hours: String(start.getHours()).padStart(2, '0'), minutes: String(start.getDate()).padStart(2, '0')},
                    end_date:{month: String(end.getMonth()).padStart(2, '0'), day: String(end.getDate()).padStart(2, '0'),year:String(end.getFullYear())},
                    end_time:{hours: String(end.getHours()).padStart(2, '0'), minutes: String(end.getDate()).padStart(2, '0')},
                    country: 'US'

                }
                setOriginalData(Object.assign({},data))
                setModifiedData(Object.assign({},data))
            },500)
        }
    },[])

    const handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (name === "code"){
            value = value.toUpperCase()
        }
        if (name === "num_diamonds"){
            value = parseInt(value)
            if (value === 0 || value > 100){
                if (value > 100){
                    e.target.classList.add(styles.shake)
                    setTimeout(()=>{
                        e.target.classList.remove(styles.shake)
                    },300)
                }
                return
            }
        }
        setModifiedData({...modifiedData,[name]:value})
    }

    const handleDateChange = (e,attr) => {
        FormUtils.handleDateChange(e,attr,modifiedData,setModifiedData,dayRefs)
    }

    const formatDate = (attr) => {
        FormUtils.formatDate(modifiedData,attr)
    }


    const handleDateBlur = (e,attr) => {
        FormUtils.handleDateBlur(e,attr,modifiedData,setModifiedData,errors,setErrors)
    }

    const handleFocus = (attr) => {
        if (errors[attr]){
            setErrors((prevErrors)=>{
                let copy = {...prevErrors}
                delete copy[attr]
                return copy
            })
        }
    }


    const handleTimeChange = (e,attr) => {
        FormUtils.handleTimeChange(e,attr,modifiedData,setModifiedData,dayRefs)
    }

    const validateData = () => {
        const formErrors = {}
        let valid = true
        if (!formatDate('start_date')){
            formErrors['start_date'] = 'Invalid date'
            valid = false
        }
        if (!formatDate('end_date')){
            formErrors['end_date'] = 'Invalid date'
            valid = false
        }
        if (formatDate('start_date') && formatDate('end_date')){
            if (formatDate('start_date') > formatDate('end_date')){
                formErrors['end_date'] = "End date should be bigger than start date"
                valid = false
            }
        }
        setErrors({...errors,...formErrors})
        return valid
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let valid = validateData()
        if (valid){
            setLoading(true)
        }
    }

    const handleCodeKey = (e) => {
        const key = e.key;
        const isLetter = /^[a-zA-Z]$/.test(key);
        const isNumber = /^[0-9]$/.test(key);
    
        if (!isLetter && !isNumber && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
          e.preventDefault();
        }
    }

    const handleMaxLength = (e) => {
        const key = e.key;
        const isLetter = /^[a-zA-Z]$/.test(key);
        const isNumber = /^[0-9]$/.test(key);
    
        if (!(!isLetter && !isNumber && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') && modifiedData.code.length >= 20){
          codeInput.current.classList.add(styles.shake)
          setTimeout(()=>{
            codeInput.current.classList.remove(styles.shake)
          },300)
        }
    }

    const isDisabled = useMemo(()=>{
        if (!modifiedData) return true
        if (JSON.stringify(originalData) === JSON.stringify(modifiedData)){
            return true
        }
        if (!modifiedData.code || !modifiedData.num_diamonds || !modifiedData.country){
            return true
        }
        else if (Object.values(modifiedData.start_date).some(value => !parseInt(value))){
            return true
        }
        else if (Object.values(modifiedData.end_date).some(value => !parseInt(value))){
            return true
        }
        else if (Object.values(modifiedData.start_time).some(value => isNaN(parseInt(value)))){
            return true
        }
        else if (Object.values(modifiedData.end_time).some(value => isNaN(parseInt(value)))){
            return true
        }
        else{
            return Object.keys(errors).length
        }
    },[modifiedData,originalData,errors])

    const handleDateKeyDown = (e,attr) => {
        FormUtils.handleDateKeyDown(e,attr,dayRefs)
    }


  return (
    <div className = {styles.wrapper}>
        {modifiedData ? <form className = {styles.box} onSubmit = {handleSubmit} autoComplete='off'>
            <header>
                <div className = {styles.editIcon}><RiEditFill/></div>
                <div className = {styles.editTitle}>Edit Promo Code</div>
                <div className = {styles.closeIcon} onClick = {()=>closeModal()}><IoClose/></div>
            </header>
            <main>
                <div className = {styles.inputArea}>
                    <label htmlFor = "code">Promo Code</label>
                    <div className = {styles.instructions}>- Promo codes are automatically uppercased and limited to 20 characters. No space or special characters allowed.</div>
                    <div  ref = {codeInput} className = {`${styles.inputBox} ${styles.code}`} tabIndex={-1} onKeyDown = {handleMaxLength}>
                        <input type = "text" name = "code" value = {modifiedData.code} onChange = {handleChange} placeholder = "Enter promo code here" onKeyDown={handleCodeKey} maxLength={20} autoComplete='off'/>
                        {modifiedData.code.length > 1 && <div className = {styles.length}>{modifiedData.code.length}/20</div>}
                    </div>
                </div>
                <div className = {styles.inputArea}>
                    <label htmlFor = "num_diamonds">Number of diamonds</label>
                    <div className = {styles.instructions}>- Number of diamonds offered by a promo code is limited to 100.</div>
                    <div className = {`${styles.inputBox} ${styles.num}`}>
                        <input type = "text" name = "num_diamonds" value = {modifiedData.num_diamonds} onChange = {handleChange} placeholder = "N&deg;"  autoComplete='off'/>
                        <div className = {styles.diamondIcon}><RiVipDiamondFill/></div>
                    </div>
                </div>
                <div className = {styles.dateSection}>
                    <div className = {styles.line}></div>
                    <div className = {styles.sectionTitle}>Promotional Period</div>
                    <div className = {styles.inputArea}>
                        <label htmlFor = "start_date">Start Date</label>
                        <div className = {styles.dateWrapper}>
                            <div className = {`${styles.dateBox} ${errors.start_date && styles.error}`} tabIndex = {-1} onBlur = {(e)=>FormUtils.validateDate(e,dayRefs.current?.start_date?.month?.parentNode,modifiedData,'start_date',errors,setErrors)}>
                                <div className = {styles.dateBtn}>
                                    <input className = {styles.month} ref = {(el)=>dayRefs.current['start_date'].month = el} type = "text" name = "month" value = {modifiedData.start_date.month} placeholder = "MM" onChange={(e)=>handleDateChange(e,"start_date")} onBlur = {(e)=>handleDateBlur(e,"start_date")} onFocus = {()=>handleFocus("start_date")} onKeyDown = {(e)=>handleDateKeyDown(e,"start_date")} autoComplete='off'/>
                                    <div className = {styles.seperator}>/</div>
                                    <input ref = {(el)=>dayRefs.current['start_date'].day = el}  type = "text" name = "day" value = {modifiedData.start_date.day} placeholder = "DD" onChange={(e)=>handleDateChange(e,"start_date")} onBlur = {(e)=>handleDateBlur(e,"start_date")} onFocus = {()=>handleFocus("start_date")} onKeyDown = {(e)=>handleDateKeyDown(e,"start_date")} autoComplete='off'/>
                                    <div className = {styles.seperator}>/</div>
                                    <input className = {styles.year} ref = {(el)=>dayRefs.current['start_date'].year = el}  type = "text" name = "year" value = {modifiedData.start_date.year} placeholder = "YYYY" onChange={(e)=>handleDateChange(e,"start_date")} onBlur = {(e)=>handleDateBlur(e,"start_date")} onFocus = {()=>handleFocus("start_date")} onKeyDown = {(e)=>handleDateKeyDown(e,"start_date")} autoComplete='off'/>
                                </div>
                                <div className = {styles.calendarIcon}><LuCalendarDays/></div>
                            </div>
                            <div className = {styles.timeBox}>
                                <div className = {styles.timeBtn}>
                                    <input  ref = {(el)=>dayRefs.current['start_date'].hours = el} type = "text" name = "hours" value = {modifiedData.start_time.hours} placeholder = "HH" onChange={(e)=>handleTimeChange(e,"start_time")} onKeyDown = {(e)=>handleDateKeyDown(e,"start_date")} autoComplete='off'/>
                                    <div className = {styles.seperator}>:</div>
                                    <input  ref = {(el)=>dayRefs.current['start_date'].minutes = el} type = "text" name = "minutes" value = {modifiedData.start_time.minutes} placeholder = "MM" onChange={(e)=>handleTimeChange(e,"start_time")} onKeyDown = {(e)=>handleDateKeyDown(e,"start_date")} autoComplete='off'/>
                                </div>
                                <div className = {styles.timeIcon}><FiClock/></div>
                            </div>
                        </div>
                        {errors.start_date && <div className = {styles.errorBox}>
                            {errors.start_date}
                        </div>}
                    </div>
                    <div className = {styles.inputArea}>
                        <label htmlFor = "end_date">End Date</label>
                        <div className = {styles.dateWrapper}>
                            <div className = {`${styles.dateBox} ${errors.end_date && styles.error}`} tabIndex = {-1} onBlur = {(e)=>FormUtils.validateDate(e,dayRefs.current?.end_date?.month?.parentNode,modifiedData,'end_date',errors,setErrors)}>
                                <div className = {styles.dateBtn}>
                                    <input className = {styles.month} ref = {(el)=>dayRefs.current['end_date'].month = el}  type = "text" name = "month" value = {modifiedData.end_date.month} placeholder = "MM" onChange={(e)=>handleDateChange(e,"end_date")} onBlur = {(e)=>handleDateBlur(e,"end_date")} onFocus = {()=>handleFocus("end_date")} onKeyDown = {(e)=>handleDateKeyDown(e,"end_date")} autoComplete='off'/>
                                    <div className = {styles.seperator}>/</div>
                                    <input  ref = {(el)=>dayRefs.current['end_date'].day = el}  type = "text" name = "day" value = {modifiedData.end_date.day} placeholder = "DD" onChange={(e)=>handleDateChange(e,"end_date")} onBlur = {(e)=>handleDateBlur(e,"end_date")} onFocus = {()=>handleFocus("end_date")} onKeyDown = {(e)=>handleDateKeyDown(e,"end_date")} autoComplete='off'/>
                                    <div className = {styles.seperator}>/</div>
                                    <input className = {styles.year} ref = {(el)=>dayRefs.current['end_date'].year = el}  type = "text" name = "year" value = {modifiedData.end_date.year} placeholder = "YYYY" onChange={(e)=>handleDateChange(e,"end_date")}  onBlur = {(e)=>handleDateBlur(e,"end_date")} onFocus = {()=>handleFocus("end_date")} onKeyDown = {(e)=>handleDateKeyDown(e,"end_date")} autoComplete='off'/>
                                </div>
                                <div className = {styles.calendarIcon}><LuCalendarDays/></div>
                            </div>
                            <div className = {styles.timeBox}>
                                <div className = {styles.timeBtn}>
                                    <input  ref = {(el)=>dayRefs.current['end_date'].hours = el}  type = "text" name = "hours" value = {modifiedData.end_time.hours} placeholder = "HH" onChange={(e)=>handleTimeChange(e,"end_time")} onKeyDown = {(e)=>handleDateKeyDown(e,"end_date")} autoComplete='off'/>
                                    <div className = {styles.seperator}>:</div>
                                    <input  ref = {(el)=>dayRefs.current['end_date'].minutes = el}  type = "text" name = "minutes" value = {modifiedData.end_time.minutes} placeholder = "MM" onChange={(e)=>handleTimeChange(e,"end_time")} onKeyDown = {(e)=>handleDateKeyDown(e,"end_date")} autoComplete='off'/>
                                </div>
                                <div className = {styles.timeIcon}><FiClock/></div>
                            </div>
                        </div>
                        {errors.end_date && <div className = {styles.errorBox}>
                            {errors.end_date}
                        </div>}
                    </div>
                    <div className = {styles.line}></div>
                </div>
                <div className = {styles.inputArea}>
                    <label htmlFor = "country">Country</label>
                    <div className = {styles.countryWrapper}>
                        <div className={styles.countryBtn} onClick = {()=>setShowCountries(!showCountries)}>
                            {modifiedData.country ? <div className = {styles.flag}><ReactCountryFlag countryCode={modifiedData.country} svg /></div> : <div className = {styles.worldIcon}><TfiWorld/></div>}
                            {modifiedData.country ? <div className = {styles.country}>{getName(modifiedData.country)}</div> : <div className = {styles.placeholder}>Set Country</div>}
                        </div>
                        {showCountries &&
                            <CountryDropDown 
                                selectedCountry = {modifiedData.country}
                                selectCountry = {(value)=>setModifiedData({...modifiedData,country:value})}
                                closeMenu={()=>setShowCountries(false)}
                            />}
                    </div>
                </div>
            </main>
            <footer>
                <button type = "button" className = {styles.cancelBtn}>Cancel</button>
                {loading ? <div className = {`${styles.loadingBtn} ${styles.save}`}>
                    <div className = {styles.spinner}></div>
                </div> : <button type = "submit" className = {styles.saveBtn} disabled = {isDisabled}>Save Changes</button>}
            </footer>
        </form> : <div className = {styles.box}>
            <header>
                <div className = {styles.editIcon}><RiEditFill/></div>
                <div className = {styles.editTitle}>Edit Promo Code</div>
                <div className = {styles.closeIcon} onClick = {()=>closeModal()}><IoClose/></div>
            </header>
            <div className = {styles.loadingArea}>
                <div className = {styles.loadingSpinner}></div>
            </div>
        </div>}
    </div>
  )
}

export default EditPromoCode