import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from  "../css/FilterMenu.module.css"
import { TbCalendarPlus } from "react-icons/tb";
import { TiArrowUnsorted } from "react-icons/ti";
import { IoClose } from "react-icons/io5";
import { BsCheckLg } from "react-icons/bs";
import spread_data from "../../tarotGame/data/spread.json"
import tarot_data from "../../tarotGame/data/cardInfo.json"
import { icons } from './ViewTarots';

const date_fields = {
  today : "Today",
  yesterday: "Yesterday",
  current_week: "This week",
  last_week: "Last Week",
  last_biweek: "Last 15 days",
  last_month: "Last Month"
}

const spreads = Object.assign({}, spread_data['three_cards'], spread_data['five_cards'])

const FilterMenu = ({filterParams,onChangeFilterParams,close}) => {
  const [filterObject,setFilterObject] = useState({...filterParams})
  const [showMenu,setShowMenu] = useState({created_at:false,spreads:false,cards:false})
  const dropDownMenus = useRef({})
  const menu = useRef(null)

  useEffect(()=>{

    const handleClick = (e) => {
        if (!menu.current.parentNode.contains(e.target)){
            close()
        }

        let selected_menu = Object.keys(showMenu).find(key => showMenu[key])
        if (selected_menu){
          if (!dropDownMenus.current[selected_menu].contains(e.target)){
            setShowMenu({...showMenu,[selected_menu]:false})
          }
        }
    }

    const handleResize = () => {
      setShowMenu({created_at:false,spreads:false,cards:false})
    }

    window.addEventListener("mousedown",handleClick)
    window.addEventListener("resize",handleResize)
    return () => {
        window.removeEventListener("mousedown",handleClick)
        window.removeEventListener("resize",handleResize)
    }
  },[close,showMenu])


  const selectGameDate = (key) => {
    setFilterObject({...filterObject,created_at:key})
    setShowMenu({...showMenu,created_at:false})
  }

  const setDropDownMenu = (el,key) => {
    if (el){
      dropDownMenus.current[key] = el
    }
  }

  const handleMenu = (key) => {
    if (showMenu[key]){
      setShowMenu((prevState)=>{
        let copy = {...prevState}
        copy[key] = false
        return copy
      })
    }
    else{
      setShowMenu((prevState)=>{
        let copy = {...prevState}
        copy[key] = true
        return copy
      })
    }
  }

  const setMaxHeight = useCallback((el)=>{
    if (el){
      let btn = el.parentNode.children[0]
      el.style.width = `${btn.offsetWidth}px`
        if (window.innerHeight - btn.getBoundingClientRect().bottom < el.scrollHeight){
            if (btn.getBoundingClientRect().top * 0.8 > (window.innerHeight - btn.getBoundingClientRect().bottom)){
                el.style.bottom = `${window.innerHeight - btn.getBoundingClientRect().top + 5}px`
                el.style.maxHeight = `${btn.getBoundingClientRect().top - 25 > 305 ? 305 : btn.getBoundingClientRect().top  - 25}px`
                return
            }
        }
        el.style.top = `${btn.getBoundingClientRect().bottom + 5}px`
        el.style.maxHeight = `${window.innerHeight - btn.getBoundingClientRect().bottom - 25}px`
    }
},[filterObject.cards,filterObject.spreads])

  const select = (attr,key) => {
    if (filterObject[attr].includes(key)){
      setFilterObject((prevState)=>{
        let copy = [...prevState[attr]]
        copy = copy.filter(item => item !== key)
        return {...prevState,[attr]:copy}
      })
    }
    else{
      setFilterObject({...filterObject,[attr]:[key,...filterObject[attr]]})
      if (attr === "spreads" && filterObject[attr].length === 2){
        setShowMenu({...showMenu,[attr]:false})
      }
      else if (attr === "cards" && filterObject[attr].length === 4){
        setShowMenu({...showMenu,[attr]:false})
      }
    }
  }

  const deleteItem = (attr,key) => {
    setFilterObject((prevState)=>{
      let copy = [...prevState[attr]]
      copy = copy.filter(item => item !== key)
      return {...prevState,[attr]:copy}
    })
  }

  const handleScroll = (_) => {
    setShowMenu({created_at:false,spreads:false,cards:false})
  };

  const reset = () => {
    onChangeFilterParams({spread:null,spreads:[],cards:[]})
    close()
  }

  const save = () => {
    onChangeFilterParams(filterObject)
    close()
  }

  return (
    <div className = {styles.menu} ref = {menu}>
      <header>Filtering</header>
      <div className = {styles.content} onScroll = {handleScroll}>
        <div className = {styles.section}>
          <div className = {styles.label}>Created on</div>
          <div className = { `${styles.selectorBox} ${filterObject.created_at && styles.min}`} ref = {(el)=>setDropDownMenu(el,"created_at")}>
            <div className = {`${styles.selectorInput} ${showMenu.created_at && styles.active}`} onClick = {()=>handleMenu("created_at")}>
              <div className = {styles.valueBox}>
                  <div className = {styles.icon}><TbCalendarPlus/></div>
                  <div className = {styles.value}>{filterObject.created_at ? date_fields[filterObject.created_at] : "Select creation date"}</div>
              </div>
              <div className = {styles.arrow}><TiArrowUnsorted/></div>
            </div>
            {filterObject.created_at && <div className = {styles.removeBtn} onClick = {()=>setFilterObject({...filterObject,created_at:null})}><IoClose/></div>}
            {showMenu.created_at && <div className = {styles.fieldMenu} ref = {setMaxHeight}>
                {Object.keys(date_fields).map((key,index)=>(
                <div className = {`${styles.fieldItem} ${key} ${filterObject.created_at === key && styles.selected}`} onClick = {()=>selectGameDate(key)} key = {index}>
                  {date_fields[key]}
                </div>
                ))}
            </div>}
          </div>
        </div>
        <div className = {styles.section}>
          <div className = {styles.label}>Spreads</div>
          <div className = {styles.selectorBox} ref = {(el)=>setDropDownMenu(el,"spreads")}>
            <div className = {`${styles.selectorInput} ${showMenu.spreads && styles.active} ${filterObject.spreads.length >= 3 && styles.disabled}`}  onClick = {()=>handleMenu("spreads")}>
              <div className = {styles.valueBox}>
                  <div className = {styles.icon}><TbCalendarPlus/></div>
                  <div className = {styles.value}>Select up to 3 spreads</div>
              </div>
              <div className = {styles.arrow}><TiArrowUnsorted/></div>
            </div>
            {showMenu.spreads && <div className = {styles.fieldMenu} ref = {setMaxHeight}>
                {Object.keys(spreads).map((key,index)=>(
                  <div className = {styles.itemBox} key = {index} onClick = {()=>select("spreads",key)}>
                    <div className = {styles.check}>{filterObject.spreads.includes(key) && <BsCheckLg/>}</div>
                    <div className = {styles.itemName}>{spreads[key].name}</div>
                  </div>
                ))}
              </div>}
          </div>
          {filterObject.spreads.length > 0 && <div className = {styles.results}>
            {filterObject.spreads.map((spread,index)=>(
              <div className = {styles.selectedBox} key = {index}>
                <div className = {styles.spreadIcon}>{icons[spread]}</div>
                <div className = {styles.selectedName}>{spreads[spread].name}</div>
                <div className = {styles.delete} onClick = {()=>deleteItem("spreads",spread)}><IoClose/></div>
              </div>
            ))}
          </div>}
        </div>
        <div className = {styles.section}>
          <div className = {styles.label}>Cards</div>
          <div className = {styles.selectorBox} ref = {(el)=>setDropDownMenu(el,"cards")}>
            <div className = {`${styles.selectorInput} ${showMenu.cards && styles.active} ${filterObject.cards.length >= 5 && styles.disabled}`} onClick = {()=>handleMenu("cards")}>
              <div className = {styles.valueBox}>
                <div className = {styles.icon}><TbCalendarPlus/></div>
                <div className = {styles.value}>Select up to 5 cards</div>
              </div>
              <div className = {styles.arrow}><TiArrowUnsorted/></div>
            </div>
            {showMenu.cards && <div className = {styles.fieldMenu} ref = {setMaxHeight}>
              {Object.keys(tarot_data).map((key,index)=>(
                <div className = {styles.itemBox} key = {index} onClick = {()=>select("cards",key)}>
                  <div className = {styles.check}>{filterObject.cards.includes(key) && <BsCheckLg/>}</div>
                  <div className = {styles.itemName}>{tarot_data[key].dataLabel}</div>
                </div>
              ))}
              </div>
            }
          </div>
          {filterObject.cards.length > 0 && <div className = {styles.results}>
            {filterObject.cards.map((card,index)=>(
              <div className = {styles.selectedBox} key = {index}>
                <div className = {styles.selectedName}>{tarot_data[card].dataLabel}</div>
                <div className = {styles.delete} onClick = {()=>deleteItem("cards",card)}><IoClose/></div>
              </div>
            ))}
          </div>}
        </div>
      </div>
      <footer>
        <button type = "button" className = {styles.resetBtn} onClick = {()=>reset()}>Reset</button>
        <button type = "button" className = {styles.saveBtn} onClick = {()=>save()}>Apply Filter</button>
      </footer>
    </div>
  )
}

export default FilterMenu