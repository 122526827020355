import axios from 'axios'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'
import styles from "../css/Activate.module.css"
import DarkLogo from "../media/darkLogo.png"
import Logo from "../media/logo.png"
import ExpiredImage from "../media/expiredImage.png"
import InvalidImage from "../media/invalidImage.png"
import ValidImage from "../media/validImage.png"
import EmailImage from "../media/emailVerification.png"
import Robot from "../media/robot.png"
import Mail from "../media/envelope.png"
import { PiWarningFill } from 'react-icons/pi'

const Activate = () => {
    const [validating,setValidating] = useState(true)
    const [resending,setResending] = useState(false)
    const [resendError,setResendError] = useState(false)
    const loading = useRef(true)
    const [status,setStatus] = useState({success:false,invalid:false,expired:false,used:false,crash:false,emailDNE:false})
    const [searchParams, _] = useSearchParams()
    let token = searchParams.get('token')

    useEffect(()=>{
        if (!token){
            setTimeout(()=>{
                setStatus((prevState) => { return {...prevState,invalid:true}})
                setValidating(false)
            },700)
            return
        }
        if (loading.current){
            loading.current = false
            const startTime = performance.now()
            axios.put(process.env.REACT_APP_API_URL + '/activate?token='+token)
            .then((_)=>{
                setStatus((prevState) => { return {...prevState,success:true}})
            })
            .catch((err)=>{
                if (err?.response?.status === 400){
                    let error = err.response?.data
                    if (error === "USED_TOKEN"){
                        setStatus((prevState) => { return {...prevState,used:true}})
                    }
                    else if (error === "EXPIRED_TOKEN"){
                        setStatus((prevState) => { return {...prevState,expired:true}})
                    }
                    else if (error === "INVALID_TOKEN"){
                        setStatus((prevState) => { return {...prevState,invalid:true}})
                    }
                }
                else{
                    setStatus((prevState) => { return {...prevState,crash:true}})
                }
                
            })
            .finally(()=>{
                const endTime = performance.now()
                const duration = endTime - startTime
                setTimeout(() => {
                    setValidating(false)
                }, duration < 7000 ? 7000 - duration : 0)
            })
        }
    },[token])

    const resend = () => {
        if (resendError){
            setResendError(false)
        }
        setResending(true)
        axios.post(`${process.env.REACT_APP_API_URL}/resend-activation/${token}`)
        .then((_)=>{
            setResending(false)
        })
        .catch((err)=>{
            if (err?.response?.data === "EMAIL_DOES_NOT_EXIST"){
                setStatus({...status,expired:false,emailDNE:true})
            }
            else{
                setResendError(true)
            }
            setResending(false)

        })
    }

    const re_validate = () => {
        setValidating(true)
        setTimeout(()=>{
            setValidating(false)
        },11000)
    }

    const responseData = useMemo(()=>{
        if (status.expired){
            return{
                title:<div className = {`${styles.title} ${styles.expired}`}>Email verification link <br/>has expired</div>,
                instructions:"It looks like the email verification link you're using is expired and no longer valid. You can get a new activation link by clicking on the resend button below or by attempting to login.",
                image: ExpiredImage,
                link: resending ? <div className = {styles.loadingBtn}><div className = {styles.loader}></div></div> : <div className = {styles.submitBtn} onClick = {()=>resend()}>Resend activation link</div>,
                error: resendError ? <div className = {styles.errorBanner}>
                    <div className = {styles.errorIcon}><PiWarningFill/></div>
                    <div className = {styles.errorText}>Something unexpected occured. Try again</div>
                    </div> : null
            }
        }
        else if (status.invalid){
            return{
                title:<div className = {`${styles.title} ${styles.invalid}`}>Email verification link <br/>is invalid</div>,
                instructions:"It looks like the email verification link you're using is not valid. Recheck your email and either click of the activate button or copy and paste the proper activation link.",
                image: InvalidImage,
                link: <NavLink to = "/"className = {styles.submitBtn}>Go to HomePage</NavLink>
            }
        }
        else if (status.emailDNE){
            return{
                title:<div className = {`${styles.title} ${styles.invalid}`}>Astrooo account no longer exists</div>,
                instructions:"It looks like the Astrooo account that you trying to generate a new activation link for no longer exists. Restart the sign-up process and activate your account within 7 days.",
                image: InvalidImage,
                link: <NavLink to = "/"className = {styles.submitBtn}>Go to Signup</NavLink>
            }
        }
        else if (status.success){
            return{
                title:<div className = {styles.title}>Astrooo Account succesfully <br/>activated</div>,
                instructions:"Your account has been succesfully activated. You can now login with the email and password provided during the sign-up process.",
                image: ValidImage,
                link: <NavLink to = "/login"className = {styles.submitBtn}>Go to Login</NavLink>
            }   
        }
        else if (status.used){
            return{
                title:<div className = {`${styles.title} ${styles.used}`}>Astrooo Account was already <br/>activated</div>,
                instructions:"Your account is already active. Click on the login button and enter your credentials to access your Astrooo account.",
                image: ValidImage,
                link: <NavLink to = "/login"className = {styles.submitBtn}>Go to Login</NavLink>
            }   
        }
    },[status,resending])

  return (
    <div className = {styles.wrapper}>
    {validating ? 
        <div className = {styles.loadingContainer}>
            <div className = {styles.loaderWrapper}>
                <div className = {styles.pulseLoader}></div>
                <div className = {styles.innerLoader}>
                    <img src = {Mail} alt = ""/>
                </div>
            </div>
            <div className = {styles.loadingText}>Validating activation link</div>
        </div> : 
        (!status.crash ? <div className = {styles.box}>
        <div className = {styles.leftSection}>
            <div className = {styles.appTitle}>
                <div className = {styles.logo}>
                    <img src = {DarkLogo} alt = "/"></img>
                </div>
                <div className = {styles.name}>Astrooo</div>
            </div>
            <div className = {styles.imageSection}>
                <img src = {EmailImage} alt = ""/>
            </div>
        </div>
            <div className = {styles.rightSection}>
                <div className = {styles.appTitle}>
                    <div className = {styles.logo}>
                        <img src = {Logo} alt = "/"></img>
                    </div>
                    <div className = {styles.name}>Astrooo</div>
                </div>
                <div className = {styles.contentWrapper}>
                    {responseData.title}
                    <div className = {`${styles.statusImage} ${status.success && styles.success}`}>
                        <img src = {responseData.image} alt = ""/>
                    </div>
                    {responseData.error}
                    <div className = {styles.instructions}>{responseData.instructions}</div>
                    {responseData.link  || <></>}
                </div>
            </div>
        </div> : 
        <div className = {`${styles.box} ${styles.crash}`}>
            <div className = {styles.appTitle}>
                <div className = {styles.logo}>
                    <img src = {Logo} alt = ""></img>
                </div>
                <div className = {styles.name}>Astrooo</div>
            </div>
            <div className = {styles.robotImage}>
                <img src = {Robot} alt = ""/>
            </div>
            <div className = {styles.title}>Ooops, Something went wrong</div>
            <div className = {styles.desc}>It looks like unexpected error occured while attempting to validate the email activation link. Click on the button below to try again or refresh this page.</div>
            <div className = {styles.submitBtn} onClick = {()=>re_validate()}>Re-validate link</div>
        </div>)
        }
    </div>
  )
}

export default Activate