import React, { useState } from 'react'
import validator from 'validator';
import { NavLink, useNavigate } from 'react-router-dom'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { FiLock } from 'react-icons/fi'
import { IoMailOutline } from 'react-icons/io5'
import axios from 'axios';
import { setUserData } from '../../../utils/auth';
import { RiErrorWarningFill } from 'react-icons/ri'
import styles from "../css/LoginForm.module.css"
import { PiWarningFill } from 'react-icons/pi';
import { Slider } from './SignUpForm';
import Logo from "../media/logo.png"
import { FaCheck } from "react-icons/fa";

const LoginForm = () => {
    const [user,setUser]                 = useState({email:"",password:"",rememberMe:false})
    const [errors,setErrors]             = useState({email:"",credentials:"",crash:""})
    const [showPassword,setShowPassword] = useState(false)
    const [loading,setLoading]           = useState(false)
    let navigate = useNavigate()


    const handleChange = (e) => {
        if (errors.credentials){
            setErrors((prevErrors)=>{return {...prevErrors,credentials:""}})
        }
        let name = e.target.name
        let value = e.target.value
        setUser({...user,[name]:value})
        if (name === "email" && errors.email){
            setErrors((prevErrors)=>{return {...prevErrors,email:""}})

        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault()

        // Clear out prev submission errors
        if (errors.credentials){
            setErrors((prevErrors)=>{return{...prevErrors,credentials:""}})
        }

        // Validate email
        if (!validator.isEmail(user.email)){
            setErrors((prevErrors)=>{return{...prevErrors,email:"Invalid email address"}})
            return
        }

        setLoading(true)
        axios.post(process.env.REACT_APP_API_URL + '/login', user)
        .then((res)=>{
            setUserData(res.data)
            navigate('/dashboard')
        })
        .catch((err)=>{
            if (err?.response?.status === 400){
                setErrors({...errors,credentials:err.response.data})
            }
            else{
                setErrors({...errors,crash:"Unable to login now"})
            }
            setLoading(false)
        })
    }

  return (
    <div className = {styles.wrapper}>
        <div className = {styles.container}>
            <div className = {styles.leftContainer}>
                <Slider/>
            </div>
            <div className = {styles.rightContainer}>
                <div className = {styles.contentWrapper}>
                    <div className = {styles.appLogo}>
                        <img src = {Logo} alt = "/"></img>
                    </div>
                    <div className = {styles.titleWrapper}>
                        <div className = {styles.title}>Welcome back! <span></span></div>
                        <div className = {styles.subtitle}>Enter your details to access your <span className = {styles.name}>Astrooo</span> account.</div>
                    </div>
                    <form className = {styles.form} onSubmit = {handleSubmit} autoComplete="off">
                        {(errors.credentials || errors.crash) && <div className = {styles.errorBanner}>
                            <div className = {styles.errorIcon}><PiWarningFill/></div>
                            <div className = {styles.errorText}>{errors.credentials || errors.crash}</div>
                        </div>}
                        <div className = {styles.inputs}>
                            <div className = {styles.inputContainer}>
                                <label htmlFor = "email">Email</label>
                                {errors.email && <div className = {styles.errorBox}>
                                    {errors.email}
                                </div>}
                                <div className = {`${styles.inputBox} ${errors.email ? styles.error : ""}`}>
                                    <div className = {`${errors.email ? styles.warningIcon : styles.emailIcon} ${!user.email && styles.placeholder}`}>{errors.email ? <RiErrorWarningFill/> : <IoMailOutline/>}</div>
                                    <input type = "text" name = "email" placeholder = "Enter your email"  value = {user.email} onChange = {handleChange} autoComplete="off"/>
                                </div>
                            </div>
                            <div className = {styles.inputContainer}>
                                <label htmlFor = "password">Password</label>
                                <div className = {styles.inputBox}>
                                    <div className = {`${styles.passwordIcon} ${!user.password && styles.placeholder}`}><FiLock/></div>
                                    <input type = {!user.password ? "text" : (showPassword ? "text" : "password")} name = "password" placeholder = "Enter your password"  value = {user.password} onChange = {handleChange} autoComplete="off"/>
                                    <div className = {`${styles.showIcon} ${!user.password && styles.placeholder}`} onClick = {()=>setShowPassword(!showPassword)}>{showPassword ? <AiOutlineEye/> : <AiOutlineEyeInvisible/>}</div>
                                </div>
                                <div className = {styles.rememberForgot}>
                                    <div className = {styles.rememberBox}>
                                        <div className = {`${styles.square} ${user.rememberMe && styles.checked}`} onClick = {()=>setUser({...user,rememberMe:!user.rememberMe})}>{user.rememberMe && <FaCheck/>}</div>
                                        <div className = {styles.rememberText}>Remember me</div>
                                    </div>
                                    <div className = {styles.forgotLink}>
                                        <NavLink to = "/forgot-password">Forgot password?</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!loading ? <button type = "submit" className = {styles.submitBtn} disabled = {!user.email || !user.password || Object.keys(errors).some(key => errors[key] && key !== "crash")}>Login</button> : <div className = {styles.loadingBtn}><div className = {styles.loader}></div></div>}
                    </form>
                </div>
                <div className = {styles.linkWrapper}>Don't have an account? <NavLink to = "/signup">Sign up</NavLink></div>
            </div>
        </div>
    </div>
  )
}

export default LoginForm