import axios from 'axios'
import { isExpired } from './auth'


const clearUser = () => {
    localStorage.removeItem('tokens')
    localStorage.removeItem('user')
}

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

api.interceptors.request.use(
    (config) => {
        const tokens = JSON.parse(localStorage.getItem('tokens',null)) 
        let access_token  = tokens ? tokens.access_token : ""
        let refresh_token = tokens ? tokens.refresh_token : ""
        if (!refresh_token || isExpired(refresh_token)){
            clearUser()
            window.location.href = "/login"
            return
        }
        else{
            config.headers.Authorization = `Bearer ${access_token}`;
        }
        return config
    }
)

api.interceptors.response.use(
    (response) => response,
    async(error) => {
        const req = error.config
        if (error.response?.status === 401 && !req._retry){
            req._retry = true

            try{
                let tokens = JSON.parse(localStorage.getItem('tokens')) 
                const response = await axios.get(process.env.REACT_APP_API_URL + '/refresh-token/' + tokens.refresh_token)
                tokens = JSON.stringify({
                    'access_token': response.data.access,
                    "refresh_token": response.data.refresh
                })
                localStorage.setItem('tokens',tokens)
                req.headers.Authorization = `Bearer ${response.data.access}`;
                return api(req);
            }
            catch(err){
                console.log("Error = ",err)
                window.location.href = "/login"
                clearUser()
            }
        }
        else{
            return Promise.reject(error);
        }
    }
)

export default api