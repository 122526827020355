import React from 'react'
import styles from "../css/SelectedSpread.module.css"
import Lock from "../media/lock.png"

const SelectedSpread = ({spread,disabled,reselect,min=false}) => {

    const handleReselect = () => {
        if (!disabled){
            reselect()
        }
    }

    return (
        !spread ? 
            <div className = {`${styles.spread} ${styles.active} ${min && styles.min}`} id = "spread-area">
                <div className = {styles.contentWrapper}>
                    <div className = {styles.spreadContent}>
                        <div className = {styles.spreadTitle}>Spread</div>
                        <div className = {styles.lockWrapper}>
                            <img src = {Lock} alt = ""/>
                        </div>
                    </div>
                </div>
            </div> : 
            <div className = {`${styles.spread} ${styles.completed} ${min && styles.min}`} id = "spread-area">
                <div className = {styles.spreadBox} onClick = {()=>handleReselect()}>
                    <div className = {styles.spreadInner}>
                        <div className = {styles.front}>
                            <div className = {styles.spreadContent}>
                                <div className = {styles.spreadTitle}>Spread</div>
                                <div className = {styles.spreadName}>{spread.name}</div>
                            </div>
                        </div>
                            <div className = {styles.back}>
                            <div className = {styles.spreadContent}>
                                <div className = {styles.spreadTitle}>Spread</div>
                                <div className = {styles.spreadDesc}>{spread.description}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default SelectedSpread