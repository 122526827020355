import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/UserModal.module.css"
import { getName } from 'country-list'
import ReactCountryFlag from 'react-country-flag'
import CountryDropDown from './CountryDropDown'
import { TfiWorld } from 'react-icons/tfi'
import { LuRefreshCw } from "react-icons/lu"
import { IoClose, IoCopy } from "react-icons/io5"
import { RiVipDiamondFill } from 'react-icons/ri'
import { FiUserPlus } from "react-icons/fi";
import validator from 'validator'
import { TiArrowSortedDown } from 'react-icons/ti'
import axios from 'axios'

const monthDays = {
    0 : 31,
    1 : 29,
    2 : 31,
    3 : 30,
    4 : 31,
    5 : 30,
    6 : 31,
    7 : 31,
    8 : 30,
    9 : 31,
    10 : 30,
    11 : 31
}

const CreateUser = ({reload,closeModal}) => {
    const [data,setData] = useState({fullname:"",email:"",password:"",perms: null, balance:null,country:null,is_active:false,gender:null,birthdate:{day:null,month:null,year:null}})
    const [showCountries,setShowCountries] = useState(false)
    const [errors,setErrors] = useState({})
    const [loading,setLoading] = useState(false)
    const [crashError,setCrashError] = useState(false)
    const nameInput = useRef(null)

    const handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (errors[name]){
            setErrors((prevErrors)=>{
                let copy = {...prevErrors}
                delete copy[name]
                return copy
            })
        }
        if (name === "fullname"){
            value = value.replace(/\s+/g, ' ')
            setData({...data,[name]:value})
        }
        else if (name === "balance"){
            if (/^\d*$/.test(value)) {
                if (parseInt(value) === 0 || parseInt(value) > 100){
                    e.target.classList.add(styles.shake)
                    setTimeout(()=>{
                        e.target.classList.remove(styles.shake)
                    },300)
                    return
                }
                setData({...data,[name]:value})
            }
        }
        else{
            setData({...data,[name]:value})
        }
    }

    const handleKeyDown = (e) => {
        const nonPrintableKeys = new Set([
            'Backspace', 'Tab', 'Enter', 'Shift', 'Control', 'Alt', 'Pause', 'CapsLock', 'Escape', 'PageUp', 'PageDown',
            'End', 'Home', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Insert', 'Delete', 'F1', 'F2', 'F3', 'F4',
            'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12', 'NumLock', 'ScrollLock', 'ContextMenu', 'Meta'
        ]);

        if (!nonPrintableKeys.has(e.key) && data.fullname.length >= 25){
          nameInput.current.classList.add(styles.shake)
          setTimeout(()=>{
            nameInput.current.classList.remove(styles.shake)
          },300)
        }
    }

    const isDisabled = useMemo(()=>{
        if (Object.keys(data).some(key => !data[key] && (key !== 'is_active' && key !== 'birthdate'))){
            return true
        }
        if (Object.values(data.birthdate).some(value => value === null)){
            return true
        }
        if (Object.keys(errors).length){
            return true
        }
    },[data,errors])

    const generatePassword = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let password = '';
        
        for (let i = 0; i < 10; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            password += characters[randomIndex];
        }
        
        setData({...data,password:password})
    }

    const copyPassword = () => {
        navigator.clipboard.writeText(data.password)
        .then(() => {
          alert('Password copied succesfully');
        })
        .catch(err => {
          alert('Unable to copy password.');
        });  
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        if (!validator.isEmail(data.email)){
            setErrors((prevErrors)=>{return {...prevErrors,email:"Invalid email address"}})
        }
        else{
            setLoading(true)
            const start = performance.now()
            axios.post(`${process.env.REACT_APP_API_URL}/admin/users/create`,data)
            .then((_)=>{
                const end = performance.now()
                const waiting_time = end - start < 500 ? (500 - end - start) : 0
                setTimeout(()=>{
                    reload()
                    closeModal()
                },waiting_time)
            })
            .catch((err)=>{
                const end = performance.now()
                const waiting_time = end - start < 500 ? (500 - end - start) : 0
                setTimeout(()=>{
                    if (err?.response?.data?.email){
                        setErrors({...errors,email:err.response.data.email})
                    }
                    else{
                        setCrashError(true)
                    }
                    setLoading(false)
                },waiting_time)
            })
        }
    }

  return (
    <div className = {styles.wrapper}>
        <form className = {styles.box} autoComplete='off' onSubmit = {handleSubmit}>
            <header>
                <div className = {styles.userIcon}><FiUserPlus/></div>
                <div className = {styles.title}>Add New User</div>
                <div className = {styles.closeIcon} onClick = {()=>closeModal()}><IoClose/></div>
            </header>
            <main>
                {data.perms === "basic_user" && <div className = {styles.statusArea}>
                      <div className = {styles.statusBox}>
                          <div className = {`${styles.statusSlider} ${data.is_active && styles.checked}`}>
                              <div className = {styles.statusCircle} onClick = {()=>setData({...data,is_active:!data.is_active})}></div>
                          </div>
                          <div className = {styles.statusText}>{data.is_active ? "Active" : "Inactive"}</div>
                      </div>
                </div>}
                <div className = {styles.permissionsWrapper}>
                    <div className = {`${styles.permissionBox} ${data.perms === "admin" && styles.checked}`} onClick = {()=>setData({...data,perms:"admin",is_active:false})}>
                        <div className = {styles.permissionCircle}>
                            {data.perms === "admin" && <div className = {styles.innerCircle}></div>}
                        </div>
                        <div className = {styles.perm}>Administrator</div>
                    </div>
                    <div className = {`${styles.permissionBox} ${data.perms === "basic_user" && styles.checked}`} onClick = {()=>setData({...data,perms:"basic_user"})}>
                        <div className = {styles.permissionCircle}>
                            {data.perms === "basic_user" && <div className = {styles.innerCircle}></div>}
                        </div>
                        <div className = {styles.perm}>Basic User</div>
                    </div>
                </div>
                <div className = {styles.inputWrapper}>
                    <label htmlFor='fullname'>Name</label>
                    <div className = {styles.inputBox} ref = {nameInput} tabIndex={-1} onKeyDown = {handleKeyDown}>
                        <div className = {styles.length}>{data.fullname.length}/25</div>
                        <input type = "text" name = 'fullname' value = {data.fullname} onChange = {handleChange} maxLength = {25} autoComplete='off'/>
                    </div>
                </div>
                <div className = {styles.inputWrapper}>
                    <label htmlFor='email'>Email</label>
                    <div className = {`${styles.inputBox} ${errors.email && styles.errorBox}`}>
                        <input type = "text" name = 'email' value = {data.email} onChange = {handleChange} autoComplete='off'/>
                    </div>
                    {errors.email && <div className = {styles.error}>{errors.email}</div>}
                </div>
                <div className = {styles.inputWrapper}>
                    <label htmlFor='password'>Password</label>
                    <div className = {`${styles.passwordBox} ${!data.password && styles.filled}`}>
                        <input type = "text" name = 'password' value = {data.password} disabled = {true}/>
                        {data.password && <div className = {styles.copyBtn} onClick = {()=>copyPassword()}><IoCopy/></div>}
                    </div>
                    <button type = "button" className = {styles.generateBtn} onClick = {()=>generatePassword()}>
                        <div className = {styles.generateIcon}><LuRefreshCw/></div>Generate
                    </button>
                </div>
                <div className = {styles.doubleWrapper}>
                    <div className = {styles.inputWrapper}>
                        <label htmlFor='gender'>Gender</label>
                        <div className = {styles.choices}>
                            <div className = {`${styles.choice} ${data.gender === "female" && styles.checked}`} onClick = {()=>setData({...data,gender:"female"})}>Female</div>
                            <div className = {`${styles.choice} ${data.gender === "male" && styles.checked}`} onClick = {()=>setData({...data,gender:"male"})}>Male</div>
                            <div className = {`${styles.choice} ${data.gender === "other" && styles.checked}`} onClick = {()=>setData({...data,gender:"other"})}>Other</div>
                        </div>
                    </div>
                    <div className = {styles.inputWrapper}>
                        <label htmlFor='country'>Country</label>
                        <div className = {styles.countryWrapper}>
                            <div className={styles.countryBtn} onClick = {()=>setShowCountries(!showCountries)}>
                                {data.country ? <div className = {styles.flag}><ReactCountryFlag countryCode={data.country} svg /></div> : <div className = {styles.worldIcon}><TfiWorld/></div>}
                                {data.country ? <div className = {styles.country}>{getName(data.country)}</div> : <div className = {styles.placeholder}>Set Country</div>}
                            </div>
                            {showCountries &&
                                <CountryDropDown 
                                    selectedCountry = {data.country}
                                    selectCountry = {(value)=>setData({...data,country:value})}
                                    closeMenu={()=>setShowCountries(false)}
                                />}
                        </div>
                    </div>
                </div>
                <div className = {styles.doubleWrapper}>
                    <div className = {styles.inputWrapper}>
                        <label htmlFor='birthdate'>Date of Birth</label>
                        <BirthDateSelector birthdate={data.birthdate} selectDate={(attr,value)=>setData({...data,birthdate:{...data.birthdate,[attr]:value}})}/>
                    </div>
                    <div className = {styles.inputWrapper}>
                        <label htmlFor='balance'>Initial Balance - (Max: 100)</label>
                        <div className = {`${styles.inputBox} ${styles.balance}`}>
                            <input type = "text" name = "balance" value = {data.balance} onChange = {handleChange} placeholder = "N&deg;"  autoComplete='off'/>
                            <div className = {styles.diamondIcon}><RiVipDiamondFill/></div>
                        </div>
                    </div>
                </div>
            </main>
            <footer>
                {loading ? <div className = {styles.loadingBtn}>
                    <div className = {styles.spinner}></div>
                </div> : <button type = "submit" disabled = {isDisabled}>Add User</button>}
            </footer>
        </form>
    </div>
  )
}

export const BirthDateSelector = ({birthdate,selectDate}) => {
    const [dropDown,setDropDown] = useState("")
    const menus = useRef({})

    useEffect(()=>{

        const handleClick = (e) => {
            if (dropDown){
                if (!menus.current[dropDown]?.contains(e.target)){
                    setDropDown("")
                }
            }
        }

        const handleResize = () => {
            if (dropDown){
                setDropDown("")
            }
        }
        window.addEventListener("mousedown",handleClick)
        window.addEventListener("resize",handleResize)
        return () => {
            window.removeEventListener("mousedown",handleClick)
            window.removeEventListener("resize",handleResize)
        }
    },[dropDown])


    const setMaxHeight = useCallback((el)=>{
        if (el){
            if (el){
                el.style.maxHeight = ""
                let btnCoords = el.parentNode.getBoundingClientRect()
                let aboveHeight = btnCoords.top
                let belowHeight = window.innerHeight - btnCoords.top - btnCoords.height
                if (belowHeight > aboveHeight){
                    el.style.top = `${btnCoords.bottom + 5}px`
                    el.style.bottom = ""
                    el.style.left = `${btnCoords.left}px`
                    el.style.maxHeight = `${btnCoords.bottom - 10}px`
                }
                else{
                    let bottomRect = window.innerHeight - btnCoords.top + 5
                    el.style.bottom = `${bottomRect}px`
                    el.style.top = ""
                    el.style.left = `${btnCoords.left}px`
                    el.style.maxHeight = `${window.innerHeight - bottomRect - 20}px` 
                }
                el.style.width = `${btnCoords.width}px`
            }
        }
    },[])

    const setMenuRef = useCallback((el,key)=>{
        if (el){
            menus.current[key] = el
        }
    },[])

    const days = useMemo(()=>{
        if (birthdate){
            if (birthdate.month === null){
                return 31
            }
            else{
                let num_days
                if (birthdate.month === 1 && birthdate.year){
                    num_days = new Date(birthdate.year, 2, 0).getDate()
                }
                else{
                    num_days = monthDays[birthdate.month]
                }
                if (num_days < birthdate.day){
                    selectDate('day',null)
                }
    
                return num_days
            }
        }
    },[birthdate,selectDate])

    const years = useMemo(()=>{
        let current_year = new Date().getFullYear()
        let start_year = current_year - 120
        return Array.from({ length: 121}, (_, index) => start_year + index).reverse()
    },[])

    return (
        <div className = {styles.dateInputs}>
            <div className = {styles.selectorWrapper}  ref = {(el)=>setMenuRef(el,"months")}>
                <div className = {`${styles.selectorBox} ${birthdate.month === null && styles.emptyInput}`} onClick = {()=>setDropDown(dropDown === "months" ? "" : "months")}>
                    {birthdate.month !== null ? <div className = {styles.selectorInput}>{(birthdate.month+1).toString().padStart(2, '0')}</div> : <div className = {styles.placeholder}>MM</div>}
                    <div className = {styles.arrow}><TiArrowSortedDown/></div>
                </div>
                {dropDown === "months" && <div className = {styles.menu} ref = {setMaxHeight}>
                    {[...Array(12).keys()].map((_,index)=>(
                        <div className = {styles.menuItem} key = {index} onClick = {()=>{selectDate("month",index);setDropDown("")}}>{(index+1).toString().padStart(2, '0')}</div>
                    ))}
                </div>}
            </div>
            <div className = {styles.selectorWrapper} ref = {(el)=>setMenuRef(el,"days")}>
                <div className = {`${styles.selectorBox} ${birthdate.day === null && styles.emptyInput}`} onClick = {()=>setDropDown(dropDown === "days" ? "" : "days")}>
                    {birthdate.day != null ? <div className = {styles.selectorInput}>{(birthdate.day).toString().padStart(2, '0')}</div> : <div className = {styles.placeholder}>DD</div>}
                    <div className = {styles.arrow}><TiArrowSortedDown/></div>
                </div>
                {dropDown === "days" && <div className = {styles.menu} ref = {setMaxHeight}>
                    {[...Array(days).keys()].map((_,index)=>(
                        <div className = {styles.menuItem} key = {index} onClick = {()=>{selectDate("day",index+1);setDropDown("")}}>{(index+1).toString().padStart(2, '0')}</div>
                    ))}
                </div>}
            </div>
            <div className = {styles.selectorWrapper} ref = {(el)=>setMenuRef(el,"years")}>
                <div className = {`${styles.selectorBox} ${!birthdate.year && styles.emptyInput}`} onClick = {()=>setDropDown(dropDown === "years" ? "" : "years")}>
                    {birthdate.year ? <div className = {styles.selectorInput}>{birthdate.year}</div> : <div className = {styles.placeholder}>YYYY</div>}
                    <div className = {styles.arrow}><TiArrowSortedDown/></div>
                </div>
                {dropDown === "years" && <div className = {styles.menu} ref = {setMaxHeight}>
                    {years.map((year,index)=>(
                        <div className = {styles.menuItem} key = {index} onClick = {()=>{selectDate("year",year);setDropDown("")}}>{year}</div>
                    ))}
                </div>}
            </div>
        </div>
    )
}
export default CreateUser