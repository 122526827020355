import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/Game.module.css"
import { FaCalendar } from "react-icons/fa6";
import DatePicker from './DatePicker';
import Ribbon from "../media/ribbon.png"
import BookPage from "../media/bookside.png"
import InputBox from "../media/nameBox.png"
import InterpertBtn from "../media/interpertBtn.png"
import MenuBtn from "../media/menuBtn.png"
import borderLine from "../media/line.png"
import Stone from "../../tarotGame/media/stone.PNG"
import MoonStars from "../media/moon_stars.png"
import DecoratedCircle from "../media/circle.png"
import TitleBorder from "../media/titleBorder.png"
import { RiArrowDownSLine, RiArrowUpSLine  } from "react-icons/ri";
import { BsFillSendFill } from 'react-icons/bs';
import { PiCalendarBlankFill } from "react-icons/pi";
import api from '../../../utils/api';
import History from './History';
import LoadingDream from './LoadingDream';
import ExitModal from './ExitModal';
import RestartModal from './RestartModal';
import ReactMarkdown from 'react-markdown';

const monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const Game = ({title,game_id,closeGame,gameData=null}) => {
  const [data,setData] = useState(gameData ? {...gameData,dream:gameData.content} : {id:game_id,title:title || "",dreamt_at:null,dream:"",follow_up:[],interpertation:"",questions:[]})
  const textRef = useRef(null)
  const inputRef = useRef(null)
  const [showCalendar,setShowCalendar] = useState(false)
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [loading,setLoading] = useState(false)
  const [showHistory,setShowHistory] = useState(false)
  const [fetching,setFetching] = useState(false)
  const [dreams,setDreams] = useState(null)
  const [showReading,setShowReading] = useState({state:false,id:null,data:null})
  const [scrolling,setScrolling] = useState({state:false,direction:"down",prevOffset:0,initial_check:false})
  const [showPopUps,setShowPopUps] = useState({exit:false,restart:false,instructions:false,crash:false,not_found:false})


  // Check overflow and direction on scroll

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (textRef.current?.offsetHeight > inputRef.current?.offsetHeight){
        setScrolling((...prevState)=>{ return {...prevState,state:true}})
      }
      else{
        setScrolling((...prevState)=>{ return {...prevState,state:false}})
      }
    });

    const resizeLines = new ResizeObserver(_ => {
      if (textRef.current){
        textRef.current.style.height = `inherit`
        textRef.current.style.height = `${textRef.current.scrollHeight}px`
      }
    })

    if (textRef.current && inputRef.current) {
      resizeObserver.observe(textRef.current);
      resizeLines.observe(inputRef.current)
    }


    return () => {
      resizeObserver.disconnect();
      resizeLines.disconnect();
    };
  }, []);


  useEffect(() => {
      let textPage =inputRef.current

      const handleScroll = () => {
        if (inputRef.current) {
            const state = inputRef.current.scrollHeight > inputRef.current.clientHeight;
            const currentScrollPosition = inputRef.current.scrollTop;
            var direction = currentScrollPosition > scrolling.prevOffset ? 'down' : 'up'
            if (direction === "up" && currentScrollPosition === 0){
                direction = "down"
            }
            else if (direction === "down" && currentScrollPosition >= (inputRef.current.scrollHeight -  inputRef.current.clientHeight)){
                direction = "up"
            }
            setScrolling((_)=>{
                let new_scrolling = {state, direction, prevOffset: currentScrollPosition, initial_check:true }
                return new_scrolling
            })
        }
      }

      if (inputRef.current) {
        inputRef.current.addEventListener('scroll', handleScroll)
      }
      // Cleanup
      return () => {
          if (textPage) {
          textPage.removeEventListener('scroll', handleScroll);
          }
      };
  }, [scrolling])

  const handleChange = (e) => {
    let value = e.target.value
    let name = e.target.name
    if (name === "title"){
      if (value.length > 25){
        value = value.substring(0,25)
        e.target.value = value.substring(0,25)
        e.target.parentNode.classList.add(styles.shake)
        setTimeout(()=>{
          e.target.parentNode.classList.remove(styles.shake)
        },850)
      }

    }
    setData({...data,[name]:value})
    if (name === "title"){
      var windowComputedStyle = window.getComputedStyle(e.target)
      var minHeight = windowComputedStyle.getPropertyValue('min-height')
      e.target.style.height = minHeight
      e.target.style.height = `${e.target.scrollHeight}px`
    }
    if (name === "dream"){
      e.target.style.height = `inherit`
      e.target.style.height = `${e.target.scrollHeight}px`
    }
  }

  const formatDate = () => {
    let month = (data.dreamt_at.getMonth() + 1).toString().padStart(2,'0')
    let day = data.dreamt_at.getDate().toString().padStart(2,'0')
    return data.dreamt_at.getFullYear() + '-' + month + '-' + day
  }

  const handleHistoryBtn = () => {
    if (showHistory){
        setShowReading({state:false,id:null,data:null})
        setShowHistory(false)
    }
    else{
      setShowReading({state:false,id:null,data:null})
      fetchHistory()
    }
  }

  const handleMenu = (key) => {
    if (key !== "history" || key !== "return"){
      setShowPopUps({...showPopUps,[key]:true})
    }
    else if (key === "history"){
      fetchHistory()
    }
    else if (key === "return"){
      setShowHistory(false)
    }
  }


  const execute = () => {
    setLoading(true)
    let input_data = {}
    if (data.title !== title){
      input_data['title'] = data.title
    }
    api.put(`/dreamGame/${game_id}/getInterpertation`,{...input_data,dream:data.dream,dreamt_at:formatDate()})
    .then((res)=>{
        setData({...data,title:res.data.title,content:res.data.dream,image:res.data.image,interpertation:res.data.interpertation,answered_at:new Date(res.data.answered_at)})
        setLoading(false)
    })
    .catch((err)=>{
      console.log("Error = ",err)
      setLoading(false)
    })
  }

  const IsDisabled = useMemo(()=>{
    return !data.dream || !data.dreamt_at || !data.title
  },[data.dream,data.dreamt_at,data.title])

  const missingAttrs = useMemo(()=>{
    let arr = []

    if (!data.title){
      arr.push('Title')
    }
    if (!data.dream){
      arr.push('Dream')
    }

    if (!data.dreamt_at){
      arr.push('Date')
    }

    if (!arr.length){
      return ""
    }

    if (arr.length > 1){
      const allButLast = arr.slice(0, -1).join(', ');
      // Concatenate the last item with "and"
      const lastItem = arr[arr.length - 1];
      return `${allButLast} and ${lastItem} are missing`;
    }
    else{
      return `${arr[1]} is required`
    }
  },[data])

  const fetchHistory = () => {
    setFetching(true)
    let startTime = performance.now()
    api.get('/dreamReadings')
    .then((res)=>{
        let endTime = performance.now()
        const duration = endTime - startTime
        setTimeout(() => {
            setDreams([...res.data])
            setFetching(false)
            setShowHistory(true)
        }, duration < 400 ? 400 - duration : 0)
    })
    .catch((err)=>{
        console.log("Error = ",err)
        setFetching(false)
    })
  }

  const fetchDream = (dream_id) => {
    setShowReading({...showReading,state:true,id:dream_id})
    let startTime = performance.now()
    api.get(`/dreamReadings/${dream_id}`)
    .then((res)=>{
        let endTime = performance.now()
        const duration = endTime - startTime
        setTimeout(() => {
            setShowReading({id:dream_id,state:false,data:res.data})
        }, duration < 400 ? 400 - duration : 0)
    })
    .catch((err)=>{
        console.log("Error = ",err)
        setFetching(false)
    })
  }


  const restart = () => {
    setData({id:data.id,title:"",dreamt_at:null,dream:"",follow_up:[],interpertation:"",questions:[]})
    setShowPopUps({...showPopUps,restart:false})
  }

  const newGame = (id) => {
      setData({id:id,title:"",dreamt_at:null,dream:"",follow_up:[],interpertation:"",questions:[]})
      setShowPopUps({...showPopUps,restart:false})
  }

  return (
    <div className = {styles.wrapper}>
      <div className = {styles.box}>
        <div className = {styles.closeIcon}></div>
        <div className = {styles.content}>
          <div className = {styles.titleRibbon} style = {{ backgroundImage: `url(${Ribbon})`}}>
            <div className = {styles.title}>-   Dream Interpertation   -</div>
          </div>
          {showReading.id !== null ? (showReading.state ? <LoadingDream/> : <DreamView dream = {showReading.data}/>) :
          (showHistory ? <History dreams={dreams} handleDreamView={(id)=>fetchDream(id)} closeHistory={()=>setShowHistory(false)}/> : 
          (data.interpertation ? <DreamView dream = {data}/> :
          <div className = {styles.bookArea}>
            <div className = {styles.leftSide} style = {{ backgroundImage: `url(${BookPage})`}}></div>
            <div className = {styles.rightSide} style = {{ backgroundImage: `url(${BookPage})`}}> 
              <div className = {styles.textWrapper}>
                <div className = {styles.titleWrapper}>
                  <div className = {styles.sectionTitle}>My Dream</div>
                  <div className = {styles.seperator} style = {{ backgroundImage: `url(${TitleBorder})`}}></div>
                </div>
                <div className = {styles.textBox} ref = {inputRef}>
                  <textarea rows = {18} name = "dream" value = {data.dream} placeholder = "Type your dream here..." onChange = {handleChange} ref = {textRef}/>
                </div>
              </div> 
            </div>
            {scrolling.state && <div className = {`${styles.arrowWrapper} ${styles.right}`}>
              <div className = {styles.arrowBtn}>
                  <div className = {styles.arrowIcon}>{scrolling.direction === "down" ? <RiArrowDownSLine/> : <RiArrowUpSLine/> } </div>
              </div>
            </div>}
            <Decorations/>
          </div>)
          )}
            {(!data.interpertation && !showHistory && showReading.id === null) ? 
            <div className = {styles.footer}>
              <div className = {styles.menuArea}>
                <div className = {styles.btnsWrapper}>
                  <div className = {styles.menuRow}>
                    <div className = {styles.menuBtn} style = {{ backgroundImage: `url(${MenuBtn})`}} onClick = {()=>setShowPopUps({...showPopUps,restart:true})}>Restart</div>
                    <div className = {styles.menuBtn} style = {{ backgroundImage: `url(${MenuBtn})`}}>Instructions</div>
                  </div>
                  <div className = {styles.menuRow}>
                    {fetching ? <div className = {styles.loadingHistoryBtn} style = {{ backgroundImage: `url(${MenuBtn})`}}>
                      <div className = {styles.dot}></div>
                      <div className = {styles.dot}></div>
                      <div className = {styles.dot}></div>
                    </div> :  <div className = {styles.menuBtn} style = {{ backgroundImage: `url(${MenuBtn})`}} onClick = {()=>fetchHistory()}>History</div>}
                    <div className = {styles.menuBtn} style = {{ backgroundImage: `url(${MenuBtn})`}} onClick = {()=>setShowPopUps({...showPopUps,exit:true})}>Exit</div>
                  </div>
                </div>
                <div className = {styles.stone} style = {{ backgroundImage: `url(${Stone})`}}></div>
              </div>
              <div className = {styles.interpertArea}>
                <div className = {styles.btnWrapper}>
                  <div className = {styles.line} style = {{ backgroundImage: `url(${borderLine})`}}></div>
                  <div className = {`${styles.btn} ${IsDisabled && styles.disabled}`}>
                    <button type = "button" className = {styles.interpertBtn} style = {{ backgroundImage: `url(${InterpertBtn})`}} disabled = {IsDisabled || loading} onClick = {()=>execute()}>
                      {!loading ? "Interpert" : <div className = {styles.loadingBtn}>
                        <div className = {styles.dot}></div>
                        <div className = {styles.dot}></div>
                        <div className = {styles.dot}></div>
                      </div>}
                    </button>
                    <div className = {styles.tooltip}>{missingAttrs}</div>
                  </div>
                  <div className = {styles.line} style = {{ backgroundImage: `url(${borderLine})`}}></div>
                </div>
              </div>
              <div className = {styles.inputsArea}>
                <div className = {styles.btnsWrapper}>
                  <div className = {styles.nameBox} style = {{ backgroundImage: `url(${InputBox})`}}>
                    <textarea rows = {1} name = "title" value = {data.title} type = "text" placeholder = "Type dream name" onChange = {handleChange} onFocus = {()=>setIsInputFocused(true)} onBlur = {()=>setIsInputFocused(false)} />
                    {isInputFocused && <div className = {styles.characterCount}>{data.title.length}/25</div>}
                  </div> 
                  <div className = {`${styles.dateBox} ${!data.dreamt_at && styles.placeholder}`} style = {{ backgroundImage: `url(${InputBox})`}} onClick = {()=>setShowCalendar(!showCalendar)}>
                    <div className = {styles.calendarIcon}><FaCalendar /></div>
                    <div className = {styles.dateValue}>{!data.dreamt_at ? "Select Dream Date" : monthsShort[data.dreamt_at.getMonth()] + " " + data.dreamt_at.getDate() +", " + data.dreamt_at.getFullYear()}</div>
                  </div>
                </div>
                <div className = {styles.stone} style = {{ backgroundImage: `url(${Stone})`}}></div>
              </div>
          </div> : <div className = {`${styles.footer} ${styles.expanded}`}>
            {['New Game','Instructions','History','Exit'].map((item,index)=>(
              <div className = {styles.menuBox} key = {index}>
                <div className = {styles.btnWrapper}>
                  {item === "History" ? (fetching ? <div className = {styles.loadingHistoryBtn} style = {{ backgroundImage: `url(${InterpertBtn})`}}>
                      <div className = {styles.dot}></div>
                      <div className = {styles.dot}></div>
                      <div className = {styles.dot}></div>
                    </div> : <div className = {styles.menuBtn} style = {{ backgroundImage: `url(${InterpertBtn})`}} onClick = {()=>handleHistoryBtn()}>{showHistory ? "Return" : "History"}</div>)
                    : <div className = {styles.menuBtn} style = {{ backgroundImage: `url(${InterpertBtn})`}} onClick = {()=>handleMenu(item === "New Game" ? "restart" : item.toLowerCase())}>{item}</div>}
                </div>
              </div>
            ))
            }
          </div>}
          <div className = {styles.dreamRep}>
          {[...Array(2).keys()].map((_,index) => (
              <div className = {styles.moonImage} style = {{ backgroundImage: `url(${MoonStars})`}} key = {index}></div>
            )
            )}
          </div>
        </div>
        {showCalendar && <DatePicker selectedDate = {data.dreamt_at} selectDate = {(date)=>setData({...data,dreamt_at:date})} close = {()=>setShowCalendar(false)}/>}
        {showPopUps.exit && <ExitModal game_id = {data.id} close = {()=>setShowPopUps({...showPopUps,exit:false})} exit = {()=>closeGame()} isComplete = {data.interpertation || loading}/>}
        {showPopUps.restart && <RestartModal game_id = {data.id} isComplete={data.interpertation || loading} restartGame={()=>restart()} newGame = {(id)=>newGame(id)} close = {()=>setShowPopUps({...showPopUps,restart:false})}/>}
      </div>
    </div>
  )
}

export default Game


const Diamond = () => {
  return (
    <div className = {styles.square}>
      <div className = {styles.innerSquare}>
        <div className = {styles.thirdSquare}></div>
      </div>
    </div>
  )
}


const Circle = () => {
  return (
    <div className = {styles.circle}>
      <div className = {styles.innerCircle}></div>
    </div>
  )
}


const DreamView = ({dream}) => {
  const [data,setData] = useState({...dream})
  const [newQuestion,setNewQuestion] = useState("")
  const [focused,setFocused] = useState(false)
  const textRef = useRef(null)
  const inputRef = useRef(null)
  const leftPage = useRef(null)
  const rightPage = useRef(null)
  const [scrolling,setScrolling] = useState({left:{state:false,direction:"down",prevOffset:0,initial_check:false},right:{state:false,direction:"down",prevOffset:0,initial_check:false}})

  useEffect(() => {
      let leftSide = leftPage.current
      let rightSide = rightPage.current

      const handleLeftScroll = () => {
        if (leftPage.current) {
            const state = leftPage.current.scrollHeight > leftPage.current.clientHeight;
            const currentScrollPosition = leftPage.current.scrollTop;
            var direction = currentScrollPosition > scrolling.left.prevOffset ? 'down' : 'up'
            if (direction === "up" && currentScrollPosition === 0){
                direction = "down"
            }
            else if (direction === "down" && currentScrollPosition >= (leftPage.current.scrollHeight -  leftPage.current.clientHeight)){
                direction = "up"
            }
            setScrolling((prevState)=>{
                let copy = {...prevState}
                copy.left = {state, direction, prevOffset: currentScrollPosition, initial_check:true }
                return copy
            })
        }
      }

      const handleRightScroll = () => {
        if (rightPage.current) {
            const state = rightPage.current.scrollHeight > rightPage.current.clientHeight;
            const currentScrollPosition = rightPage.current.scrollTop;
            var direction = currentScrollPosition > scrolling.right.prevOffset ? 'down' : 'up';
            if (direction === "up" && currentScrollPosition === 0){
                direction = "down"
            }
            else if (direction === "down" && Math.round(currentScrollPosition) >= rightPage.current.scrollHeight -  rightPage.current.clientHeight){
                direction = "up"
            }
            setScrolling((prevState)=>{
                let copy = {...prevState}
                copy.right= {state, direction, prevOffset: currentScrollPosition, initial_check: true }
                return copy
            })
        }
      }

      if (leftPage.current) {
          leftPage.current.addEventListener('scroll', handleLeftScroll)
          if (!scrolling.left.initial_check){
              handleLeftScroll()
          }
      }
  
      if (rightPage.current) {
          rightPage.current.addEventListener('scroll', handleRightScroll)
          if (!scrolling.right.initial_check){
              handleRightScroll()
          }
      }

      return () => {
          if (leftSide) {
          leftSide.removeEventListener('scroll', handleLeftScroll);
          }
          if (rightSide) {
              rightSide.removeEventListener('scroll', handleLeftScroll);
          }
      };
  }, [scrolling])

  useEffect(() => {
    // Scroll to the bottom of the chat box whenever a sent message is added
    if (data.questions?.length){
      const lastMessage = data.questions[data.questions.length - 1];
      if (lastMessage.question && !lastMessage.answer) {
        rightPage.current.scrollTop = rightPage.current.scrollHeight
      }
    }
  }, [data.questions]);

  const timestamp = useCallback((timestamp) => {
    let am_pm   = timestamp.getHours() >= 12 ? 'PM' : 'AM';
    let hours   = timestamp.getHours()  % 12;
    hours       = hours ? hours : 12;
    let minutes = timestamp.getMinutes()
    minutes     = minutes < 10 ? '0'+ minutes : minutes
    return hours + ":" + minutes + " " + am_pm
  },[])

  const handleQuestionChange = (e) => {
    let value = e.target.value
    if (value.length > 270){
      inputRef.current.classList.add(styles.shake)
      setTimeout(()=>{
        inputRef.current.classList.remove(styles.shake)
      },850)
    }
    value = value.substring(0,270)
    e.target.value = value

  e.target.style.height = "auto";
    const scrollHeight = e.target.scrollHeight
    e.target.style.height = scrollHeight + "px";
    if (e.target.scrollHeight > e.target.offsetHeight){
      e.target.style.height = e.target.scrollHeight + "px";
    }

    let textHeight = e.target.offsetHeight
    let lineHeight = parseFloat(window.getComputedStyle(e.target).lineHeight.replace("px",""))
    let lines = Math.floor(textHeight/lineHeight)
    if (lines > 1){
      inputRef.current.classList.add(styles.multiline)
    }
    else{
      inputRef.current.classList.remove(styles.multiline)
    }

    setNewQuestion(value)
  }

  const send = (resending = false) => {
    let asking_time = new Date()
    setData({...data,questions:[...data.questions,{question:newQuestion,answer:"",asked_at:asking_time,loading:true}]})
    rightPage.current.scrollTop = rightPage.current.scrollHeight
    api.post(`/dreamGame/${data.id}/question`,{question:newQuestion,asked_at:asking_time})
    .then((res)=>{
      setData((prevData)=>{
        let questions = [...prevData.questions]
        let index = questions.length - 1
        questions[index].answer = res.data.answer
        questions[index].answered_at = res.data.answered_at
        return {...data,questions:questions}
      })
    })
    .catch((err)=>{
      console.log("Error = ",err)
      setData((prevData)=>{
        let questions = [...prevData.questions]
        let index = questions.length - 1
        questions[index].error = true
        delete questions[index].loading
        return {...data,questions:questions}
      })
    })
    // setQuestions([...questions,{text:newQuestion,timestamp:new Date()}])
    setNewQuestion("")
    textRef.current.style.height = "auto";
  }

  const resend = (followup,index) => {
    let asking_time = new Date()
    setData((prevData)=>{
      let questions = [...prevData.questions]
      let new_index = questions.length - 1
      let question = {...followup,asked_at:asking_time}
      questions.splice(index, 1);
      questions.splice(new_index, 0, question);
      return {...data,questions:questions}
    })
    setData({...data,questions:[...data.questions,{question:newQuestion,answer:"",asked_at:asking_time,loading:true}]})
    api.post(`/dreamGame/${data.id}/question`,{question:newQuestion,asked_at:asking_time})
    .then((res)=>{
      setData((prevData)=>{
        let questions = [...prevData.questions]
        let index = questions.length - 1
        questions[index].answer = res.data.answer
        questions[index].answered_at = res.data.answered_at
        return {...data,questions:questions}
      })
    })
    .catch((err)=>{
      console.log("Error = ",err)
      setData((prevData)=>{
        let questions = [...prevData.questions]
        let index = questions.length - 1
        questions[index].error = true
        delete questions[index].loading
        return {...data,questions:questions}
      })
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter'){
      e.preventDefault()
      send()
    }
  }

  return (
    <div className = {`${styles.bookArea} ${styles.expanded}`}>
      <div className = {styles.leftSide} style = {{ backgroundImage: `url(${BookPage})`}}>
        <div className = {styles.responseArea} ref = {leftPage}>
          <div className = {styles.dreamDate}>
            <div className = {styles.dreamDateIcon}><PiCalendarBlankFill/></div>
            <div className = {styles.dreamDateVal}>Dreamt at: {months[new Date(data.dreamt_at).getMonth()] + " " + new Date(data.dreamt_at).getDate() +", " + new Date(data.dreamt_at).getFullYear()}</div>
          </div>
          <div className = {styles.imageBox} style = {{ backgroundImage: `url(${process.env.REACT_APP_API_URL}/dreamImages/${data.image})`}}></div>
          <div className = {styles.dreamTitle}>-  {data.title}  -</div>
          <div className = {styles.response_text}>{data.content}</div>
        </div>
      </div>
      <div className = {styles.rightSide} style = {{ backgroundImage: `url(${BookPage})`}}>  
        <div className = {styles.contentWrapper}>
          <div className = {styles.titleWrapper}>
            <div className = {styles.sectionTitle}>My Dream Chat</div>
            <div className = {styles.seperator} style = {{ backgroundImage: `url(${TitleBorder})`}}></div>
          </div>
          <div className = {styles.chatContent} ref = {rightPage}>
            {[{answer:data.interpertation,answered_at:data.answered_at,is_interpertation:true},...data.questions].map((followup,index)=>(
            <div className = {styles.chatInstance} key = {index}>
              {!followup.is_interpertation && <div className = {styles.userWrapper}>
                <div className = {styles.userBox}>
                  <div className = {styles.timestamp}>{timestamp(new Date(followup.asked_at))}</div>
                  <div className = {styles.text}>{followup.question}</div>
                </div>
              </div>}
              {followup.answer ? <div className = {styles.appWrapper}>
                <div className = {styles.appBox}>
                  <div className = {styles.timestamp}>{timestamp(new Date(followup.answered_at))}</div>
                  <div className = {styles.text}><ReactMarkdown>{followup.answer}</ReactMarkdown></div>
                </div>
              </div> :
              (followup.loading  ? <div className = {styles.loadingDots}>
                <div className = {styles.dot}></div>
                <div className = {styles.dot}></div>
                <div className = {styles.dot}></div>
              </div> : <div className = {styles.chatError}>Error <span onClick = {()=>resend(followup,index)}>resend</span></div>)}
            </div>
          ))}
          </div>
          <div className = {styles.chatSeperator}></div>
          {( focused && newQuestion.trim()) && <div className = {styles.characterLength}>{newQuestion.length}/270</div>}
          <div className = {styles.typerWrapper} ref = {inputRef}>
            <div className = {styles.textarea}>
              <textarea ref = {textRef} rows = {1} name = "newQuestion" placeholder='Type your question here' value = {newQuestion} onChange = {handleQuestionChange} onFocus = {()=>setFocused(true)} onBlur = {()=>setFocused(false)} onKeyDown={handleKeyDown}/>
            </div>
            <div className = {styles.sendBtn} onClick = {()=>send()}><BsFillSendFill/></div>
          </div>
        </div>
      </div>
      {scrolling.left.state && <div className = {`${styles.arrowWrapper} ${styles.left}`}>
            <div className = {styles.arrowBtn}>
            <div className = {styles.arrowIcon}>{scrolling.left.direction === "down"  ? <RiArrowDownSLine/> : <RiArrowUpSLine/> } </div>
            </div>
            </div>}
      {scrolling.right.state && <div className = {`${styles.arrowWrapper} ${styles.right}`}>
          <div className = {styles.arrowBtn}>
              <div className = {styles.arrowIcon}>{scrolling.right.direction === "down" ? <RiArrowDownSLine/> : <RiArrowUpSLine/> } </div>
          </div>
      </div>}
      <Decorations expanded = {true}/>
    </div>
  )
}

export const Decorations = ({expanded = false}) => {
  return (
    <div className = {`${styles.decorationBox} ${expanded && styles.expanded}`}>
      <div className = {styles.squares}>
        {[...Array(4).keys()].map((_,index) => (
          <Diamond key = {index}/>
        )
        )}
      </div>
      <div className = {styles.circles}>
        {[...Array(4).keys()].map((_,index) => (
          <Circle key = {index}/>
        )
        )}
      </div>
      <div className = {styles.decoratedCircles}>
        {[...Array(2).keys()].map((_,index) => (
          <div className = {styles.decoratedCircle} style = {{ backgroundImage: `url(${DecoratedCircle})`}} key = {index}></div>
        )
        )}
      </div>
    </div>
    )
}