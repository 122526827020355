import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from "../css/DreamBox.module.css"
import DreamMenu from './DreamMenu'
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { TbCalendarPlus } from "react-icons/tb";
import { BsFillMoonStarsFill } from "react-icons/bs";
import { FaHeart, FaPlay } from 'react-icons/fa';
import { HiOutlineChatBubbleOvalLeft } from "react-icons/hi2";
import { FaRegHeart } from "react-icons/fa6";
import { toast } from 'react-toastify';
import api from '../../../utils/api';

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]


const DreamBox = ({dream_data,handleMenu,index,refreshPage}) => {
    const [bookmarked,setBookmarked] = useState(dream_data.bookmarked)
    const [showMenu,setShowMenu] = useState(false)
    const btnRef = useRef(null)


    useEffect(()=>{
        const handleScroll = () => {
            if (showMenu){
                setShowMenu(false)
            }
        }
        let grid = document.getElementById("dreams-grid")
        if (grid){
            grid.addEventListener("scroll",handleScroll)
        }
        return () => {
            grid.removeEventListener("scroll",handleScroll)
        }
    },[showMenu])

    const handleView = (e) => {
        if (!btnRef.current.contains(e.target)){
            handleMenu("view")
        }
    }

    const dateText = useCallback((date_value)=>{
        if (!date_value) return "Not Set"
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        yesterday.setHours(0, 0, 0, 0)
        const inputDate = new Date(date_value)
        inputDate.setHours(0, 0, 0, 0)
        if (inputDate.getTime() === today.getTime()) {
            return "Today"
        }
        else if (inputDate.getTime() === yesterday.getTime()) {
            return "Yesterday"
        } 
        else {
            return months[inputDate.getMonth()] + " " + inputDate.getDate() + ", " + inputDate.getFullYear()
        }
    },[])

    const handleResume = (e) => {
        e.stopPropagation()
        handleMenu("resume")
    }

    const handleBookMark = (e) => {
        e.stopPropagation()
        if (!bookmarked){
            setBookmarked(true)
            api.put(`/dreams/${dream_data.id}/bookmark`)
            .then((res)=>{
                toast.success(res.data, {
                    position: 'bottom-left',
                    className: styles.toastSuccess,
                    bodyClassName: styles.toastBody,
                    progressStyle: {
                        backgroundColor: '#5F8575',
                    },
                });
            })
            .catch((err)=>{
                setBookmarked(false)
                if (err?.response?.status === 404){
                    toast.error("Dream Reading Not Found", {
                        position: 'bottom-left',
                        className: styles.toastError,
                        bodyClassName: styles.toastBody,
                        progressClassName: styles.toastProgress,
                    });
                    refreshPage()
                }
                else{
                    toast.error("Something Unexpected Occured", {
                        position: 'bottom-left',
                        className: styles.toastError,
                        bodyClassName: styles.toastBody,
                        progressClassName: styles.toastProgress,
                    }); 
                }
            })
        }
        else{
            setBookmarked(false)
            api.put(`/dreams/${dream_data.id}/unbookmark`)
            .then((res)=>{
                toast.success(res.data, {
                    position: 'bottom-left',
                    className: styles.toastSuccess,
                    bodyClassName: styles.toastBody,
                    progressStyle: {
                        backgroundColor: '#5F8575',
                    },
                });
            })
            .catch((err)=>{
                setBookmarked(true)
                if (err?.response?.status === 404){
                    toast.error("Dream Reading Not Found", {
                        position: 'bottom-left',
                        className: styles.toastError,
                        bodyClassName: styles.toastBody,
                        progressClassName: styles.toastProgress,
                    });
                    refreshPage()
                }
                else{
                    toast.error("Something Unexpected Occured", {
                        position: 'bottom-left',
                        className: styles.toastError,
                        bodyClassName: styles.toastBody,
                        progressClassName: styles.toastProgress,
                        autoClose: 600000,
                    }); 
                }
            })
        }
    }

  return (
    <div className = {`${styles.box} ${showMenu && styles.openMenu}`} key = {index} onClick = {(e)=>handleView(e)}>
        <div className = {styles.imageSection}>
            <div className = {styles.imageBox} style = {{ backgroundImage: `url(${process.env.REACT_APP_API_URL}/dreamImages/${dream_data.image})`}}></div>
        </div>
        <div className = {styles.content}>
            <div className = {styles.titleWrapper}>
                <div className = {styles.contentWrapper}>
                    <div className = {styles.dateBox}>
                        <div className = {styles.calendarIcon}><BsFillMoonStarsFill/></div>
                        <div className = {styles.dateText}>Dream Date: {dateText(dream_data.dreamt_at)}</div>
                    </div>
                    <div className = {styles.dreamContent}>
                        <div className = {styles.titleBox}>{dream_data.title}</div>
                        <div className = {styles.dream}>{dream_data.dream}</div>
                    </div>
                </div>
                <div className = {styles.menuBox} ref = {btnRef}>
                    <div className = {`${styles.ellipsisBtn} ${showMenu && styles.active}`} onClick = {()=>setShowMenu(!showMenu)}><IoEllipsisHorizontalSharp/></div>
                    {showMenu && <DreamMenu handleMenu = {(key)=>handleMenu(key)} close = {()=>setShowMenu(false)}/>}
                </div>
            </div>
        </div>
        <div className = {styles.footer}>
            <div className = {styles.dateWrapper}>
                <div className = {styles.calendarIcon}><TbCalendarPlus/></div>
                <div className = {styles.date}>{dateText(dream_data.updated_at)}</div>
            </div>
            <div className = {styles.btns}>
                {dream_data.num_questions > 0 && <div className = {styles.questionBox}>
                    <div className = {styles.questionIcon}><HiOutlineChatBubbleOvalLeft/></div>
                    <div className = {styles.questionNum}>{dream_data.num_questions}</div>
                </div>}
                <div type = "button" className = {styles.resumeWrapper} onClick = {handleResume}><div className = {styles.resumeBtn}><FaPlay/></div></div>
                <div className = {`${styles.likeBtnWrapper} ${bookmarked ? styles.bookmarked : styles.unbookmarked}`} onClick = {(e)=>handleBookMark(e)}>
                    <div className = {styles.likeBtnFilled}><FaHeart/></div>
                    <div className = {styles.likeButton}><FaRegHeart/></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DreamBox