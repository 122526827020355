import React, { useEffect, useMemo, useRef, useState } from 'react'
import { IoClose } from "react-icons/io5";
import { PiWarningFill } from "react-icons/pi";
import styles from "../css/DeleteTarot.module.css"
import api from '../../../utils/api';
import CrashModal from '../../errors/js/CrashModal';
import NotFoundTarot from '../../errors/js/NotFoundTarot';

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const DeleteTarot = ({id,handleDelete,close}) => {
  const [confirm,setConfirm] = useState("")
  const [tarot,setTarot] = useState(null)
  const [loading,setLoading] = useState(false)
  const [notFound,setNotFound] = useState(false)
  const [crashError,setCrashError] = useState("")
  const fetching = useRef(true)

  useEffect(()=>{
    if (fetching.current){
        fetching.current = false
        let startTime = performance.now()
        api.get(`/tarots/game/${id}`)
        .then((res)=>{
            let endTime = performance.now()
            let duration = endTime - startTime
            setTimeout(()=>{
                setTarot(res.data)
            },duration < 1000 ? 1000 - duration : 0)
        })
        .catch((err)=>{
          if (err.response?.status === 404){
            setNotFound(true)
          }
          else{
            setCrashError("It looks like something unexpected occured when trying to retrieve the selected tarot reading.")
          }
        })
    }
  },[id])

  const creation_date = useMemo(()=>{
    if (tarot?.created_at){
      const today = new Date(tarot.created_at)
      today.setHours(0, 0, 0, 0)
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0)
      const inputDate = new Date()
      inputDate.setHours(0, 0, 0, 0)
      if (inputDate.getTime() === today.getTime()) {
          return "today"
      }
      else if (inputDate.getTime() === yesterday.getTime()) {
          return "yesterday"
      } 
      else {
          return "on " + months[inputDate.getMonth()] + " " + inputDate.getDate() + ", " + inputDate.getFullYear()
      }
    }
    else{
      return null
    }
},[tarot?.created_at])

  const deleteReading = (e) => {
    setLoading(true)
    e.preventDefault()
    let startTime = performance.now()
    api.delete(`/tarots/delete/${id}`)
    .then((_)=>{
        let endTime = performance.now()
        let duration = endTime - startTime
        setTimeout(()=>{
            handleDelete()
            setLoading(false)
        },duration < 1400 ? 1400 - duration : 0)
    })
    .catch((err)=>{
      if (err.response?.status === 404){
        setNotFound(true)
      }
      else{
        setCrashError("It looks like something unexpected occured when trying to delete the selected tarot reading.")
      }
    })

  }

  return (
    crashError ? <CrashModal msg = {crashError} close = {()=>close()}/> : notFound ? <NotFoundTarot close = {()=>close()}/> :
    <div className = {styles.wrapper}>
      {tarot ? <form className = {styles.box} onSubmit = {deleteReading} autoComplete='off'>
        <div className = {styles.deleteIcon}><PiWarningFill/></div>
        <div className = {styles.closeIcon} onClick = {()=>close()}><IoClose/></div>
        <div className = {styles.content}>
          <div className = {styles.title}>Are you sure?</div>
          <div className = {styles.text}>You are about to <b>DELETE</b> one of your tarots readings that were created <b>{creation_date}</b>. Deleting this reading will be deleted along with its <b>card results, interpertation and follow-up questions</b>. This action <b>CANNOT</b> be undone.</div>
          <div className = {styles.inputBox}>
            <label htmlFor='confirm'>To confirm, type <b>&ldquo;delete&rdquo;</b></label>
            <input type = "text" value = {confirm} name = "confirm" onChange = {(e)=>setConfirm(e.target.value)} autoComplete='off'/>
          </div>
        </div>
        <div className = {styles.btns}>
          <button type = "button" className = {styles.cancelBtn}>Cancel, Keep Reading</button>
          {loading ? <div className = {styles.loadingBtn}><div className = {styles.loader}></div></div> : <button type = "button" className = {styles.deleteBtn} disabled = {confirm.toLowerCase() !== "delete"}>Delete Reading</button>}
        </div>
      </form> : <div className = {styles.fetchingLoader}>
          <div className = {styles.header}><div className = {styles.closeIcon} onClick = {()=>close()}><IoClose/></div></div>
          <div className = {styles.spinner}></div>
        </div>}
    </div>
  )
}

export default DeleteTarot