import React, { useCallback, useEffect, useRef } from 'react'
import styles from "../css/TarotMenu.module.css"
import { IoEyeOutline } from "react-icons/io5";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { LuTrash2 } from "react-icons/lu";

const TarotMenu = ({handleMenu,close, viewModal = false, tarotMenu = false}) => {
    const menu = useRef(null)

    useEffect(()=>{

        const handleClick = (e) => {
            if (!menu.current.parentNode.contains(e.target)){
                close()
            }
        }

        const handleResize = () => {
            close()
        }
        window.addEventListener("mousedown",handleClick)
        window.addEventListener("resize",handleResize)
        return () => {
            window.removeEventListener("mousedown",handleClick)
            window.removeEventListener("resize",handleResize)
        }
    },[close])

    const execute = (key) => {
        handleMenu(key)
        close()
    }

    const setMenu = useCallback((el)=>{
        if (el){
            let button  = el.previousElementSibling
            el.style.maxHeight = ""
            if (button){
                if (viewModal){
                    el.style.top = `${button.getBoundingClientRect().bottom + 10}px`
                    el.style.maxHeight = `${window.innerHeight - button.getBoundingClientRect().bottom - 10}px`
                }
                else{
                    if (button.getBoundingClientRect().top - el.offsetHeight > 10){
                        el.style.top = `${button.getBoundingClientRect().top - el.offsetHeight - 10}px`
                    }
                    else{
                        el.style.top = `${button.getBoundingClientRect().bottom + 10}px`
                    }
                }
                el.style.left = `${button.getBoundingClientRect().right - el.offsetWidth}px`
            }
            menu.current = el
        }
    },[viewModal])

  return (
    <div className = {`${styles.menu} ${viewModal && styles.modal}`}  ref = {setMenu}>
        {!viewModal && <div className = {styles.menuItem}  onClick = {()=>execute("view")}>
            <div className = {`${styles.menuIcon} ${styles.view} `}><IoEyeOutline/></div>
            <div className = {styles.menuText}>View details</div>
        </div>}
        <div className = {styles.menuItem}>
            <div className = {`${styles.menuIcon} ${styles.continue} `}><IoArrowForwardCircleOutline/></div>
            <div className = {styles.menuText}>Continue</div>
        </div>
        <div className = {styles.divider}></div>
        <div className = {styles.menuItem} onClick = {()=>execute("delete")}>
            <div className = {`${styles.menuIcon} ${styles.delete} `}><LuTrash2/></div>
            <div className = {styles.menuText}>Delete Game</div>
        </div>
    </div>
  )
}

export default TarotMenu