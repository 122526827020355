import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/Calendar.module.css"
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const days = ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"]

const Calendar = ({selectedDate,selectDate,close}) => {
    const today = new Date()
    const [currentDate,setCurrentDate] = useState({month:!selectedDate ? today.getMonth() : selectedDate.getMonth() ,year:!selectedDate ? today.getFullYear() : selectedDate.getFullYear()})
    const box = useRef(null)

    useEffect(()=>{
      const handleClick = (e) => {
        if (!box.current.parentNode.contains(e.target)){
          close()
        }

      }
      window.addEventListener("mousedown",handleClick)
      return () => {
        window.removeEventListener("mousedown",handleClick)
      }
    },[close])

    const currentData = useMemo(()=>{
      const firstDateOfMonth = new Date(currentDate.year, currentDate.month , 1);
      const firstDayOfMonth = firstDateOfMonth.getDay() === 0 ? 7 : firstDateOfMonth.getDay();
      const startDate = new Date(firstDateOfMonth);
      startDate.setDate(startDate.getDate() - (firstDayOfMonth - 1));
      const lastDateOfMonth = new Date(currentDate.year, currentDate.month + 1, 0)
      const monthArray = []
      let currentValue = new Date(startDate)
      while (true) {
          monthArray.push(new Date(currentValue))
          if (currentValue.getDay() === 0 && lastDateOfMonth.getDate() === currentValue.getDate()){
            break
           }
          else if (currentValue.getDay() === 0 && currentDate.month !== currentValue.getMonth()){
            break
          }
          currentValue.setDate(currentValue.getDate() + 1);
      }
      let data = []
      for (let i = 0; i < monthArray.length; i += 7) {
        let chunk = monthArray.slice(i, i + 7);
        data.push(chunk);
      }


      return data
    },[currentDate])

    const goPrev = () => {
      if (currentDate.month === 0){
        setCurrentDate({month:11,year:currentDate.year - 1})
      }
      else{
        setCurrentDate({...currentDate, month:currentDate.month - 1})
      }
    }

    const goNext = () => {
      if (currentDate.month === 11){
        setCurrentDate({month:0,year:currentDate.year + 1})
      }
      else{
        setCurrentDate({...currentDate, month:currentDate.month + 1})
      }
    }

    const handleDate = (date) => {
      selectDate(date)
      close()
    }

    const isSelectedDate = (current_date) => {
      const selected_date = new Date(selectedDate)
      selected_date.setHours(0,0,0,0)
      const current_date_copy = new Date(current_date)
      current_date_copy.setHours(0,0,0,0)
      return selected_date.getTime() === current_date_copy.getTime()
    }

    const setMenu = useCallback((el)=>{
      if (el){
          let button  = el.previousElementSibling
          if (button){
            if (window.innerWidth >= 750){
              const top = button.getBoundingClientRect().top - el.offsetHeight / 2 - button.offsetHeight / 2 < 5 ? 5 : button.getBoundingClientRect().top - el.offsetHeight / 2 - button.offsetHeight / 2 
              el.style.top = `${top}px`
              el.style.left = `${button.getBoundingClientRect().left - el.offsetWidth}px`
              el.style.maxHeight = `${top === 5 ? button.getBoundingClientRect().top - 10 : window.innerHeight - top - 10}px`

            }
            else{
              if (window.innerHeight - button.getBoundingClientRect().bottom < button.getBoundingClientRect().top){
                el.style.top = `5px`
                el.style.maxHeight = `${button.getBoundingClientRect().top-10}px`
                el.style.left = `${button.getBoundingClientRect().left - 12}px`

              }
              else{
              el.style.top = `${button.getBoundingClientRect().bottom + 5}px`
              el.style.left = `${button.getBoundingClientRect().left - 12}px`
              el.style.maxHeight = `${window.innerHeight - 10 - button.getBoundingClientRect().bottom}px`
              }
            }
          } 
          box.current = el
      }
  },[])

  return (
    <div className = {styles.box} ref = {setMenu}>
      <div className = {styles.calendarHeader}>
        <div className = {styles.arrowIcon} onClick = {()=>goPrev()}><div className = {styles.innerIcon}><IoIosArrowBack/></div></div>
        <div className = {styles.month}>{months[currentDate.month] + " " + currentDate.year}</div>
        <div className = {styles.arrowIcon} onClick = {()=>goNext()}><div className = {styles.innerIcon}><IoIosArrowForward/></div></div>
      </div>
      <div className = {styles.dayRow}>
        {days.map((day,index)=>(
          <div className = {styles.dateText} key = {index}>{day}</div>
        ))}
      </div>
      <div className = {styles.days}>
      {currentData.map((row, rowIndex) => (
          <div className = {`${styles.row} ${currentData.length === 6 ? styles.min : ""}`} key={rowIndex}>
              {row.map((day, itemIndex) => (
                <div className = {`${styles.dateBox} ${(new Date(day).getMonth() !== currentDate.month) ? styles.blur : ""} ${isSelectedDate(day) ? styles.selected : ""}`} key={itemIndex} onClick = {()=>handleDate(day)}>
                  {new Date(day).getDate()}
                </div>
              ))}
          </div>
      ))}
      </div>
    </div>
  )
}

export default Calendar