import React, { useEffect, useRef, useState } from 'react'
import styles from "../css/DeleteDream.module.css"
import { IoClose } from "react-icons/io5";
import { PiSealWarningFill } from "react-icons/pi";
import api from '../../../utils/api';
import NotFoundDream from '../../errors/js/NotFoundDream';
import CrashModal from '../../errors/js/CrashModal';

const DeleteDream = ({id,handleDelete,close}) => {
    const [confirmName,setConfirmName] = useState("")
    const [dream,setDream] = useState(null)
    const [loading,setLoading] = useState(false)
    const [notFound,setNotFound] = useState(false)
    const [crashError,setCrashError] = useState(false)
    const fetching = useRef(true)
  
    useEffect(()=>{
      if (fetching.current){
          fetching.current = false
          let startTime = performance.now()
          api.get(`/dreamReadings/${id}`)
          .then((res)=>{
              let endTime = performance.now()
              let duration = endTime - startTime
              setTimeout(()=>{
                  setDream(res.data)
              },duration < 1000 ? 1000 - duration : 0)
          })
          .catch((err)=>{
            if (err.response?.status === 404){
              setNotFound(true)
            }
            else{
              setCrashError("It looks like something unexpected occured when trying to retrieve the selected dream reading.")
            }
          })
      }
    },[id])
  
    const deleteReading = (e) => {
      setLoading(true)
      e.preventDefault()
      let startTime = performance.now()
      api.delete(`/dreamReadings/delete/${id}`)
      .then((_)=>{
          let endTime = performance.now()
          let duration = endTime - startTime
          setTimeout(()=>{
              handleDelete()
              setLoading(false)
          },duration < 1400 ? 1400 - duration : 0)
      })
      .catch((err)=>{
        if (err.response?.status === 404){
          setNotFound(true)
        }
        else{
          setCrashError("It looks like something unexpected occured when trying to delete the selected dream reading.")
        }
      })
  
    }

  return (
    crashError ? <CrashModal msg = {crashError} close = {()=>close()}/> : notFound ? <NotFoundDream close = {()=>close()}/> : <div className = {styles.wrapper}>
        <form className = {styles.box} onSubmit={deleteReading}>
            <div className = {styles.header}>
                <div className  = {styles.titleBox}>
                    <div className = {styles.titleIcon}><PiSealWarningFill/></div>
                    <div className = {styles.title}>Are you sure?</div>
                </div>
                <div className = {styles.closeIcon} onClick = {()=>close()}><IoClose/></div>
            </div>
            {dream ? <div className = {styles.contentWrapper}>
                <div className = {styles.content}>
                    <div className = {styles.text}>This action <b>CANNOT</b> be undone. This will permanently delete &ldquo; <b>{dream.title}</b> &rdquo; along with its interpetation and follow-up questions.</div>
                    <div className = {styles.section}>
                        <label htmlFor = "name">Please type the name of the dream to confirm</label>
                        <input type = "text" value = {confirmName} name = "confirmName" onChange = {(e)=>setConfirmName(e.target.value)}/>
                    </div>
                </div>
                <div className = {styles.footer}>
                    {loading ? <div className = {styles.loadingBtn}><div className = {styles.loader}></div></div> : <button type = "submit" className = {styles.deleteBtn} disabled = {dream.title.toLowerCase().trim() !== confirmName.toLowerCase().trim()}>I understand, delete this dream</button>}
                </div>
            </div> : <div className = {styles.fetchingLoader}><div className = {styles.spinner}></div></div>}
        </form>
    </div>
  )
}

export default DeleteDream