import React, { useState } from 'react'
import styles from "../css/LoginPage.module.css"
import Logo from "../../auth/media/darkLogo.png"
import { IoIosWarning } from "react-icons/io";
import validator from 'validator';
import axios from 'axios';
import { setUserData } from '../../../utils/auth';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const [data,setData] = useState({email:"",password:""})
  const [errors,setErrors] = useState({})
  const navigate = useNavigate()

  const handleChange = (e) => {
    if (errors.auth || errors.crash){
      setErrors({crash:"",auth:""})
    }
    let name = e.target.name
    let value = e.target.value
    setData({...data,[name]:value})
  }

  const handleSubmit = (e) => {
    if (errors.auth || errors.crash){
      setErrors({crash:"",auth:""})
    }
    e.preventDefault()
    if (!validator.isEmail(data.email)){
      setErrors({email:"Invalid Email Address"})
      return
    }
    else{
      axios.post(`${process.env.REACT_APP_API_URL}/admin/users/login`,data)
      .then((res)=>{
        setUserData(res.data)
        navigate('/admin/dashboard')
      })
      .catch((err)=>{
        if (err.response?.status === 400){
          setErrors({...errors,auth:err.response.data})
        }
        if (err.response?.status === 500){
          setErrors({...errors,crash:err.response.data})
        }
      })

    }

  }

  return (
    <div className = {styles.wrapper}>
      <form className = {styles.box} autoComplete = "off" onSubmit = {handleSubmit}>
        <div className = {styles.header}>
          <div className = {styles.logo}><img src = {Logo} alt = ""/></div>
          <div className = {styles.appTitle}>Astroora</div>
          <div className = {styles.admin}> Administration</div>
        </div>
        <div className = {styles.inputs}>
            {(errors.auth || errors.crash) && <div className = {styles.errorBox}>
                <div className = {styles.warningIcon}><IoIosWarning/></div>
                <div className = {styles.errorText}>{errors.auth ? errors.auth : errors.crash}</div>
            </div>
            }
          <div className = {styles.inputBox}>
            <label htmlFor='email'>Email</label>
            <input type = "text" name = "email" value = {data.email} placeholder = "user@email.com" onChange = {handleChange} autoComplete = "off"/>
            {errors.email && <div className = {styles.error}>{errors.email}</div>}
          </div>
          <div className = {styles.inputBox}>
            <label htmlFor='password'>Password</label>
            <input type = "password" name = "password" value = {data.password} placeholder = "Type your password here" onChange = {handleChange} autoComplete = "off"/>
          </div>
        </div>
        <button type = "submit">Log in</button>
      </form>
    </div>
  )
}

export default LoginPage