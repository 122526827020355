import axios from 'axios'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import styles from "../css/ResetPasswordForm.module.css"
import { PiWarningFill } from 'react-icons/pi'
import { BsCheckLg } from 'react-icons/bs'
import { LiaUnlockAltSolid } from 'react-icons/lia'
import DarkLogo from "../media/darkLogo.png"
import Logo from "../media/logo.png"
import ResetImage from "../media/resetPassword.png"
import { LuKey } from "react-icons/lu"
import LoaderImage from "../media/reset_validating.png"
import Robot from "../media/robot.png"

const ResetPasswordForm = () => {
    const [data,setData]                   = useState({password:"",confirm_password:""})
    const [errors,setErrors]               = useState({password:"",confirm_password:""})
    const [validateError,setValidateError] = useState(false)
    const [resetError,setResetError]       = useState("")
    const [resetSuccess,setResetSuccess]   = useState(false)
    const [validating,setValidating]       = useState(true)
    const [loading,setLoading]             = useState(false)
    const navigate                         = useNavigate()
    const [searchParams, _]                = useSearchParams()
    let token                              = searchParams.get('token')
    const validatingRef = useRef(true)

    const validateToken = useCallback(()=>{
        setValidating(true)
        const startTime = performance.now()
        axios.get(process.env.REACT_APP_API_URL + '/validate-reset/'+token)
        .catch((err)=>{
            if (err?.response?.status === 400){
                let error = err.response?.data
                if (error === "USED_TOKEN"){
                    navigate('/forgot-password',{state: {error: "Your reset link was already used. Get a new link!"}})
                }
                else if (error === "EXPIRED_TOKEN"){
                    navigate('/forgot-password',{state: {error: "Your reset link has expired. Get a new link!"}})
                }
                else if (error === "INVALID_TOKEN"){
                    navigate('/forgot-password',{state: {error: "Your reset link is invalid. Try again!"}})
                }
            }
            else{
                setValidateError(true)
            }
        })
        .finally(()=>{
            const endTime = performance.now()
            const duration = endTime - startTime
            setTimeout(() => {
                setValidating(false)
            }, duration < 8000 ? 8000 - duration : 0)
        })
    },[navigate,token])

    useEffect(()=>{
        if (!token){
            setTimeout(()=>{
                navigate('/forgot-password',{state: {error: "Your reset link is invalid. Try again!"}})
            },700)
            return
        }
        if (validating && validatingRef.current){
            validatingRef.current = false
            validateToken()
        }
    },[validating,validateToken,token,navigate])


    const handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        setData({...data,[name]:value})
        if (errors[name]){
            setErrors({...errors,[name]:""})
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let valid = true
        if (data.password.length < 8){
            setErrors((prevErrors)=>{return {...prevErrors,password:"At least 8 characters"}})
            valid = false
        }
        if (data.password !== data.confirm_password){
            setErrors((prevErrors)=>{return {...prevErrors,confirm_password:"Passwords do not match"}})
            valid = false
        }

        if (valid){
            setLoading(true)
            axios.put(process.env.REACT_APP_API_URL + '/reset-password',{token:token,new_password:data.password})
            .then((_)=>{
                setResetSuccess(true)
                setData({password:"",confirm_password:""})
            })
            .catch((err)=>{
                if (err?.response?.status === 400){
                    let error = err.response?.data
                    if (error.token?.includes("USED_TOKEN")){
                        navigate('/forgot-password',{state: {error: "Your reset link was already used. Get a new link!"}})
                    }
                    else if (error?.token?.includes("EXPIRED_TOKEN")){
                        navigate('/forgot-password',{state: {error: "Your reset link has expired. Get a new link!"}})
                    }
                    else if (error?.token?.includes("INVALID_TOKEN")){
                        navigate('/forgot-password',{state: {error: "Your reset link is invalid. Try again!"}})
                    }

                    if (error?.new_password){
                        setErrors({...errors,password:error.new_password})
                    }
                }
                else{
                    setResetError("Unable to reset password. Try later")
                }

            })
            .finally(()=>{
                setLoading(false)
            })
        }
    }


  return (
    <div className = {styles.wrapper}>
        {!validating ? (validateError ? 
            <div className = {`${styles.box} ${styles.crash}`}>
                <div className = {styles.appTitle}>
                    <div className = {styles.logo}>
                        <img src = {Logo} alt = ""></img>
                    </div>
                    <div className = {styles.name}>Astrooo</div>
                </div>
                <div className = {styles.robotImage}>
                    <img src = {Robot} alt = ""/>
                </div>
                <div className = {styles.title}>Ooops, Something went wrong</div>
                <div className = {styles.desc}>It looks like unexpected error occured while attempting to validate the password reset link. Click on the button below to try again or refresh this page.</div>
                <div className = {styles.submitBtn} style = {{ textTransform: "none" }} onClick = {()=>validateToken()}>Re-validate link</div>
            </div>
            : <div className = {styles.box}>
            <div className = {styles.leftSection}>
                <div className = {styles.appTitle}>
                    <div className = {styles.logo}>
                        <img src = {DarkLogo} alt = ""/>
                    </div>
                    <div className = {styles.name}>Astrooo</div>
                </div>
                <div className = {styles.imageSection}>
                    <img src = {ResetImage} alt = ""/>
                </div>
            </div>
            <div className = {styles.rightSection}>
                <div className = {styles.appTitle}>
                    <div className = {styles.logo}>
                        <img src = {Logo} alt = "/"></img>
                    </div>
                    <div className = {styles.name}>Astrooo</div>
                </div>
                <form className = {styles.form} onSubmit = {handleSubmit} autoComplete="off">
                        <div className = {styles.keyIcon}><LuKey/></div>
                        <div className={styles.titleWrapper}>
                            <div className = {styles.title}>Reset your <span>Astrooo</span> password</div>
                            <div className = {styles.instructions}>Please enter and confirm a new password to change your<br/> login password</div>
                        </div>
                        <div className={styles.inputs}>
                            {(resetError || validateError) ? <div className = {styles.errorBanner}>
                                <div className = {styles.errorIcon}><PiWarningFill/></div>
                                <div className = {styles.errorText}>{resetError ? resetError : validateError}</div>
                            </div> : 
                            resetSuccess && <div className = {styles.successBanner}>
                                <div className = {styles.successIcon}><BsCheckLg/></div>
                                <div className = {styles.successText}>Your password has been succesfully changed.</div>
                            </div>}
                            <div className = {styles.inputContainer}>
                                <label htmlFor = "password"> New Password</label>
                                <div className = {`${styles.inputBox} ${errors.password ? styles.error : ""}`}>
                                    <div className = {`${styles.inputIcon} ${!data.password && styles.placeholder}`}>{errors.password ? <PiWarningFill/> : <LiaUnlockAltSolid/>}</div>
                                    <input type = {data.password ? "password" : "text"} name = "password" placeholder = "Enter a new password"  value = {data.password} onChange = {handleChange} disabled = {validateError} autoComplete="off"/>
                                </div>
                                {errors.password && <div className = {styles.errorBox}>{errors.password}</div>}
                            </div>
                            <div className = {styles.inputContainer}>
                                <label htmlFor = "confirm_password"> Confirm Password</label>
                                <div className = {`${styles.inputBox} ${errors.confirm_password ? styles.error : ""}`}>
                                    <div className = {`${styles.inputIcon} ${!data.confirm_password && styles.placeholder}`}>{errors.confirm_password ? <PiWarningFill/> : <LiaUnlockAltSolid/>}</div>
                                    <input type = {data.confirm_password ? "password" : "text"} name = "confirm_password" placeholder = "Repeat your new password"  value = {data.confirm_password} onChange = {handleChange} disabled = {validateError} autoComplete="off"/>
                                </div>
                                {errors.confirm_password && <div className = {styles.errorBox}>{errors.confirm_password}</div>}
                            </div>
                        </div>
                        {!loading ? <button
                            type = "submit"
                            className = {styles.submitBtn}
                            disabled = {!data.password || !data.confirm_password || validateError || errors.confirm_password || errors.password}
                        >
                            Reset Password
                        </button> : <div className = {styles.loadingBtn}><div className = {styles.loader}></div></div>}
                        <div className = {styles.linkWrapper}>No longer want to reset password? <NavLink to = "/login">Login</NavLink></div>
                </form>
            </div>
        </div>) : 
        <div className = {styles.loadingWrapper}>
            <div className = {styles.validatingLoader}>
                <img src = {LoaderImage} alt = ""/>
            </div>
            <div className = {styles.loadingText}>Validating Reset Link</div>
        </div>}
    </div>
  )
}

export default ResetPasswordForm