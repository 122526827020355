import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/UserModal.module.css"
import { getName } from 'country-list'
import ReactCountryFlag from 'react-country-flag'
import CountryDropDown from './CountryDropDown'
import { TfiUser, TfiWorld } from 'react-icons/tfi'
import { IoClose } from "react-icons/io5"
import { RiVipDiamondFill } from 'react-icons/ri'
import { LiaUserEditSolid } from "react-icons/lia";
import { PiLockKeyFill } from "react-icons/pi";
import axios from 'axios'
import { BirthDateSelector } from './CreateUser'

const EditUser = ({id,reload,closeModal}) => {
    const [originalData,setOriginalData] = useState(null)
    const [modifiedData,setModifiedData] = useState(null)
    const [showCountries,setShowCountries] = useState(false)
    const [notFound,setNotFound] = useState(false)
    const [crashError,setCrashError] = useState(false)
    const [errors,setErrors] = useState({})
    const [loading,setLoading] = useState(false)
    const [resetPending,setResetPending] = useState(false)
    const nameInput = useRef(null)
    const fetching = useRef(true)

    useEffect(()=>{
      if (fetching.current){
        fetching.current = false
        const start  = performance.now()
        axios.get(`${process.env.REACT_APP_API_URL}/admin/users/${id}`)
        .then((res)=>{
            const end = performance.now()
            const waiting_time = end - start < 500 ? (500 - end - start) : 0
            setTimeout(()=>{
                let data = {
                    fullname: res.data.fullname,
                    email: res.data.email,
                    gender: res.data.gender,
                    birthdate: {year:new Date(res.data.birthdate).getFullYear(),month:new Date(res.data.birthdate).getMonth(),day:new Date(res.data.birthdate).getDate()},
                    is_active: res.data.is_active,
                    is_admin: res.data.is_admin,
                    balance: res.data?.financial_details?.balance.toString(),
                    country: res.data.country,
                    avatar: res.data.avatar
                }
                setOriginalData(Object.assign({},data))
                setModifiedData(Object.assign({},data))
            },waiting_time)
        })
        .catch((err)=>{
            if (err?.response?.status === 404){
                setNotFound(true)
                reload()
            }
            else{
                setCrashError(true)
            }
        })
      }
    },[id,reload])

    const handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (errors[name]){
            setErrors((prevErrors)=>{
                let copy = {...prevErrors}
                delete copy[name]
                return copy
            })
        }
        if (name === "fullname"){
            value = value.replace(/\s+/g, ' ')
            setModifiedData({...modifiedData,[name]:value})
        }
        else if (name === "balance"){
            if (/^\d*$/.test(value)) {
                if (parseInt(value) === 0 || parseInt(value) > 100){
                    e.target.classList.add(styles.shake)
                    setTimeout(()=>{
                        e.target.classList.remove(styles.shake)
                    },300)
                    return
                }
                setModifiedData({...modifiedData,[name]:value})
            }
        }
        else{
            setModifiedData({...modifiedData,[name]:value})
        }
    }

    const handleKeyDown = (e) => {
        const nonPrintableKeys = new Set([
            'Backspace', 'Tab', 'Enter', 'Shift', 'Control', 'Alt', 'Pause', 'CapsLock', 'Escape', 'PageUp', 'PageDown',
            'End', 'Home', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Insert', 'Delete', 'F1', 'F2', 'F3', 'F4',
            'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12', 'NumLock', 'ScrollLock', 'ContextMenu', 'Meta'
        ]);

        if (!nonPrintableKeys.has(e.key) && modifiedData.fullname.length >= 25){
          nameInput.current.classList.add(styles.shake)
          setTimeout(()=>{
            nameInput.current.classList.remove(styles.shake)
          },300)
        }
    }

    const isDisabled = useMemo(()=>{
      if (!modifiedData) return
        if (JSON.stringify(originalData) === JSON.stringify(modifiedData)){
          return true
        }
        if (Object.keys(modifiedData).some(key => !modifiedData[key] && !["is_admin","is_active","avatar"].includes(key))){
            return true
        }
        if (Object.values(modifiedData.birthdate).some(value => value === null)){
            return true
        }
        if (Object.keys(errors).length){
            return true
        }
    },[modifiedData,originalData,errors])

    const requestReset = () => {
        setResetPending(true)
        setTimeout(()=>{
          setResetPending(false)
        },300)
    }

    const getData = () => {
        let formData = new FormData()
        if (modifiedData.fullname !== originalData.fullname){
            formData.append('fullname',modifiedData.fullname)
        }
        if (modifiedData.email !== originalData.email){
            formData.append('email',modifiedData.email)
        }
        if (modifiedData.gender !== originalData.gender){
            formData.append('gender',modifiedData.gender)
        }
        if (modifiedData.balance !== originalData.balance){
            formData.append('balance',Number(modifiedData.balance))
        }
        if (JSON.stringify(modifiedData.birthdate) !== JSON.stringify(originalData.birthdate)){
            let month = (modifiedData.birthdate.month + 1).toString().padStart(2,'0')
            let day = (modifiedData.birthdate.day + 1).toString().padStart(2,'0')
            let formattedDate = modifiedData.birthdate.year + '-' + month + '-' + day
            formData.append('birthdate',formattedDate)
        }


        if (modifiedData.avatar !== originalData.avatar){
            if (!modifiedData.avatar){
                formData.append('avatar','')
                formData.append('avatar_removed',true)
            }
            else{
                formData.append('avatar',modifiedData.avatar)
            }
        }
        return formData
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        if (parseInt(modifiedData.balance) < originalData.balance){
            setErrors((prevErrors)=>{return {...prevErrors,balance:"Balance cannot be decreased manually"}})
        }
        else{
            setLoading(true)
            const start = performance.now()
            const data = getData()
            axios.put(`${process.env.REACT_APP_API_URL}/admin/users/update/${id}`,data)
            .then((_)=>{
                const end = performance.now()
                const waiting_time = end - start < 500 ? (500 - end - start) : 0
                setTimeout(()=>{
                    reload()
                    closeModal()
                },waiting_time)
            })
            .catch((err)=>{
                const end = performance.now()
                const waiting_time = end - start < 500 ? (500 - end - start) : 0
                setTimeout(()=>{
                    if (err?.response?.data?.email){
                        setErrors({...errors,email:err.response.data.email})
                    }
                    else if (err.response?.status === 404){
                        setNotFound(true)
                        reload()
                    }
                    else{
                        setCrashError(true)
                    }
                    setLoading(false)
                },waiting_time)
            })
        }
    }

    const avatarSrc = useMemo(() => {
        if (modifiedData?.avatar){
            if (modifiedData.avatar !== originalData.avatar){
                return URL.createObjectURL(modifiedData.avatar)
            }
            else{
                return `${process.env.REACT_APP_API_URL}/profileImages/${originalData.avatar}`
            }
        }
        else{
            return null
        }
    },[modifiedData?.avatar,originalData?.avatar])

    const setProfileImage = (e) =>{
        let image = e.target.files[0]
        setModifiedData({...modifiedData,avatar:image})
        e.target.value = ""
    }


  return (
    <div className = {styles.wrapper}>
        {modifiedData ? <form className = {styles.box} autoComplete='off' onSubmit = {handleSubmit}>
            <header>
                <div className = {styles.editIcon}><LiaUserEditSolid/></div>
                <div className = {styles.title}>Edit User Details</div>
                <div className = {styles.closeIcon} onClick = {()=>closeModal()}><IoClose/></div>
            </header>
            <main>
              <div className = {styles.statusArea}>
                      <div className = {styles.statusBox}>
                          <div className = {`${styles.statusSlider} ${modifiedData.is_active && styles.checked}`}>
                              <div className = {styles.statusCircle} onClick = {()=>setModifiedData({...modifiedData,is_active:!modifiedData.is_active})}></div>
                          </div>
                          <div className = {styles.statusText}>{modifiedData.is_active ? "Active" : "Inactive"}</div>
                      </div>
                </div>
                <div className = {styles.permissionsWrapper}>
                    <div className = {`${styles.permissionBox} ${modifiedData.is_admin && styles.checked}`} onClick = {()=>setModifiedData({...modifiedData,is_admin:true})}>
                        <div className = {styles.permissionCircle}>
                            {modifiedData.is_admin && <div className = {styles.innerCircle}></div>}
                        </div>
                        <div className = {styles.perm}>Administrator</div>
                    </div>
                    <div className = {`${styles.permissionBox} ${!modifiedData.is_admin && styles.checked}`} onClick = {()=>setModifiedData({...modifiedData,is_admin:false})}>
                        <div className = {styles.permissionCircle}>
                            {!modifiedData.is_admin && <div className = {styles.innerCircle}></div>}
                        </div>
                        <div className = {styles.perm}>Basic User</div>
                    </div>
                </div>
                <div className = {styles.inputWrapper}>
                  <div className = {styles.labelBox}>Photo</div>
                  <div className = {styles.imageBox}>
                    <label className = {styles.imageCircle} htmlFor='avatar'>
                        {!modifiedData.avatar ? 
                            <TfiUser/> :
                            <img src = {avatarSrc} alt = ""/>
                        }
                    </label>
                    <div className = {styles.removeBtn} onClick = {()=>setModifiedData({...modifiedData,avatar:null})}>Remove Photo</div>
                    <input type = "file" id = "avatar" onChange={(e)=>setProfileImage(e)} accept="image/*"/>
                  </div>
                </div>
                <div className = {styles.inputWrapper}>
                    <label htmlFor='fullname'>Name</label>
                    <div className = {styles.inputBox} ref = {nameInput} tabIndex={-1} onKeyDown = {handleKeyDown}>
                        <div className = {styles.length}>{modifiedData.fullname.length}/25</div>
                        <input type = "text" name = 'fullname' value = {modifiedData.fullname} onChange = {handleChange} maxLength = {25} autoComplete='off'/>
                        {errors.email && <div className = {styles.error}>{errors.email}</div>}
                    </div>
                </div>
                <div className = {styles.inputWrapper}>
                    <label htmlFor='email'>Email</label>
                    <div className = {`${styles.inputBox} ${styles.email}`}>
                        <input type = "text" name = 'email' value = {modifiedData.email} onChange = {handleChange} autoComplete='off'/>
                    </div>
                </div>
                <div className = {styles.inputWrapper}>
                    <label htmlFor='password'>Password</label>
                    {resetPending ? 
                      <div className = {styles.resetLoadingBtn}><div className = {styles.spinner}></div></div> : 
                      <button type = "button" className = {styles.resetBtn} onClick = {()=>requestReset()}>
                        <div className = {styles.resetIcon}><PiLockKeyFill/></div>Request Reset
                      </button>
                    }
                </div>
                <div className = {styles.doubleWrapper}>
                    <div className = {styles.inputWrapper}>
                        <label htmlFor='gender'>Gender</label>
                        <div className = {styles.choices}>
                            <div className = {`${styles.choice} ${modifiedData.gender === "female" && styles.checked}`} onClick = {()=>setModifiedData({...modifiedData,gender:"female"})}>Female</div>
                            <div className = {`${styles.choice} ${modifiedData.gender === "male" && styles.checked}`} onClick = {()=>setModifiedData({...modifiedData,gender:"male"})}>Male</div>
                            <div className = {`${styles.choice} ${modifiedData.gender === "other" && styles.checked}`} onClick = {()=>setModifiedData({...modifiedData,gender:"other"})}>Other</div>
                        </div>
                    </div>
                    <div className = {styles.inputWrapper}>
                        <label htmlFor='country'>Country</label>
                        <div className = {styles.countryWrapper}>
                            <div className={styles.countryBtn} onClick = {()=>setShowCountries(!showCountries)}>
                                {modifiedData.country ? <div className = {styles.flag}><ReactCountryFlag countryCode={modifiedData.country} svg /></div> : <div className = {styles.worldIcon}><TfiWorld/></div>}
                                {modifiedData.country ? <div className = {styles.country}>{getName(modifiedData.country)}</div> : <div className = {styles.placeholder}>Set Country</div>}
                            </div>
                            {showCountries &&
                                <CountryDropDown 
                                    selectedCountry = {modifiedData.country}
                                    selectCountry = {(value)=>setModifiedData({...modifiedData,country:value})}
                                    closeMenu={()=>setShowCountries(false)}
                                />}
                        </div>
                    </div>
                </div>
                <div className = {`${styles.inputWrapper} ${styles.birthdate}`}>
                    <label htmlFor='birthdate'>Date of Birth</label>
                    <BirthDateSelector birthdate={modifiedData.birthdate} selectDate={(attr,value)=>setModifiedData({...modifiedData,birthdate:{...modifiedData.birthdate,[attr]:value}})}/>
                </div>
                <div className = {styles.inputWrapper}>
                    <label htmlFor='balance'>Current Balance - (Max: 1000)</label>
                    <div className = {styles.instructions}>Manual change of balance only allows for increase in number of diamonds (for security reasons).</div>
                    <div className = {`${styles.inputBox} ${styles.balance} ${errors.balance && styles.errorBox}`}>
                        <input type = "text" name = "balance" value = {modifiedData.balance} onChange = {handleChange} placeholder = "N&deg;"  autoComplete='off'/>
                        <div className = {styles.diamondIcon}><RiVipDiamondFill/></div>
                    </div>
                    {errors.balance && <div className = {styles.error}>{errors.balance}</div>}
                </div>
            </main>
            <footer>
                <button type = "button" className = {styles.cancelBtn}>Cancel</button>
                {loading ? <div className = {`${styles.loadingBtn} ${styles.save}`}>
                    <div className = {styles.spinner}></div>
                </div> : <button type = "submit" className = {styles.saveBtn} disabled = {isDisabled}>Save Changes</button>}
            </footer>
        </form> :  <div className = {styles.box}>
            <header>${process.env.REACT_APP_API_URL}/admin/users
                <div className = {styles.editIcon}><LiaUserEditSolid/></div>
                <div className = {styles.title}>Edit User Details</div>
                <div className = {styles.closeIcon} onClick = {()=>closeModal()}><IoClose/></div>
            </header>
            <div className = {styles.loadingArea}>
              <div className = {styles.loadingSpinner}></div>
            </div>
          </div>
        }
    </div>
  )
}

export default EditUser