import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/Dashboard.module.css"
import NavBar from './NavBar'
import { FaCoins } from "react-icons/fa6";
import { TbArrowsExchange } from "react-icons/tb";
import { IoIosGift } from "react-icons/io";
import { FaUsers } from "react-icons/fa";
import { GiCardRandom } from 'react-icons/gi';
import { IoMoon } from 'react-icons/io5';
import { FaGifts } from "react-icons/fa";
import AddPromoCode from './AddPromoCode';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


const Dashboard = () => {
  const [showAddPromoCode,setShowAddPromoCode] = useState(false)
  const navigate = useNavigate()
  const [data,setData] = useState(null)
  const [crashError,setCrashError] = useState(false)
  const fetching = useRef(true)

  useEffect(()=>{
    if (fetching.current){
      fetching.current = false
      axios.get(`${process.env.REACT_APP_API_URL}/admin/users/dashboard/overview`)
      .then((res)=>{
        setData(res.data)
      })
      .catch((err)=>{
        setCrashError(true)
      })
    }
  })

  const formatDate = useCallback((date)=>{
    const day = date.getDate().toString().padStart(2, '0');
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  },[])

  return (
    <div className = {styles.wrapper}>
      <div className = {styles.navBar}>
          <NavBar page = "dashboard"/>
      </div>
      <div className = {styles.box}>
        <header>
          <div className = {styles.mainHeader}>
            <div className = {styles.mainTitle}>
              <div className = {styles.title}>Welcome Back</div>
              <div className = {styles.waveIcon}>&#128075;</div>
            </div>
            <div className = {styles.subtitle}> Dive Into Your Dashboard: View Users, Revenue, Transactions & More</div>
          </div>
          <div className = {styles.playerBtn}>
            Switch to player mode
          </div>
        </header>
        {!data ? <SkeletonDashboard/> : <main>
          <div className = {styles.boxes}>
            <div className = {styles.stats}>
              <div className = {styles.statBox}>
                <div className = {styles.iconBox}><FaCoins/></div>
                <div className = {styles.num}>{data.revenue}</div>
                <div className = {styles.statName}>Revenue</div>
              </div>
              <div className = {styles.statBox} onClick = {()=>navigate('/admin/transactions')}>
                <div className = {styles.iconBox}><TbArrowsExchange/></div>
                <div className = {styles.num}>{data.num_transactions}</div>
                <div className = {styles.statName}>Transactions</div>
              </div>
              <div className = {styles.statBox} onClick = {()=>navigate('/admin/promocodes')}>
                <div className = {styles.iconBox}><IoIosGift/></div>
                <div className = {styles.num}>{data.num_promo_codes}</div>
                <div className = {styles.statName}>Promo Codes</div>
              </div>
              <div className = {styles.statBox} onClick = {()=>navigate('/admin/users')}>
                <div className = {styles.iconBox}><FaUsers/></div>
                <div className = {styles.num}>{data.num_users}</div>
                <div className = {styles.statName}>Total Users</div>
              </div>
            </div>
            <div className = {styles.revenueOverview}>
              <div className = {styles.graphTitle}>Revenue Overview</div>
              <Graph data = {data.monthly_revenue}/>
            </div>
            <div className = {styles.gameOverview}>
              <div className = {styles.graphTitle}>Game Statistics</div>
              <GameGraph data = {data}/>
            </div>
            <div className = {styles.invoices}>
                <div className = {styles.invoiceTitle}>Recent Orders</div>
                  <table>
                      <thead>
                          <tr>
                          <th>N&deg; Invoice</th>
                          <th>User</th>
                          <th>Amount</th>
                          <th>Date</th>
                          </tr>
                      </thead>
                      <tbody>
                          {data.invoices.map((invoice, index) => (
                              <tr key={index}>
                                  <td className = {styles.id_number}>#{invoice.transaction_id}</td>
                                  <td className = {styles.player}>{invoice.user}</td>
                                  <td className = {styles.title}>{invoice.amount} Euros</td>
                                  <td className = {styles.created_at}>{formatDate(new Date(invoice.timestamp))}</td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
              </div>
              <div className = {styles.promoCodeBox}>
                <div className = {styles.info}>
                  <div className = {styles.promoTitle}>New Promotional <br/>Code</div>
                  <div className = {styles.promoSubtitle}>Click on the button below to create a promo code</div>
                  <button type = "button" onClick = {()=>setShowAddPromoCode(true)}>New Code</button>
                </div>
                <div className = {styles.image}><FaGifts/>
                </div>
              </div>
          </div>
        </main>}
      </div>
      {showAddPromoCode && <AddPromoCode closeModal = {()=>setShowAddPromoCode(false)}/>}
    </div>
  )
}

const Graph = ({data, skeleton = false}) => {
  const numLabels = 5
  const revenueData = useMemo(()=>{
    return data ? data : [
      { month: 'Jan', revenue: 5000 },
      { month: 'Feb', revenue: 4000 },
      { month: 'Mar', revenue: 6000 },
      { month: 'Apr', revenue: 7000 },
      { month: 'May', revenue: 8000 },
      { month: 'Jun', revenue: 3000 },
      { month: 'Jul', revenue: 4000 },
      { month: 'Aug', revenue: 5000 },
      { month: 'Sep', revenue: 7000 },
      { month: 'Oct', revenue: 6000 },
      { month: 'Nov', revenue: 8000 },
      { month: 'Dec', revenue: 9000 },
    ]
  },[data])


  const maxRevenue = useMemo(()=>{
    return Math.max(...revenueData.map(item => item.revenue))
  },[revenueData])

  const formatNumber = (num) => {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
    } else {
        return num.toFixed(1).toString();
    }
  }

  const valueY = useCallback((i)=>{
    return formatNumber((maxRevenue / numLabels) * (numLabels - i))
  },[maxRevenue])

  return (
    <div className = {styles.graph}>
      <div className= {styles.axisY}>
        {[...Array(numLabels + 1)].map((_, index) => (
            <div className = {styles.labelY} key = {index}>{valueY(index)}</div>
          ))
        }
      </div>
      <div className= {styles.bars}>
        {revenueData.map((item, index) => (
          <div className = {styles.barContainer} key = {index} >
            {skeleton ? <div
              className = {styles.bar}
              style={{
                height: `${(item.revenue / maxRevenue) * 100}%`,
              }}
              ></div> :
              <div
                className = {styles.bar}
                style={{
                  height: "100%",
                }}
                title={`$${item.revenue}`}
              >
                <div className = {`${styles.innerBar} ${item.revenue === maxRevenue && styles.completed}`} style={{height: `${(item.revenue / maxRevenue) * 100}%`}}></div>
              </div>
            }
            <div className = {styles.labelX}>{monthNames[item.month-1] + " " + item.year?.toString().slice(-2)}</div>
          </div>
        ))}
      </div>
    </div>
  )
}



const GameGraph = ({data}) => {

  const higherPercentage = useMemo(() => {
    return data.number_dreams > data.number_tarots ? (data.number_dreams / (data.number_tarots + data.number_dreams) * 100) : (data.number_tarots / (data.number_tarots + data.number_dreams) * 100)
  },[data])

  return (
    <div className = {styles.pieWrapper}>
      <div className={styles.pieChart} style = {{ '--p': higherPercentage}}>
        <div className = {styles.chartContent}>{data.total_games}<br/> Total<br/> Games</div>
      </div>
      <div className = {styles.labels}>
        <div className = {styles.labelBox}>
          <div className = {`${styles.labelIcon} ${data.number_dreams > data.number_tarots && styles.great}`}><GiCardRandom/></div>
          <div className = {styles.labelContent}>
            <div className = {styles.labelNum}>{data.number_dreams}</div>
            <div className = {styles.labelName}>Dreams</div>
          </div>
        </div>
        <div className = {styles.labelBox}>
          <div className = {`${styles.labelIcon} ${data.number_tarots > data.number_dreams && styles.great}`}><IoMoon/></div>
          <div className = {styles.labelContent}>
            <div className = {styles.labelNum}>{data.number_tarots}</div>
            <div className = {styles.labelName}>Tarots</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SkeletonDashboard = () => {
  const revenueData = [
    { month: 'Jan', revenue: 3},
    { month: 'Feb', revenue: 4 },
    { month: 'Mar', revenue: 5 },
    { month: 'Apr', revenue: 6},
    { month: 'May', revenue: 8},
    { month: 'Jun', revenue: 9 },
    { month: 'Jul', revenue: 9 },
    { month: 'Aug', revenue: 8 },
    { month: 'Sep', revenue: 6 },
    { month: 'Oct', revenue: 5 },
    { month: 'Nov', revenue: 4 },
    { month: 'Dec', revenue: 3 },
  ]


  const higherPercentage = useMemo(() => {
    const gameData =  {dreams: 6, tarots: 4}
    return gameData.dreams > gameData.tarots ? (gameData.dreams / (gameData.tarots + gameData.dreams) * 100) : (gameData.tarots / (gameData.tarots + gameData.dreams) * 100)
  },[])


  return (
  <main className = {styles.skeleton}>
    <div className = {styles.boxes}>
      <div className = {styles.stats}>
        <div className = {styles.statBox}>
          <div className = {styles.iconBox}><FaCoins/></div>
          <div className = {styles.skeletonNum}></div>
          <div className = {styles.skeletonStatName}></div>
        </div>
        <div className = {styles.statBox}>
          <div className = {styles.iconBox}><TbArrowsExchange/></div>
          <div className = {styles.skeletonNum}></div>
          <div className = {styles.skeletonStatName}></div>
        </div>
        <div className = {styles.statBox}>
          <div className = {styles.iconBox}><IoIosGift/></div>
          <div className = {styles.skeletonNum}></div>
          <div className = {styles.skeletonStatName}></div>
        </div>
        <div className = {styles.statBox}>
          <div className = {styles.iconBox}><FaUsers/></div>
          <div className = {styles.skeletonNum}></div>
          <div className = {styles.skeletonStatName}></div>
        </div>
      </div>
      <div className = {styles.revenueOverview}>
        <div className = {styles.titleSkeleton}></div>
        <Graph data = {revenueData} skeleton = {true}/>
      </div>
      <div className = {styles.gameOverview}>
        <div className = {styles.titleSkeleton}></div>
        <div className = {styles.pieWrapper}>
          <div className={styles.pieChart} style = {{ '--p': higherPercentage}}>
          </div>
          <div className = {styles.labels}>
            <div className = {styles.labelBox}>
              <div className = {styles.labelIcon}><GiCardRandom/></div>
              <div className = {styles.labelContent}>
                <div className = {styles.labelNumSkeleton}></div>
                <div className = {styles.labelNameSkeleton}></div>
              </div>
            </div>
            <div className = {styles.labelBox}>
              <div className = {styles.labelIcon}><IoMoon/></div>
              <div className = {styles.labelContent}>
                <div className = {styles.labelNumSkeleton}></div>
                <div className = {styles.labelNameSkeleton}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className = {styles.invoices}>
        <div className = {styles.invoiceTitle}>Recent Orders</div>
        <div className = {styles.skeletonTable}>
          <div className = {styles.skeletonHeader}>
            <div className = {styles.row}>
              {[...Array(4).keys()].map((_,index) => (
                  <div className = {styles.col} key = {index}>
                    <div className = {styles.colContent}></div>
                  </div>
                ))}
            </div>
          </div>
          <div className = {styles.tableContent}>
            {[...Array(3).keys()].map((_,index) => (
              <div className = {styles.row} key = {index}>
                  {[...Array(4).keys()].map((_,col_index) => (
                  <div className = {styles.col} key = {col_index}>
                    <div className = {styles.colContent}></div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className = {styles.promoCodeBox}>
        <div className = {styles.info}>
          <div className = {styles.promoTitleSkeleton}>
            <div className = {styles.line}></div>
            <div className = {styles.line}></div>
          </div>
          <div className = {styles.promoSubtitleSkeleton}>
            <div className = {styles.line}></div>
            <div className = {styles.line}></div>
          </div>
          <div className = {styles.promoBtn}></div>
          <div className = {styles.image}><FaGifts/></div>
        </div>
      </div>
    </div>
  </main>
  )
}


export default Dashboard