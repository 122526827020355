import axios from 'axios'
import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import validator from 'validator'
import styles from "../css/ForgotPasswordForm.module.css"
import { BsCheckLg } from 'react-icons/bs'
import { PiWarningFill } from 'react-icons/pi'
import { RiErrorWarningFill } from 'react-icons/ri'
import { IoMailOutline } from 'react-icons/io5'
import DarkLogo from "../media/darkLogo.png"
import Lock from "../media/lock.png"
import Logo from "../media/logo.png"
import BeigeLock from "../media/beigeLock.png"

const ForgotPasswordForm = () => {
    const [email,setEmail] = useState("")
    const [emailError,setEmailError] = useState("")
    const [resetError,setResetError] = useState("")
    const { state }                  = useLocation();
    const [tokenError,setTokenError] = useState(state?.error)
    const [loading,setLoading]       = useState(false)
    const [success,setSuccess]       = useState({state:false,email:""})

    const sendResetLink = () => {
        setLoading(true)
        let receiver = email ? email : success.email
        axios.post(process.env.REACT_APP_API_URL + '/forgot-password', {email: receiver})
        .then((_)=>{
            setSuccess({state:true,email:receiver})
            setLoading(false)
            setEmail("")
        })
        .catch((err)=>{
            if (err?.response?.status === 400 && err.response?.data?.email){
                setEmailError(err.response.data.email)
            }
            else{
                setResetError("Something went wrong. Try later")
                setSuccess({state:false,email:""})
            }
            setLoading(false)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setResetError("")
        setTokenError("")
        if (!validator.isEmail(email)){
            setEmailError("Invalid email address")
            return
        }
        sendResetLink()
    }

    const handleChange = (e) => {
        setEmail(e.target.value)
        if (emailError){
            setEmailError("")
        }
        if (resetError){
            setResetError("")
        }
    }


  return (
    <div className = {styles.wrapper}>
        <div className = {styles.box}>
            <div className = {styles.leftSection}>
                <div className = {styles.appTitle}>
                    <div className = {styles.logo}>
                        <img src = {Logo} alt = "/"></img>
                    </div>
                    <div className = {styles.name}>Astrooo</div>
                </div>
                <div className = {styles.imageSection}>
                    <img src = {BeigeLock} alt = ""/>
                </div>
                <form className = {styles.form} onSubmit = {handleSubmit} autoComplete="off">
                    <div className={styles.title}>Forgot Your <span>Astrooo</span> <br/>Password?</div>
                    <div className = {styles.instructions}>Enter the email associated with your account and we'll send you an email with instructions on how to reset your password.</div>
                    <div className={styles.inputs}>
                        {(resetError || tokenError) ? <div className = {styles.errorBanner}>
                            <div className = {styles.errorIcon}><PiWarningFill/></div>
                            <div className = {styles.errorText}>{resetError ? resetError : tokenError}</div>
                        </div> : 
                            success.state && <div className = {styles.successBanner}>
                                <div className = {styles.successIcon}><BsCheckLg/></div>
                                <div className = {styles.successText}>Reset link sent succesfully to <span className = {styles.receiver}>{success.email.substring(0,15) + (success.email.length > 15 ? "***" : "")}</span>. <span className = {styles.resendBtn} onClick = {()=>sendResetLink()}>Resend</span></div>
                            </div>}
                        <div className={styles.inputContainer}>
                            <label htmlFor='email'>Email</label>
                            <div className = {`${styles.inputBox} ${emailError ? styles.error : ""}`}>
                                <div className = {`${emailError ? styles.warningIcon : styles.emailIcon} ${!email && styles.placeholder}`}>{emailError ? <RiErrorWarningFill/> : <IoMailOutline/>}</div>
                                <input type = "text" name = "email" placeholder = "Type your email"  value = {email} onChange = {handleChange} autoComplete="off"/>
                            </div>
                            {emailError && <div className = {styles.errorBox}>{emailError}</div>}
                        </div>
                        {!loading ? <button type = "submit" className = {styles.submitBtn} disabled = {!email || emailError}>Send</button> : 
                        <div className = {styles.loadingBtn}><div className = {styles.loader}></div></div>}
                    </div>
                    <div className = {styles.linkWrapper}>Did you remember your password? <NavLink to = "/login">Login</NavLink></div>
                </form>
            </div>
            <div className = {styles.rightSection}>
                <div className = {styles.appTitle}>
                    <div className = {styles.logo}>
                        <img src = {DarkLogo} alt = ""/>
                    </div>
                    <div className = {styles.name}>Astrooo</div>
                </div>
                <div className = {styles.imageSection}>
                    <img src = {Lock} alt = ""/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ForgotPasswordForm