import React from 'react'
import styles from "../css/SavingData.module.css"
import { GiCardRandom } from 'react-icons/gi'
const SavingData = ({text}) => {
  return (
    <div className = {styles.wrapper}>
        <div className = {styles.loadingContainer}>
            <div className = {styles.loaderWrapper}>
                <div className = {styles.pulseLoader}></div>
                <div className = {styles.innerLoader}>
                    <GiCardRandom/>
                </div>
            </div>
            <div className = {styles.loadingText}>{text}</div>
        </div>
    </div>
  )
}

export default SavingData