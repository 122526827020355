import { useCallback, useEffect } from "react";


const useFormatTable = (header,body) => {

    const adjustHeader = useCallback(()=>{
        try{
            if (body && header){
                if (body?.scrollHeight > body?.clientHeight) {
                    const scrollbarWidth = body.offsetWidth - body.clientWidth;
                    header.style.paddingRight = `${scrollbarWidth}px`;
                }
                else{
                    header.style.paddingRight = '0px';
                }
            }
        }
        catch(err){
            console.log("Error - ",err)
        }
    },[body,header])

    useEffect(() => {
        adjustHeader()
        window.addEventListener('resize', adjustHeader);
        return () => {
            window.removeEventListener('resize', adjustHeader);
        };
    }, [adjustHeader,body,header]);

useEffect(() => {
  const observer = new MutationObserver(adjustHeader);
  if (body) {
    observer.observe(body, { childList: true, subtree: true });
  }
  return () => {
    observer.disconnect();
  };
}, [body,adjustHeader])

}

export default useFormatTable