import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from  "../css/Users.module.css"
import NavBar from './NavBar'
import { IoIosSearch } from "react-icons/io";
import { TiArrowSortedDown } from "react-icons/ti";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import spread_data from "../../tarotGame/data/spread.json"
import { icons } from '../../tarots/js/ViewTarots';
import { GiCardRandom } from "react-icons/gi";
import useFormatTable from './useFormatTable';
import { BiSortAlt2 } from 'react-icons/bi';
import axios from 'axios';
import { debounce } from 'lodash';


const spreads = {...spread_data['three_cards'],...spread_data['five_cards']}

const Tarots = () => {
    const [data,setData] = useState(null)
    const [searchValue,setSearchValue] = useState("")
    const [showSort,setShowSort] = useState(false)
    const [sortParams,setSortParams] = useState({field:"updated_at",order:"desc"})
    const [filtering,setFiltering] = useState(false)
    const [searching,setSearching] = useState(false)
    const searchTimer = useRef(null)
    const fetchParams = useRef({fetching:true,fetched:false})
    const [page,setPage] = useState(1)
    const [pageInput,setPageInput] = useState("1")
    const pageRef = useRef(null)
    const tableHeader = useRef(null)
    const tableBody = useRef(null)
    const _ = useFormatTable(tableHeader.current,tableBody.current)
    const cancelToken = useRef(null)

    const fetchUsers = useCallback(()=>{
        if (cancelToken.current) {
            cancelToken.current.cancel();
          }
        const source = axios.CancelToken.source();
        cancelToken.current = source;
        setFiltering(true)
        let url = `${process.env.REACT_APP_API_URL}/tarots/?search=${encodeURIComponent(searchValue)}&page=${page}`;
        url += `&sort_by=${sortParams.field}&sort_order=${sortParams.order}`
        axios.get(url,{cancelToken: source.token})
        .then((res)=>{
            setData(res.data)
            setPage(res.data.page)
            setFiltering(false)
        })
        .catch((err)=>{
            if (!axios.isCancel(err)) {
                setFiltering(false)
            }
            console.log("Error - ",err)
        })
    },[searchValue,sortParams,setFiltering,page])

    useEffect(()=>{
        if (fetchParams.current?.fetching){
            fetchParams.current.fetching = false
            axios.get(`${process.env.REACT_APP_API_URL}/tarots`)
            .then((res)=>{
                fetchParams.current.fetched = true
                setData(res.data)
                setPage(res.data.page)
            })
        }
        if (fetchParams.current?.fetched){
            const debouncedFetch = debounce(fetchUsers, 300)
            debouncedFetch()
        }
    },[fetchUsers])

    useEffect(() => {
        const input = pageRef.current;
        if (input) {
          input.style.width = `${input.value.length}ch`;
        }
      }, [page]);

      const handlePageChange = (e) => {
        let value = e.target.value
        if (value === '' || /^[0-9]*$/.test(value)) {
            setPageInput(value)
        }
    }

    const setCurrentPage = () => {
        if (pageInput){
            setPage(Number(pageInput))
        }
        else{
            setPageInput(page.toString())
        }
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13){
            setCurrentPage()
        }
    }

    const formatDate = useCallback((date)=>{
        if (date){
            date = new Date(date)
            const day = date.getDate().toString().padStart(2, '0');
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const month = monthNames[date.getMonth()];
            const year = date.getFullYear();
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${day} ${month} ${year} - ${hours}:${minutes}`;
        }
        else{
            return "N/A"
        }
    },[])

    const setTableBody = useCallback((el)=>{
        if (el){
            if (el?.previousElementSibling){
                const header = el.previousElementSibling
                if (el.scrollHeight > el.clientHeight) {
                    const scrollbarWidth = el.offsetWidth - el.clientWidth;
                    header.style.paddingRight = `${scrollbarWidth}px`;
                }
                else{
                    header.style.paddingRight = '0px';
                }
            }
            tableBody.current = el
        }
    },[])

    const handleSearchChange = (e) => {
        let value = e.target.value
        setSearchValue(value)
        if (searchTimer.current){
            clearTimeout(searchTimer.current)
            setSearching(true)
        }
        searchTimer.current = setTimeout(()=>{
            setSearching(false)
        },500)
    }

    const goPrev = () => {
        setPageInput((page-1).toString())
        setPage(page-1)
    }

    const goNext = () => {
        setPageInput((page+1).toString())
        setPage(page+1)
    }

  return (
    <div className = {`${styles.wrapper} ${styles.tarots}`}>
        <NavBar page = "tarots"/>
        <div className = {styles.box}>
            <header>
                <div className = {styles.mainHeader}>
                    <div className = {styles.tarotIcon}><GiCardRandom/></div>
                    <div className = {styles.title}>Tarot Seances</div>
                    <div className = {styles.lengthBox}>54</div>
                </div>
                <div className = {styles.playerBtn}>
                    Switch to player mode
                </div>
            </header>
            {!data ? <SkeletonPage/> : <main>
                <div className ={`${styles.searchWrapper} ${styles.noTopPadding}`}>
                    <div className = {styles.searchBox}>
                        <div className = {styles.searchIcon}><IoIosSearch/></div>
                        <input type = "text" name = "searchValue" value = {searchValue} onChange = {handleSearchChange} placeholder = "Search tarots by id or player's email"/>
                    </div>
                    <div className = {styles.sortBtnWrapper} tabIndex = {-1} onBlur = {()=>setShowSort(false)}>
                        <div className = {styles.sortBtn} onClick = {()=>setShowSort(!showSort)}>
                            <div className = {styles.text}>Sort by</div>
                            <div className = {styles.arrow}><TiArrowSortedDown/></div>
                            <div className = {styles.sortIcon}><BiSortAlt2/></div>
                        </div>
                        {showSort && <div className = {styles.sortMenu}>
                            <div className = {styles.sortItem} onClick={()=>setSortParams({field:"created_at",order:"desc"})}>Date Created <span>- newest to oldest</span></div>
                            <div className = {styles.sortItem} onClick={()=>setSortParams({field:"created_at",order:"asc"})}>Date Created <span>- oldest to newest</span></div>
                            <div className = {styles.sortItem} onClick={()=>setSortParams({field:"updated_at",order:"desc"})}>Date Modified <span>- newest to oldest</span></div>
                            <div className = {styles.sortItem} onClick={()=>setSortParams({field:"updated_at",order:"asc"})}>Date Modified <span>- oldest to newest</span></div>
                        </div>}
                    </div>
                </div>
                <div className = {styles.content}>
                    {(searching || filtering) ? <SkeletonTable/> : <div className = {styles.table}>
                        <div className = {styles.tableContent}>
                            <div className = {styles.tableHeader}>
                                <div className = {`${styles.headerCol} ${styles.game_id}`}>id</div>
                                <div className = {`${styles.headerCol} ${styles.player}`}>player</div>
                                <div className = {`${styles.headerCol} ${styles.spread}`}>spread</div>
                                <div className = {`${styles.headerCol} ${styles.created_at}`}>played at</div>
                                <div className = {`${styles.headerCol} ${styles.updated_at}`}>Recently modified</div>
                                <div className = {`${styles.headerCol} ${styles.num_questions}`}>N&deg; follow-up questions</div>
                                <div className = {`${styles.headerCol} ${styles.diamonds_spent}`}>N&deg; Diamonds Spent</div>
                            </div>
                            <div className = {styles.tableBody} ref = {setTableBody}>
                                {data.items.map((game, index) => (
                                    <div className = {styles.row} key={index}>
                                        <div data-label = "Id" className = {`${styles.bodyCol} ${styles.game_id}`}>{game.id}</div>
                                        <div data-label = "Player" className = {`${styles.bodyCol} ${styles.player}`}>{game.player}</div>
                                        <div data-label = "Spread" className = {`${styles.bodyCol} ${styles.spread}`}>
                                            <div className = {styles.spreadBox}>
                                                <div className = {styles.spreadIcon}>{icons[game.spread]}</div>
                                                <div className = {styles.spreadName}>{spreads[game.spread]?.name}</div>
                                            </div>
                                        </div>
                                        <div data-label = "Played at" className = {`${styles.bodyCol} ${styles.created_at}`}>{formatDate(game.created_at)}</div>
                                        <div data-label = "Recently modified" className = {`${styles.bodyCol} ${styles.updated_at}`}>{formatDate(game.updated_at)}</div>
                                        <div data-label = "N&deg; follow-ups" className = {`${styles.bodyCol} ${styles.num_questions}`}>{game.num_questions}</div>
                                        <div data-label = "N&deg; Diamonds Spent" className = {`${styles.bodyCol} ${styles.diamonds_spent}`}>{game.diamonds_spent}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className = {styles.tableFooter}>
                            <div className = {styles.displayedLength}>Showing {data.items.length} out of {data.total}</div>
                            <div className = {styles.pager}>
                                {page !== 1 && <div className = {`${styles.pageArrow} ${styles.prev}`} onClick = {()=>goPrev()}><MdKeyboardArrowLeft/></div>}
                                <div className = {styles.pagerBox}>
                                    <input ref = {pageRef} type = "text" name = "pageInput" value = {pageInput} onChange = {handlePageChange} onBlur = {()=>setCurrentPage()} onKeyDown = {handleKeyDown}/>
                                    / {data.pages}
                                </div>
                                {page !== data.pages && <div className = {`${styles.pageArrow} ${styles.next}`} onClick = {()=>goNext()}><MdKeyboardArrowRight/></div>}
                            </div> 
                        </div>
                    </div>}
                </div>
            </main>}
       </div>
    </div>
  )
}

const SkeletonTable = () => {
    return (
    <div className = {`${styles.content} ${styles.skeleton}`}>
        <div className = {styles.table}>
            <div className = {styles.tableContent}>
                <div className = {styles.tableHeader}>
                    <div className = {`${styles.headerCol} ${styles.game_id}`}>id</div>
                    <div className = {`${styles.headerCol} ${styles.player}`}>player</div>
                    <div className = {`${styles.headerCol} ${styles.spread}`}>spread</div>
                    <div className = {`${styles.headerCol} ${styles.created_at}`}>played at</div>
                    <div className = {`${styles.headerCol} ${styles.updated_at}`}>Recently modified</div>
                    <div className = {`${styles.headerCol} ${styles.num_questions}`}>N&deg; follow-up questions</div>
                    <div className = {`${styles.headerCol} ${styles.diamonds_spent}`}>N&deg; Diamonds Spent</div>
                </div>
                <div className = {styles.tableBody}>
                    {[...Array(7).keys()].map((_, index) => (
                        <div className = {styles.row} key={index}>
                            <div data-label = "Id" className = {`${styles.bodyCol} ${styles.game_id}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "Player" className = {`${styles.bodyCol} ${styles.player}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "Spread" className = {`${styles.bodyCol} ${styles.spread}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "Played at" className = {`${styles.bodyCol} ${styles.created_at}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "Recently modified" className = {`${styles.bodyCol} ${styles.updated_at}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "N&deg; follow-ups" className = {`${styles.bodyCol} ${styles.num_questions}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "N&deg; Diamonds Spent" className = {`${styles.bodyCol} ${styles.diamonds_spent}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className = {styles.tableFooter}>
                <div className = {styles.skeletonLength}></div>
            </div>
        </div>
    </div>
    )
}

const SkeletonPage = () => {
    return(
        <main className = {styles.skeleton}>
            <div className ={`${styles.searchWrapper} ${styles.noTopPadding}`}>
                <div className = {styles.searchBox}></div>
                <div className = {styles.btns}>
                    <div className = {styles.skeletonSortBtn}></div>
                </div>
            </div>
            <SkeletonTable/>
        </main>
    )
}

export default Tarots