import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from "../css/Profile.module.css"
import { IoIosArrowDown } from "react-icons/io";
import { BsCheckLg } from "react-icons/bs";
import api from '../../../utils/api';
import { updateUserData } from '../../../utils/auth';

const Languages = ['English','Deutsch','Español','Français','Italiano','Nederlands','Polski','Português']
const Display = () => {
    const [language,setLanguage] = useState("")
    const [weekStart,setWeekStart] = useState("")
    const [showDropDown,setShowDropDown] = useState(false)
    const menu = useRef(null)
    const fetching = useRef(true)

    useEffect(()=>{
        if (fetching.current){
            fetching.current = false
            api.get('/settings/get-details')
            .then((res)=>{
                setLanguage(res.data.language)
                setWeekStart(res.data.weekstart)
            })
            .catch((err)=>{
                console.log("Error = ",err)
            })
        }
        const handleClick = (e) => {
            if (showDropDown && !menu.current?.contains(e.target)){
                setShowDropDown(false)
            }
        }
        window.addEventListener("mousedown",handleClick)
        return () => {
            window.removeEventListener("mousedown",handleClick)
        }
    },[showDropDown])

    const setMaxHeight = useCallback((el)=>{
        if (el){
            if ((el.previousElementSibling.getBoundingClientRect().top * 0.8) > (window.innerHeight - el.previousElementSibling.getBoundingClientRect().bottom)){
                el.classList.add(styles.top)
                el.style.maxHeight = `${el.previousElementSibling.getBoundingClientRect().top - 25 > 280 ? 280 : el.previousElementSibling.getBoundingClientRect().top - 25}px`
                return
            }
            el.style.maxHeight = `${window.innerHeight - el.previousElementSibling.getBoundingClientRect().bottom - 25}px`
        }
    },[])

    const selectLanguage = (lang) => {
        setLanguage(lang)
        setShowDropDown(false)
        api.put('/settings/edit',{language:lang})
        .then((res)=>{
            updateUserData({language:res.data.language})
        })
        .catch((err)=>{
            console.log("Error = ",err)
        })
    }

    const selectWeekStart = (value) => {
        let newWeekStart = ""
        if (value === "sunday"){
            newWeekStart = weekStart === "sunday" ? "monday" : "sunday"
        }
        else{
            newWeekStart = weekStart === "monday" ? "sunday" : "monday"
        }
        setWeekStart(newWeekStart)
        setShowDropDown(false)
        api.put('/settings/edit',{weekstart: newWeekStart})
        .then((res)=>{
            updateUserData({weekstart:res.data.weekstart})
        })
        .catch((err)=>{
            console.log("Error = ",err)
        })
        
    }

  return (
    (language && weekStart) ? <div className = {styles.box}>
        <div className = {`${styles.section} ${styles.display}`}>
            <div className = {styles.label}>Language</div>
            <div className = {styles.selectorWrapper} ref = {menu}>
                <div className = {styles.selectorInput} onClick = {()=>setShowDropDown(!showDropDown)}>
                    <div className = {styles.value}>{language}</div>
                    <div className = {styles.arrow}><IoIosArrowDown/></div>
                </div>
                {showDropDown && <div className = {styles.dropDown} ref = {setMaxHeight}>
                    {Languages.map((lang,index)=>(
                        <div className = {styles.languageBox} key = {index} onClick = {()=>selectLanguage(lang)}>
                            <div className = {styles.languageCheck}>{lang === language && <BsCheckLg/>}</div>
                            <div className = {styles.languageText}>{lang}</div>
                        </div>
                    ))}
                </div>}
            </div>
        </div>
        <div className = {`${styles.section} ${styles.display}`}>
            <div className = {styles.label}>Start of the week</div>
            <div className = {styles.items}>
                <div className = {styles.checkItem}>
                    <div className = {`${styles.checkSquare} ${weekStart === "sunday" && styles.checked}`} onClick = {()=>selectWeekStart("sunday")}>{weekStart === "sunday" && <BsCheckLg/>}</div>
                    <div className = {styles.checkText}>Sunday</div>
                </div>
                <div className = {styles.checkItem}>
                    <div className = {`${styles.checkSquare} ${weekStart === "monday" && styles.checked}`} onClick = {()=>selectWeekStart("monday")}>{weekStart === "monday" && <BsCheckLg/>}</div>
                    <div className = {styles.checkText}>Monday</div>
                </div>
            </div>
        </div>
    </div> : <div className = {styles.fetchingLoader}><div className = {styles.spinner}></div></div>
  )
}

export default Display