import React from 'react'
import styles from "../css/LoadingDream.module.css"
import { Decorations } from './Game'

const LoadingDream = () => {
  return (
    <div className = {styles.box}>
        <div className = {styles.bookArea}>
            <div className = {styles.leftPage}>
                <div className = {styles.dreamContent}>
                    <div className = {styles.dreamDate}></div>
                    <div className = {styles.imageBox}></div>
                    <div className = {styles.dreamTitle}></div>
                    <div className = {styles.dreamContent}>
                        <div className = {styles.dreamText}></div>
                        <div className = {styles.dreamText}></div>
                        <div className = {styles.dreamText}></div>
                        <div className = {styles.dreamText}></div>
                        <div className = {styles.dreamText}></div>
                        <div className = {styles.dreamText}></div>
                    </div>
                </div>
            </div>
            <div className = {styles.rightPage}>
                <div className = {styles.chatContent}>
                    <div className = {styles.chatTitle}></div>
                    <div className = {styles.seperator}></div>
                    <div className = {styles.dialog}>
                        <div className = {styles.outputWrapper}>
                            <div className = {styles.output}></div>
                        </div>
                        <div className = {styles.inputWrapper}>
                            <div className = {styles.input}></div>
                        </div>
                        <div className = {styles.outputWrapper}>
                            <div className = {styles.output}></div>
                        </div>
                        <div className = {styles.inputWrapper}>
                            <div className = {styles.input}></div>
                        </div>
                    </div>
                    <div className = {styles.chatFooter}>
                        <div className = {styles.seperator}></div>
                        <div className = {styles.chatInput}></div>
                    </div>
                </div>
            </div>
        </div>
        <Decorations expanded = {true}/>
    </div>
  )
}

export default LoadingDream