import React from 'react'
import styles from "../css/NotFound.module.css"
import { IoIosCloseCircle } from 'react-icons/io'
import DreamImage from "../media/dreamNotFound.png"

const NotFoundDream = ({close}) => {
  return (
    <div className = {styles.wrapper}>
        <div className = {styles.box}>
            <div className = {styles.closeIcon} onClick = {()=>close()}><IoIosCloseCircle/></div>
            <div className = {styles.imageBox}>
                <img src = {DreamImage} alt = ""/>
            </div>
            <div className = {styles.info}>
                <div className = {styles.title}>Ooops! Dream Not Found</div>
                <div className = {styles.msg}>It looks like the dream you tried to access does not exist anymore.</div>
            </div>
        </div>
    </div>
  )
}

export default NotFoundDream