import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/Readings.module.css"
import { LuSearch } from "react-icons/lu";
import { FaFilter } from "react-icons/fa6";
import { TbSortAscending } from "react-icons/tb";
import { FiPlusCircle } from "react-icons/fi";
import SortMenu from './SortMenu';
import FilterMenu from './FilterMenu';
import DeleteDream from './DeleteDream';
import ViewDream from './ViewDream';
import DreamBox from './DreamBox';
import axios from 'axios';
import api from '../../../utils/api';
import LoadingReadings, { LoadingDream } from './LoadingReadings';
import Game from '../../dreamGame/js/Game';
import CrashPage from '../../errors/js/CrashPage';
import { IoSearch } from 'react-icons/io5';
import { BsMoonStarsFill } from 'react-icons/bs';
import CloudsImage from "../media/cloudsImage.png"
import { ToastContainer } from 'react-toastify';

const Readings = () => {
    const [showFilterMenu,setShowFilterMenu] = useState(false)
    const [showSortMenu,setShowSortMenu] = useState(false)
    const [filterParams,setFilterParams] = useState({dream_date:null,created_at:null})
    const [sortParams,setSortParams] = useState({field:"updated_at",order:"desc"})
    const [showPopUps,setShowPopUps] = useState({new_dream:null,edit:null,delete:null,view:null,game:null})
    const [searchValue,setSearchValue] = useState("")
    const [dreams,setDreams] = useState(null)
    const fetching = useRef(true)
    const fetched = useRef(false)
    const [searching,setSearching] = useState(false)
    const [filtering,setFiltering] = useState(false)
    const [crashError,setCrashError] = useState("")
    const searchTimer = useRef(null)
    const cancelToken = useRef(null)

    const fetchData = useCallback(()=>{
        if (cancelToken.current) {
            cancelToken.current.cancel('Request canceled due to new search');
          }
        const source = axios.CancelToken.source();
        cancelToken.current = source;
        setFiltering(true)
        let url = `/dreams/userView/?search=${encodeURIComponent(searchValue)}`;
  
        if (filterParams.dream_date !== null) {
          let month = (filterParams.dream_date.getMonth() + 1).toString().padStart(2,'0')
          let day = (filterParams.dream_date.getDate()).toString().padStart(2,'0')
          let dreamDate = filterParams.dream_date.getFullYear() + '-' + month + '-' + day
          url += `&dreamt_at=${dreamDate}`
        }

        if (filterParams.created_at !== null) {
          url += `&created_at=${filterParams.created_at}`
        }

        url += `&sort_by=${sortParams.field}&sort_order=${sortParams.order}`

        api.get(url,{cancelToken: source.token})
        .then((res)=>{
            setDreams(res.data)
            setFiltering(false)
            
        })
        .catch((err)=>{
            if (!axios.isCancel(err)) {
                setCrashError("Something unexpected occured while trying to retrieve your tarot readings. Please, try again later")
            }
        })
    },[searchValue,filterParams,sortParams])

    useEffect(()=>{
        if (fetching.current){
            fetching.current = false
            api.get('/dreams/userView/')
            .then((res)=>{
                setDreams(res.data)
                fetched.current = true
            })
            .catch((_)=>{
                setCrashError("Something unexpected occured while trying to retrieve your tarot readings. Please, try again later")
            })
        }

        if (fetched.current){
            fetchData()
        }
     },[fetchData])
  
  
    const [elementsPerRow, setElementsPerRow] = useState(3);
  
    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width > 1320){
              setElementsPerRow(4)
            }
            else if (width <= 1320 & width > 1000) { 
              setElementsPerRow(3);
            }
            else if (width > 640) {
              setElementsPerRow(2)
            }
            else { 
                setElementsPerRow(1);
            }
        };
  
        window.addEventListener('resize', handleResize);
        handleResize();
          return () => {
            window.removeEventListener('resize', handleResize);
        };
      }, []);
  
      const rows = useMemo(()=>{
          if (dreams){
              return dreams.reduce((rowsArray, item, index) => {
                  if (index % elementsPerRow === 0) {
                      rowsArray.push([]);
                  }
                  rowsArray[rowsArray.length - 1].push(item);
                  return rowsArray;
              }, []);
          }
          else{
              return []
          }
  
      },[dreams,elementsPerRow])
  
      const deleteDream = () => {
          setDreams((prevDreams)=>{
              let copy = [...prevDreams]
              copy = copy.filter(item => item.id !== showPopUps.delete)
              return copy
          })
          setShowPopUps({...showPopUps,delete:null})
      }

    const handleSearchChange = (e) => {
        setSearching(true)
        if (searchTimer.current){
            clearTimeout(searchTimer.current)
        }
        const value = e.target.value
        setSearchValue(value)

        searchTimer.current = setTimeout(()=>{
            setSearching(false)
        },200)
    }

    const activeFilter = useMemo(()=>{
        return filterParams.dream_date || filterParams.created_at
    },[filterParams])

    const activeSort = useMemo(()=>{
        return !(sortParams.field ==="updated_at" && sortParams.order === "desc")
    },[sortParams])
  
  return (
    crashError ? <div className = {styles.wrapper}>
    <div className = {styles.box}>
        <div className = {styles.header}>
            <div className = {styles.title}>Tarot Readings</div>
            <div className = {styles.profileArea}></div>
        </div>
        <CrashPage msg = {crashError}/>
    </div>
    </div> :
    showPopUps.new_dream ? <Game closeGame = {()=>setShowPopUps({...showPopUps,new_dream:null})}/> : 
    showPopUps.resume ?  <Game closeGame = {()=>setShowPopUps({...showPopUps,resume:null})} game_id={showPopUps.resume}/> : dreams ? (showPopUps.game !== null ? <Game game_id = {showPopUps.game.id} title = {showPopUps.game.title} closeGame = {()=>setShowPopUps({...showPopUps,game:null})} isResuming = {showPopUps.game.resume} gameData = {showPopUps.game.data}/> :
    <div className = {styles.wrapper}>
        <div className = {styles.box}>
            <div className = {styles.header}>
                <div className = {styles.title}>Dream Interperation</div>
                <div className = {styles.searchBox}>
                    <div className = {styles.searchIcon}><LuSearch/></div>
                    <input type = "text" placeholder = "Search..." value = {searchValue} onChange = {handleSearchChange}/>
                </div>
                <div className = {styles.profileArea}></div>
            </div>
            <div className = {styles.subHeader}>
                <div className = {styles.lengthBox}>
                    <div className = {styles.lengthText}>My Readings</div>
                    <div className = {styles.length}>{dreams?.length}</div>
                </div>
                <div className = {styles.btns}>
                    <div className = {styles.sortWrapper}>
                        <div className = {`${styles.sortBtn} ${showSortMenu  && styles.active} ${activeSort && styles.selected}`} onClick={()=>setShowSortMenu(!showSortMenu)}>
                            <div className = {styles.sortIcon}><TbSortAscending/></div>
                            <div className = {styles.sortText}>Sort</div>
                        </div>
                        {showSortMenu && <SortMenu sortValue={sortParams} onChangeSortValue = {(value)=>setSortParams(value)} close = {()=>setShowSortMenu(false)}/>}
                    </div>
                    <div className = {styles.filterWrapper}>
                        <div className = {`${styles.filterBtn} ${showFilterMenu  && styles.active} ${activeFilter && styles.selected}`} onClick={()=>setShowFilterMenu(!showFilterMenu)}>
                            <div className = {styles.filterIcon}><FaFilter/></div>
                            <div className = {styles.filterText}>Filter</div>
                        </div>
                        {showFilterMenu && <FilterMenu filterParams={filterParams} onChangeFilterParams = {(value)=>setFilterParams(value)} close = {()=>setShowFilterMenu(false)}/>}
                    </div>
                    <div className = {styles.addBtn} onClick = {()=>setShowPopUps({...showPopUps,new_dream:true})}>
                        <div className = {styles.plusIcon}><FiPlusCircle/></div>
                        <div className = {styles.addText}>New Dream</div>
                    </div>
                </div>
            </div>
            <div className = {`${styles.searchWrapper} ${styles.min}`}>
                <div className = {`${styles.searchBox} ${styles.min}`}>
                    <div className = {styles.searchIcon}><LuSearch/></div>
                    <input type = "text" placeholder = "Search..."/>
                </div>
            </div>
            {dreams.length > 0? <div className = {styles.grid} id = "dreams-grid">
                {(!searching && !filtering) ? <div className = {styles.dreams}>
                    {rows.map((row,rowIndex)=>(
                        <div className = {styles.row} key = {rowIndex}>
                            {row.map((data,index)=>(
                                <div className = {styles.dreamBox} key = {index}>
                                    <DreamBox dream_data = {data} handleMenu = {(key)=>setShowPopUps({...showPopUps,[key]:data.id})} index = {index} refreshPage = {()=>fetchData()}/>
                            </div>
                            ))}
                        </div>
                    ))}</div> : <div className  = {styles.dreams}>
                        {[...Array(2).keys()].map((_,rowIndex)=>(
                                <div className = {styles.row} key = {rowIndex}>
                                {[...Array(elementsPerRow).keys()].map((_,index)=>(
                                    <div className = {styles.dreamBox} key = {index}>
                                        <LoadingDream/>
                                </div>
                                ))}
                            </div>
                        ))}
                    </div>}
            </div> : ((!activeFilter || searchValue.trim()) ? <div className = {styles.emptyContent}>
                <div className = {styles.dreamImage}>
                    <img src = {CloudsImage} alt = ""/>
                </div>
                <div className = {`${styles.emptyTitle} ${styles.minMargin}`}>No<span> Dream</span> Readings Yet</div>
                <div className = {styles.emptyMsg}>Click on the add button to get started</div>
            </div> : <div className = {styles.emptyContent}>
                <div className = {styles.dreamsIcon}>
                    <div className = {styles.dreamIcon}><BsMoonStarsFill/></div>
                    <div className = {styles.search}><IoSearch/></div>
                </div>
            <div className = {styles.emptyTitle}>No<span> Dream</span> Readings Found</div>
            <div className = {styles.emptyMsg}>Try adjusting your search keyword or filters</div>
        </div>)}
        </div>
        {showPopUps.delete !== null && <DeleteDream id = {showPopUps.delete} handleDelete = {deleteDream} close = {()=>setShowPopUps({...showPopUps,delete:null})}/>}
        {showPopUps.view !== null && <ViewDream id = {showPopUps.view} handleMenu = {(key)=>setShowPopUps({...showPopUps,[key]:showPopUps.view,view:null})} close = {()=>setShowPopUps({...showPopUps,view:null})}/>}
        <ToastContainer/>
    </div>) : <LoadingReadings/>
  )
}

export default Readings