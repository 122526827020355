import React, { useState } from 'react'
import styles from "../css/Settings.module.css"
import Menu from "./Menu"
import Profile from './Profile'
import { IoClose } from "react-icons/io5";
import Security from './Security';
import Display from './Display';

const menuItems = {
    profile: "Edit Profile",
    security: "Account Security",
    appearance: "Appearance",
    subscription: "Manage Subscriptions",
    billing: "Update Billing Details",
}

const Settings = ({close}) => {
    const [modal,setModal] = useState("profile")
  return (
    <div className = {styles.wrapper}>
        <div className = {styles.box}>
            <Menu modal = {modal} onHandleMenu = {(key)=>setModal(key)}/>
            <div className = {styles.content}>
                <div className = {styles.header}>
                    <div className = {styles.title}>{menuItems[modal]}</div>
                    <div className = {styles.closeIcon} onClick = {()=>close()}><IoClose/></div>
                </div>
                {modal === "profile" && <Profile/>}
                {modal === "security" && <Security close = {close}/>}
                {modal === "appearance" && <Display/>}
            </div>
        </div>
    </div>
  )
}

export default Settings