import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/Step5.module.css"
import SelectedSpread from "./SelectedSpread"
import SelectedIntention from "./SelectedIntention"
import tarotCards from "../data/cardInfo.json"
import Images from "../data/images.json"
import CardResults from './CardResults'

const Step5 = ({selection_type = "automatic",spread_length ,spread,intention,goNext,setCards}) => {
    const cards = Object.values(tarotCards).sort(() => Math.random() - 0.5)
    const boxRef = useRef(null)
    const fannedoutCards = useRef(null)
    const stackCards = useRef(null)
    const [hideStack,setHideStack] = useState(false)
    const cardInputs = useRef([])
    const animating = useRef(false)
    const [selectedCards,setSelectedCards] = useState(Array.from({ length: spread_length } , () => ({ name: "", id: null, is_reversed: false,processed:false})))
    const [preSelected,setPreSelected] = useState([])
    const [isComplete,setIsComplete] = useState(false)
    const selecting = useRef(false)

    const transformRef = useCallback((el,index)=>{
        if (el){
            let i = index + 1
            const staggerStep = 0.2;
            let xOffset = 0.25 * i;
            let yOffset = -0.03 * i;
            let zOffset = -i; 
            el.style.transform = `rotate(-15deg) rotateZ(-10deg) rotateX(-15deg) translateX(${xOffset}px) translateY(${yOffset}px) translateZ(${zOffset}px)`;
            // let zTranslation = `${78 - (index + 1)}px`
            // el.style.transform = `rotateZ(-20deg) rotateX(-20deg) translateZ(${zTranslation})`
        }
    },[])

    useEffect(()=>{
        if (!animating.current && !hideStack){
            console.log("here...")
            animating.current = true
            if (boxRef.current && stackCards.current){
                var start;
                var end;
                for (let i = 0; i < stackCards.current.children.length; i++){
                    start = performance.now()
                    console.log("Start = ",start,stackCards.current,i)
                    setTimeout(()=>{
                        let child = stackCards.current.children[i]
                        child.style.position = "fixed"
                        child.style.width = `${8 * boxRef.current.offsetWidth / 100}px`
                        child.style.height =`${13 * window.innerHeight / 100}px`
                        child.style.top = `28vh`
                        child.style.left = "-480%"
                        const totalCards = 78;
                        //Code
                        const fanSpread = 18;
                        const spacing = boxRef.current.offsetWidth / 85
                        let rotation = ((fanSpread / (totalCards - 1)) * (i+1)) - (fanSpread / 2);
                        let translateX = spacing * ((i+1) - (totalCards - 1) / 2);
                        child.style.transform = `rotateZ(${rotation}deg) translateX(${translateX}px)`
                        if (i === 77){
                            console.log(performance.now())
                        }
                    },(i+1)*70)
                }
                setTimeout(()=>{
                    console.log("hiding stack")
                    setHideStack(true)
                    if (selection_type === "automatic"){
                        selectedCards.forEach((_,i)=>{
                            setTimeout(()=>{
                                let selected_index = Math.floor(Math.random() * cards.length)
                                while (selectedCards.map(card => card.id).includes(cards[selected_index].id)){
                                    selected_index = Math.floor(Math.random() * cards.length)
                                }
                                let is_reversed = Math.random() < 0.5 ? false : true
                                setSelectedCards((prevState)=>{
                                    let copy = [...prevState]
                                    copy[i].id = cards[selected_index].id
                                    copy[i].name = cards[selected_index].name
                                    copy[i].is_reversed = is_reversed
                                    return copy
                                })
                                let selectedDiv = fannedoutCards.current.children[selected_index]
                                if (selectedDiv){
                                    selectedDiv.classList.add(styles.rising)
                                }     
                                setTimeout(()=>{
                                    selectedDiv.classList.add(styles.moving)
                                    selectedDiv.style.width = `${cardInputs.current[i].offsetWidth}px`
                                    selectedDiv.style.height = `${cardInputs.current[i].offsetHeight}px`
                                    selectedDiv.style.top = `${cardInputs.current[i].getBoundingClientRect().top}px`
                                    selectedDiv.style.left = `${cardInputs.current[i].getBoundingClientRect().left}px`
                    
                                    setSelectedCards((prevState)=>{
                                        let copy = [...prevState]
                                        copy[i].processed = true
                                        return copy
                                    })
                    
                                    setTimeout(()=>{
                                        selectedDiv.style.display = "none"
                                        selectedDiv.classList.remove(styles.moving)
                                        selectedDiv.classList.remove(styles.rising)
                    
                                        setTimeout(()=>{
                                            cardInputs.current[i].classList.add(styles.flipped)
                                            if ((i === 4 && spread_length === 5) || (i === 2 && spread_length === 3)){
                                                setTimeout(()=>{
                                                    setIsComplete(true)
                                             },800)
                                            }
                                        },100)
                                    },500)
                                },600)
                            },i * 1100)
                        })
                    }
                },7000)
            }
    
        }
    },[])

    const selectCard = (card,i) => {
        // console.log("clicking...")
        if (!selectedCards.map(item => item.id).includes(card.id)){
            let active_index = selectedCards.findIndex(item => !item.id)
            let is_reversed = Math.random() < 0.5 ? false : true
            setSelectedCards((prevState)=>{
                let copy = [...prevState]
                copy[active_index].id = card.id
                copy[active_index].name = card.name
                copy[active_index].is_reversed = is_reversed
                return copy
            })
            let selectedDiv = fannedoutCards.current.children[i]
            if (selectedDiv){
                selectedDiv.classList.add(styles.rising)
            }
            setTimeout(()=>{
                selectedDiv.classList.add(styles.moving)
                selectedDiv.style.width = `${cardInputs.current[active_index].offsetWidth}px`
                selectedDiv.style.height = `${cardInputs.current[active_index].offsetHeight}px`
                selectedDiv.style.top = `${cardInputs.current[active_index].getBoundingClientRect().top}px`
                selectedDiv.style.left = `${cardInputs.current[active_index].getBoundingClientRect().left}px`

                setSelectedCards((prevState)=>{
                    let copy = [...prevState]
                    copy[active_index].processed = true
                    return copy
                })

                setTimeout(()=>{
                    selectedDiv.style.display = "none"
                    selectedDiv.classList.remove(styles.moving)
                    selectedDiv.classList.remove(styles.rising)

                    setTimeout(()=>{
                        cardInputs.current[active_index].classList.add(styles.flipped)
                    },100)
                },500)
                if ((active_index === 4 && spread_length === 5) || (active_index === 2 && spread_length === 3)){
                    setTimeout(()=>{
                        setIsComplete(true)
                    },800)
                }
            },600)
        }
    }

    useEffect(()=>{
        if (isComplete){
            setCards(selectedCards)
            goNext()
        }
    },[isComplete])

    // const fanOutSpread = useCallback((el,index)=>{
    //     let i = index + 1
    //     const totalCards = 78;
    //     const fanSpread = 18;
    //     // const spacing = 1 * boxRef.current.offsetWidth; // Total angle spread of the fan in degrees
    //     if (el && boxRef.current && !selecting.current){
    //         console.log("changing fanning out position = ",el,index,selecting.current)
    //         const spacing = boxRef.current.offsetWidth / 85
    //       // Calculate the rotation for this card within the spread
    //       let rotation = ((fanSpread / (totalCards - 1)) * i) - (fanSpread / 2);
    //       let translateX = spacing * (i - (totalCards - 1) / 2);
    //       console.log("changing some instances")
          
    //       // Apply the transform style to the card
    //       el.style.transform = `rotateZ(${rotation}deg) translateX(${translateX}px)`;
    //     }
      
    // },[])

    const handleMouseLeave = (event,i) => {
        // if (event?.target && !selecting.current){
        //     if (selecting.current){
        //         console.log("here...")
        //     }
        //     let el = event.target
        //     const spacing = boxRef.current.offsetWidth / 85
        //     const totalCards = 78
        //     const fanSpread = 18;
        //     let translateX = spacing * ((i+1) - (totalCards - 1) / 2)
        //     let rotation = ((fanSpread / (totalCards - 1)) * (i+1)) - (fanSpread / 2);
        //     el.style.transform = `rotateZ(${rotation}deg) translateX(${translateX}px)`;
        // }
        if (i === 77){
            console.log("leaving",selecting.current)
        }
    }

    const handleMouseEnter = (event,i) => {
        // if (event?.target && !selecting.current){
        //     if (selecting.current){
        //         console.log("there...")
        //     }
        //     let el = event.target
        //     const spacing = boxRef.current.offsetWidth / 85
        //     const totalCards = 78
        //     let translateX = spacing * (i - (totalCards - 1) / 2)
        //     console.log("Element = ",el)
        //     el.style.transform = `rotateZ(${0}deg) translateX(${translateX}px) translateY(-10%)`;
        // } 
        if (i === 77){
            console.log("entering",selecting.current)
        }
    }

    // const stackCards = useCallback((el)=>{
    //     console.log(el,boxRef.current,"fanning")
    //     if (el && boxRef.current){
    //         for (let i = 0; i < el.children.length; i++){
    //             setTimeout(()=>{
    //                 let child = el.children[i]
    //                 child.style.position = "fixed"
    //                 child.style.width = `${8.4 * boxRef.current.offsetWidth / 100}`
    //                 child.style.height =`${ 100 *(el* 8.4 / 100) / 140}`
    //                 child.style.top = `28vh`
    //                 child.style.left = "-480%"
    //                 const totalCards = 78;
    //                 //Code
    //                 const fanSpread = 18;
    //                 const spacing = boxRef.current.offsetWidth / 85
    //                 let rotation = ((fanSpread / (totalCards - 1)) * (i+1)) - (fanSpread / 2);
    //                 let translateX = spacing * ((i+1) - (totalCards - 1) / 2);
    //                 child.style.transform = `rotateZ(${rotation}deg) translateX(${translateX}px)`;
    //             },200)
    //         }
    //     }
    // },[])

    const setCardInputs = useCallback((el,index)=>{
        if (el){
            cardInputs.current[index] = el
        }
    },[])

    // const isComplete = useMemo(()=>{
    //     return selectedCards.every(card => card.processed)
    // },[selectedCards])

  return (
    <div className = {styles.wrapper}>
        <div className = {styles.box} ref = {boxRef}>
            <div className = {styles.gameTitle}>Tarots</div>
            <div className = {styles.menuBtn}>Menu</div>
            <div className = {styles.spread}><SelectedSpread spread = {spread}/></div>
            <div className = {styles.intention}><SelectedIntention intention= {intention}/></div>
            {/* {isComplete && <div className = {styles.results}><CardResults cards = {selectedCards}/></div>} */}
            {!isComplete && <div className = {styles.selectedCards} length = {spread_length.toString()}>
                {selectedCards.map((card,index)=>(
                    <div className = {styles.selectedCardContainer} key = {index} ref = {(el)=>setCardInputs(el,index)}>
                        <div className = {styles.selectedCard}>
                            <div className = {styles.front}></div>
                            <div className = { `${styles.back} ${card.is_reversed && styles.reversed}`}>
                                {card.processed && <img src = {Images[card.id].src} alt = ""/>}
                            </div>
                        </div>
                    </div>
                ))}
            </div>}
            {(!hideStack && !isComplete)&& <div className = {styles.cardStackWrapper}>
                <div className = {styles.cardStack} ref = {stackCards}>
                    {cards.map((_,index)=>(
                        <div className = {styles.cardStackItem} style={{ '--num': (index+1).toString() }} key = {index} ref = {(el)=>transformRef(el,index)}></div>
                    ))}
                </div>
            </div>}
            {(hideStack && !isComplete) && <div className = {styles.cardsWrapper}>
                <div className = {styles.cards} ref = {fannedoutCards}>
                    {cards.map((card,index)=>(
                        <div className = {styles.card} style = {{ '--num': (index).toString(), '--rotation': `${((18/ (78 - 1)) * (index+1)) - (18 / 2)}deg`, '--translateX': `${1.1 * window.innerHeight / 85 * ((index+1) - (78 - 1) / 2)}px`, '--hoveredTranslate': `${1.1 * window.innerHeight / 85 * ((index+1) - (78 - 1) / 2)}px` }} key = {index} onMouseEnter = {(e)=>handleMouseEnter(e,index)} onMouseLeave = {(e)=>handleMouseLeave(e,index)} onClick = {()=>selectCard(card,index)}></div>
                        // <div className = {styles.card} style = {{ '--num': (index).toString() }} key = {index} ref = {(e)=>fanOutSpread(e,index)}  onMouseEnter = {(e)=>handleMouseEnter(e,index)} onMouseLeave = {(e)=>handleMouseLeave(e,index)} onClick = {()=>selectCard(card,index)}></div>
                    ))}
                </div>
            </div>}
        </div>
    </div>
  )
}

export default Step5