import React from 'react'
import PageComponent from '../components/pageLayout/js/PageComponent'
import Readings from '../components/dreamInterpertation/js/Readings'

const DreamPage = () => {
  return (
    <PageComponent element = {<Readings/>} page = "dreams"/>
  )
}

export default DreamPage