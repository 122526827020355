import React from 'react'
import styles from "./LandingPage.module.css"
import Logo from "./logo.png"
import { HiOutlineMail } from "react-icons/hi";
import MysticLady from "./mysticLady.png"
import { MdOutlineLanguage } from "react-icons/md";
import TarotCards from "./tarotCards.png"
import Dream from "./dream.png"

const Landing = () => {
  return (
    <div className = {styles.wrapper}>
        <div className = {styles.box}>
            <header>
                <div className = {styles.headerContent}>
                    <div className = {styles.logoBox}>
                        <div className = {styles.appIcon}>
                            <img src = {Logo} alt = ""/>
                        </div>
                        <div className = {styles.appName}>Astrooo</div>
                    </div>
                    <div className = {styles.clickUps}>
                        <div className = {styles.links}>
                            <div className = {`${styles.link} ${styles.selected}`}>Home</div>
                            <div className = {styles.link}>Categories</div>
                            <div className = {styles.link}>Pricing</div>
                        </div>
                        <div className = {styles.btns}>
                            <button className = {styles.loginBtn}>Login</button>
                            <button className = {styles.signupBtn}>Sign up</button>
                        </div>
                    </div>
                </div>
            </header>
            <div className = {styles.content}>
            <div className = {styles.illustrationWrapper}>
                <div className = {styles.illustration}>
                    <div className = {styles.leftBox}>
                        <div className = {styles.leftContent}>
                            <div className = {styles.illustrationTitle}>Personalized Tarot Readings and Dream Interpertations</div>
                            <div className = {styles.illustrationText}>Create an account with Astrooo and get in-person and online tarot seances and dream interpertations.</div>
                            <div className = {styles.inputBox}>
                                <div className = {styles.inputArea}>
                                    <div className = {styles.inputIcon}><HiOutlineMail/></div>
                                    <input type = "text" value = "" placeholder = "Type your email"/>
                                </div>
                                <div className = {styles.startBtn}>Get Started</div>
                            </div>
                        </div>
                    </div>
                    <div className = {styles.rightBox}>
                        <div className = {styles.image}>
                            <img src = {MysticLady} alt = ""/>
                            <div className = {styles.chatItem}><b>Your Destiny:</b> Begin your tarot journey and uncover your future, one card at a time</div>
                            <div className = {styles.chatItem}><b>Dreams Decoded:</b> Discover what your dreams reveal about your subconscious</div>
                            <div className = {styles.chatItem}><b>Personal Tarot Story:</b> Track your readings and see your growth in self-discovery</div>
                            <div className = {styles.chatItem}><b>Visualize Dreams:</b> Create and store images of your dreams for deeper insight</div>
                            <div className = {styles.chatItem}><b>Spiritual Archive:</b> Keep every reading and interpretation for continuous insig</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className = {styles.categories}>
                <div className = {styles.category}>
                    <div className = {styles.categoryImage}>
                        <img src = {TarotCards} alt = ""/>
                    </div>
                    <div className = {styles.info}>
                            <div className = {styles.infoTitle}>Tarot Seances</div>
                            <div className = {styles.infoDesc}>Dive into the mystical world of tarot - a captivating tool for reflection and decision-making. With its 78 ornate cards divided into the Major and Minor Arcana, tarot decks weave stories of our life lessons and everyday trials. Whether seeking wisdom, understanding complex situations, or exploring subconscious thoughts, tarot offers a unique mirror to our inner world. Embrace this ancient art to navigate the landscapes of your life with more insight and creativity.</div>
                            <button type = "button" className = {styles.btn}>Reveal my cards</button>
                    </div>
                </div>
                <div className = {styles.category}>
                    <div className = {styles.categoryImage}>
                        <img src = {Dream} alt = ""/>
                    </div>
                    <div className = {styles.info}>
                        <div className = {styles.infoTitle}>Dream Interpertations</div>
                        <div className = {styles.infoDesc}>Explore your dreams in an immersive journey, decoding symbols and facing fears. Your choices reveal deep insights, guiding you to profound self-discovery and wisdom for your waking life.</div>
                        <button type = "button" className = {styles.btn}>Describe my dream</button>
                    </div>
                </div>
            </div>
            <footer>
                <div className = {styles.footerContent}>
                    <div className = {styles.copyRight}>&copy; 2024 Astrooo, Inc.</div>
                    <div className = {styles.language}>
                        <div className = {styles.languageIcon}><MdOutlineLanguage/></div>
                        <div className = {styles.languageText}>English</div>
                    </div>
                    <div className = {styles.text}>Terms of Service</div>
                    <div className = {styles.text}>Privacy Policy</div>
                </div>
            </footer>
        </div>
        </div>
    </div>
  )
}

export default Landing