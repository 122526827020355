import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/ViewDream.module.css"
import { FaEllipsis } from "react-icons/fa6";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { IoIosBookmarks } from "react-icons/io";
import { BsQuestionDiamondFill } from "react-icons/bs";
import { TfiAlarmClock } from "react-icons/tfi";
import { HiMiniArrowLongRight } from "react-icons/hi2";
import DreamMenu from './DreamMenu';
import api from '../../../utils/api';
import ReactMarkdown from 'react-markdown';
import CrashModal from '../../errors/js/CrashModal';
import NotFoundDream from '../../errors/js/NotFoundDream';

const monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const ViewDream = ({close,handleMenu,id}) => {
    const box = useRef(null)
    const [showMenu,setShowMenu] = useState(false)
    const [dream,setDream] = useState(null)
    const [notFound,setNotFound] = useState(false)
    const [crashError,setCrashError] = useState(false)
    const fetching = useRef(true)

  useEffect(()=>{
    if (fetching.current){
        fetching.current = false
        let startTime = performance.now()
        api.get(`/dreams/${id}/userView/`)
        .then((res)=>{
            let endTime = performance.now()
            let duration = endTime - startTime
            setTimeout(()=>{
                setDream(res.data)
            },duration < 2000 ? 2000 - duration : 0)
        })
        .catch((err)=>{
            if (err.response?.status === 404){
              setNotFound(true)
            }
            else{
              setCrashError("It looks like something unexpected occured when trying to retrieve the selected tarot reading.")
            }
        })
    }
    const handleClick = (e) => {
        if (!box.current?.contains(e.target)){
            close()
        }
    }
    window.addEventListener("mousedown",handleClick)
    return () => {
        window.removeEventListener("mousedown",handleClick)
    }
    },[close,id])

    const getDateString= useCallback((date_value)=>{
        if (date_value){
            let date_field
            const today = new Date()
            today.setHours(0, 0, 0, 0)
            const yesterday = new Date(today)
            yesterday.setDate(yesterday.getDate() - 1)
            yesterday.setHours(0, 0, 0, 0)
            const inputDate = new Date(date_value)
            inputDate.setHours(0, 0, 0, 0)
            if (inputDate.getTime() === today.getTime()) {
                date_field = "Today";
            } else if (inputDate.getTime() === yesterday.getTime()) {
                date_field = "Yesterday";
            } else {
                date_field = monthsShort[inputDate.getMonth()] + " " + inputDate.getDate() + ", " + inputDate.getFullYear()
            }
    
            const hours = new Date(date_value).getHours().toString().padStart(2, '0');
            const minutes = new Date(date_value).getMinutes().toString().padStart(2, '0');
            
            return date_field + " at " + hours + ":" + minutes
        }
        else{
            return null
        }
    },[])

    const dream_date = useMemo(()=>{
        if (dream?.dreamt_at){
            const today = new Date()
            today.setHours(0, 0, 0, 0)
            const yesterday = new Date(today)
            yesterday.setDate(yesterday.getDate() - 1)
            yesterday.setHours(0, 0, 0, 0)
            const inputDate = new Date(dream.dreamt_at)
            inputDate.setHours(0, 0, 0, 0)
            if (inputDate.getTime() === today.getTime()) {
                return "Today"
            }
            else if (inputDate.getTime() === yesterday.getTime()) {
                return "Yesterday"
            } 
            else {
                return monthsShort[inputDate.getMonth()] + " " + inputDate.getDate() + ", " + inputDate.getFullYear()
            }
        }
        else{
            return "Not Set"
        }
    },[dream?.dreamt_at])

  return (
    crashError ? <CrashModal msg = {crashError} close = {()=>close()}/> : notFound ? <NotFoundDream close = {()=>close()}/> : <div className = {styles.wrapper}>
        <div className = {styles.box} ref = {box}>
            <header>
                <div className = {styles.closeIcon} onClick = {()=>close()}><MdKeyboardDoubleArrowLeft/></div>
                <div className = {styles.menuWrapper}>
                    <div className = {`${styles.menuIcon} ${showMenu && styles.active}`} onClick = {()=>setShowMenu(!showMenu)}><FaEllipsis/></div>
                    {showMenu && <DreamMenu viewModal = {true} handleMenu = {handleMenu} close = {()=>setShowMenu(false)}/>}
                </div>
            </header>
            {dream ? <div className = {styles.content}>
                <div className = {styles.dateWrapper}>
                    <div className = {styles.dateItem}>
                        <div className = {styles.dateTitle}>Dream Date</div>
                        <div className = {styles.dateValue}>{dream_date}</div>
                    </div>
                    <div className = {styles.dateItem}>
                        <div className = {styles.dateTitle}>Creation Date</div>
                        <div className = {styles.dateValue}>{getDateString(dream.created_at)}</div>
                    </div>
                </div>
                <div className = {styles.section}>
                    <div className = {styles.title}>{dream.title}</div>
                    <div className = {styles.imageBox}>
                        <img src = {`${process.env.REACT_APP_API_URL}/dreamImages/${dream.image}`} alt = ""/>
                    </div>
                    <div className = {styles.text}>{dream.dream}</div>
                </div>
                <div className = {styles.section}>
                    <div className = {styles.titleBox}>
                        <div className = {styles.sectionTitle}>Interperation</div>
                        <div className = {styles.icon}><IoIosBookmarks/></div>
                    </div>
                    <div className = {styles.text}><ReactMarkdown>{dream.interpertation}</ReactMarkdown></div>
                </div>
                {dream.questions.length > 0 && <div className = {styles.section}>
                    <div className = {styles.titleBox}>
                        <div className = {styles.sectionTitle}>Follow-up Questions</div>
                        <div className = {styles.icon}><BsQuestionDiamondFill/></div>
                    </div>
                    <div className = {styles.questions}>
                        {dream.questions.map((instance,index)=>(
                            <div className = {styles.questionBox} key = {index}>
                                <div className = {styles.question}>{instance.question}</div>
                                <div className = {styles.dateBox}>
                                    <div className = {styles.timeIcon}><TfiAlarmClock/></div>
                                    <div className = {styles.date}>{getDateString(instance.asked_at)}</div>
                                </div>
                                <div className = {styles.answer}><div className = {styles.arrowIcon}><HiMiniArrowLongRight/></div><ReactMarkdown>{instance.answer}</ReactMarkdown></div>
                            </div>
                        ))}
                    </div>
                </div>}
            </div> : <div className = {styles.fetchingLoader}><div className = {styles.spinner}></div></div>}
        </div>
    </div>
  )
}

export default ViewDream