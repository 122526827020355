import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from "../css/ViewUser.module.css"
import { getName } from 'country-list'
import { FiEdit3 } from 'react-icons/fi'
import { CgTrash } from 'react-icons/cg'
import { IoMailOutline } from "react-icons/io5";
import { IoLocationOutline } from "react-icons/io5";
import { GiCardRandom } from "react-icons/gi";
import { IoMoonOutline } from "react-icons/io5";
import { IoDiamondOutline } from "react-icons/io5";
import { GrTransaction } from "react-icons/gr";
import { GrMoney } from "react-icons/gr";
import { IoArrowBackOutline } from "react-icons/io5";
import { FaArrowRightLong } from "react-icons/fa6";
import axios from 'axios'


const ViewUser = ({id,reload,closeModal,handleDelete,handleEdit}) => {
  const [user,setUser] = useState(null)
  const [crashError,setCrashError] = useState(false)
  const [notFound,setNotFound] = useState(false)
  const fetching = useRef(true)

  useEffect(()=>{
    if (fetching.current){
      fetching.current = false
      const start  = performance.now()
      axios.get(`${process.env.REACT_APP_API_URL}/admin/users/${id}`)
      .then((res)=>{
          const end = performance.now()
          const waiting_time = end - start < 500 ? (500 - end - start) : 0
          setTimeout(()=>{
              setUser(res.data)
          },waiting_time)
      })
      .catch((err)=>{
          if (err?.response?.status === 404){
              setNotFound(true)
              reload()
          }
          else{
              setCrashError(true)
          }
      })
    }
  },[id,reload])

  const formatDate = useCallback((date)=>{
    const day = date.getDate().toString().padStart(2, '0');
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  },[])

  return (
    <div className = {styles.wrapper}>
      <div className = {styles.box}>
        <div className = {styles.headerArea}>
          <div className = {styles.firstArea}>
            <div className = {styles.arrowIcon} onClick = {()=>closeModal()}><IoArrowBackOutline/></div>
            <div className = {styles.title}>User Details</div>
          </div>
          <div className = {styles.btns}>
            <div className = {styles.editBtn} onClick = {()=>handleEdit()}><FiEdit3/></div>
            <div className = {styles.deleteBtn} onClick = {()=>handleDelete()}><CgTrash/></div>
          </div>
          <div className = {styles.imageArea}>
            <div className = {`${styles.imageBox} ${user?.avatar && styles.set}`}>{user?.avatar ? 
              <img src = {`${process.env.REACT_APP_API_URL}/profileImages/${user.avatar}`} alt = ""/> :
              user?.fullname.charAt(0).toUpperCase()
              }
            </div>
          </div>
        </div>
        {user && <main>
          <div className = {styles.mainInfo}>
            <div className = {styles.name}>{user.fullname}</div>
            <div className = {styles.joinDate}>Joined on {formatDate(new Date(user.date_joined))} </div>
            <div className = {styles.status}>{user.is_active ? "Active " + user.role : "Inactive " + user.role}</div>
          </div>
          <div className = {styles.section}>
            <div className = {styles.sectionTitle}>Profile Details</div>
            <div className = {styles.detailBox}>
              <div className = {styles.detailLabelBox}>
                <div className = {styles.detailIcon}><IoMailOutline/></div>
                <div className = {styles.detailLabel}>Email</div>
              </div>
              <div className = {styles.detailContent}>{user.email}</div>
            </div>
            <div className = {styles.detailBox}>
              <div className = {styles.detailLabelBox}>
                <div className = {styles.detailIcon}><IoLocationOutline/></div>
                <div className = {styles.detailLabel}>Country</div>
              </div>
              <div className = {styles.detailContent}>{getName(user.country)}</div>
            </div>
          </div>
          <div className = {styles.section}>
            <div className = {styles.sectionTitle}>Statistics</div>
            <div className = {styles.detailBox}>
              <div className = {styles.detailLabelBox}>
                <div className = {styles.detailIcon}><GiCardRandom/></div>
                <div className = {styles.detailLabel}>Tarot Readings</div>
              </div>
              <div className = {styles.detailContent}>{user.stats.tarots.num_readings} readings
                <div className = {styles.minArrow}><FaArrowRightLong/></div>
                {user.stats.tarots.num_questions} questions
              </div>
            </div>
            <div className = {styles.detailBox}>
              <div className = {styles.detailLabelBox}>
                <div className = {styles.detailIcon}><IoMoonOutline/></div>
                <div className = {styles.detailLabel}>Dream interpertations</div>
              </div>
              <div className = {styles.detailContent}>{user.stats.dreams.num_readings} interpertations
                <div className = {styles.minArrow}><FaArrowRightLong/></div>
                {user.stats.dreams.num_questions} questions
                </div>
              </div>
          </div>
          <div className = {`${styles.section} ${styles.financial}`}>
            <div className = {styles.sectionTitle}>Financial Details</div>
            <div className = {styles.detailBox}>
              <div className = {styles.detailLabelBox}>
                <div className = {`${styles.detailIcon} ${styles.diamond}`}><IoDiamondOutline/></div>
                <div className = {styles.detailLabel}>Current Balance:</div>
              </div>
              <div className = {styles.detailContent}>&nbsp;{user.financial_details.balance}</div>
            </div>
            <div className = {styles.detailBox}>
              <div className = {styles.detailLabelBox}>
                <div className = {`${styles.detailIcon} ${styles.transactions}`}><GrTransaction/></div>
                <div className = {styles.detailLabel}>Number of Transactions:</div>
              </div>
              <div className = {styles.detailContent}>&nbsp;{user.financial_details.num_transactions}</div>
            </div>
            <div className = {styles.detailBox}>
              <div className = {styles.detailLabelBox}>
                <div className = {`${styles.detailIcon} ${styles.money}`}><GrMoney/></div>
                <div className = {styles.detailLabel}>Total Money Spent:</div>
              </div>
              <div className = {styles.detailContent}>&nbsp;{user.financial_details.money_spent} Euros</div>
            </div>
          </div>
        </main>}
      </div>
    </div>
  )
}

export default ViewUser