import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/FilterMenu.module.css"
import Calendar from './Calendar'
import { TbCalendarPlus } from "react-icons/tb";
import { TbCalendarStats } from "react-icons/tb";
import { TiArrowUnsorted } from "react-icons/ti";
import { IoClose } from "react-icons/io5";
import { MdOutlineFilterList } from "react-icons/md";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const date_fields = {
  today : "Today",
  yesterday: "Yesterday",
  current_week: "This week",
  last_week: "Last Week",
  last_biweek: "Last 15 days",
  last_month: "Last Month"
}
const FilterMenu = ({filterParams,onChangeFilterParams,close}) => {
  const [filterObject,setFilterObject] = useState({...filterParams})
  const [showCalendar,setShowCalendar] = useState(false)
  const [showDates,setShowDates] = useState(false)
  const creationDateMenu = useRef(null)
  const menu = useRef(null)

  const handleResize = useCallback(() => {
    if (showCalendar){
      setShowCalendar(false)
    }
    if (showDates){
      setShowDates(false)
    }
  },[showCalendar,showDates])

  useEffect(()=>{

    const handleClick = (e) => {
        if (!menu.current.parentNode.contains(e.target)){
            close()
        }
        if (!creationDateMenu.current?.parentNode?.contains(e.target) && showDates){
            setShowDates(false)
        }
    }
    window.addEventListener("mousedown",handleClick)
    window.addEventListener("resize",handleResize)
    return () => {
        window.removeEventListener("mousedown",handleClick)
        window.removeEventListener("resize",handleResize)
    }
  },[close,showDates,handleResize])

  const dream_date = useMemo(() => {
    if (filterObject.dream_date){
      return months[filterObject.dream_date.getMonth()] + " " + filterObject.dream_date.getDate() +", " + filterObject.dream_date.getFullYear()
    }
    else{
      return ""
    }
  },[filterObject])

  const save = () => {
    onChangeFilterParams(filterObject)
    close()
}

  const selectGameDate = (key) => {
      setFilterObject({...filterObject,created_at:key})
      setShowDates(false)
  }

  const setCreationDateMenu = useCallback((el)=>{
    const button = el?.parentNode?.children[0]
    if (el && button){
      el.style.maxHeight = ""
      if (window.innerHeight - button.getBoundingClientRect().bottom > button.getBoundingClientRect().top){
        el.style.top = `${button.getBoundingClientRect().bottom + 5}px`
        el.style.maxHeight = `${window.innerHeight - 10 - button.getBoundingClientRect().bottom}`
      }
      else{
        const top = button.getBoundingClientRect().top - el.offsetHeight < 5 ? 5 : button.getBoundingClientRect().top - el.offsetHeight - 5
        el.style.top = `${top}px`
        el.style.maxHeight = `${top === 5 ? button.getBoundingClientRect().top - 10 : button.getBoundingClientRect().top - 5}px`
      }
      el.style.left = `${button.getBoundingClientRect().left}px`
      el.style.width = `${button.offsetWidth}px`
      creationDateMenu.current = el
    }
  },[])

  return (
    <div className = {styles.menu} ref = {menu}>
      <div className = {styles.header}>
        <div className = {styles.headerIcon}><MdOutlineFilterList/></div>
        <div className = {styles.headerTitle}>Filters</div>
      </div>
      <div className = {styles.content}>
        <div className = {styles.section}>
          <div className = {styles.label}>Dreamt on</div>
          <div className = {styles.selectorBox}>
            <div className = {`${styles.selectorInput} ${filterObject.dream_date && styles.min} ${showCalendar && styles.active}`}  onClick = {()=>setShowCalendar(!showCalendar)}>
              <div className = {styles.valueBox}>
                  <div className = {styles.icon}><TbCalendarStats/></div>
                  <div className = {styles.value}>{filterObject.dream_date ? dream_date : "Select dream date"}</div>
              </div>
              <div className = {styles.arrow}><TiArrowUnsorted/></div>
            </div>
            {filterObject.dream_date && <div className = {styles.removeBtn} onClick = {()=>setFilterObject({...filterObject,dream_date:null})}><IoClose/></div>}
            {showCalendar && <Calendar selectedDate = {filterObject.dream_date} selectDate={(value)=>setFilterObject({...filterObject,dream_date:value})} close = {()=>setShowCalendar(false)}/>}
          </div>
        </div>
        <div className = {styles.section}>
          <div className = {styles.label}>Created on</div>
          <div className = {styles.selectorBox}>
            <div className = {`${styles.selectorInput} ${filterObject.created_at && styles.min} ${showDates && styles.active}`} onClick = {()=>setShowDates(!showDates)}>
              <div className = {styles.valueBox}>
                  <div className = {styles.icon}><TbCalendarPlus/></div>
                  <div className = {styles.value}>{filterObject.created_at ? date_fields[filterObject.created_at] : "Select creation date"}</div>
              </div>
              <div className = {styles.arrow}><TiArrowUnsorted/></div>
            </div>
            {filterObject.created_at && <div className = {styles.removeBtn} onClick = {()=>setFilterObject({...filterObject,created_at:null})}><IoClose/></div>}
            {showDates && <div className = {styles.fieldMenu} ref = {setCreationDateMenu}>
                {Object.keys(date_fields).map((key,index)=>(
                <div className = {`${styles.fieldItem} ${key} ${filterObject.created_at === key && styles.selected}`} key = {index} onClick = {()=>selectGameDate(key)}>
                  {date_fields[key]}
                </div>
                ))}
            </div>}
          </div>
        </div>
      </div>
      <div className = {styles.footer}>
        <button type = "button" className = {styles.cancelBtn} onClick = {()=>close()}>Cancel</button>
        <button type = "button" className = {styles.saveBtn} disabled = {JSON.stringify(filterObject) === JSON.stringify(filterParams)} onClick = {()=>save()}>Save</button>
      </div>
    </div>
  )
}

export default FilterMenu