import React from 'react'
import styles from "../css/CrashModal.module.css"
import CrashImage from "../../auth/media/robot.png"
import { IoIosCloseCircle } from "react-icons/io";

const CrashModal = ({msg,close}) => {
  return(
    <div className = {styles.wrapper}>
        <div className = {styles.box}>
            <div className = {styles.closeIcon} onClick = {()=>close()}><IoIosCloseCircle/></div>
            <div className = {styles.imageBox}>
                <img src = {CrashImage} alt = ""/>
            </div>
            <div className = {styles.info}>
              <div className = {styles.title}><span>Aaaah! </span>Something went wrong</div>
              <div className = {styles.msg}>{msg}</div>
            </div>
        </div>
    </div>
  )
}

export default CrashModal