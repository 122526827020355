import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/Profile.module.css"
import { TbMail } from "react-icons/tb";
import { TbLock } from "react-icons/tb";
import api from '../../../utils/api';
import validator from 'validator'
import { updateUserData } from '../../../utils/auth';

const Security = ({close}) => {
    const [dropDown,setDropDown] = useState("")
    const [emailData,setEmailData] = useState({current_password:"",new_email:"",confirmation_code:"",input_code:""})
    const [passwordData,setPasswordData] = useState({current_password:"",new_password:"",confirm_password:""})
    const [codeExpired,setCodeExpired] = useState(false)
    const [errors,setErrors] = useState({})
    const [loading,setLoading] = useState(false)
    const codeRef = useRef(null)


    const btnText = useMemo(() => {
        if (dropDown === "email"){
            return emailData.confirmation_code ? "Change email" : "Continue"
        }
        else{
            return "Change password"
        }
    },[emailData.confirmation_code,dropDown])

    const isDisabled = useCallback(()=>{
        if (dropDown === "email"){
            if (!emailData.confirmation_code){
                return !emailData.current_password || !emailData.new_email || Object.keys(errors).length
            }
            else{
                return !emailData.confirmation_code || Object.keys(errors).length || codeExpired
            }
        }
        else{
            return Object.keys(errors).length || Object.values(passwordData).some(x => !x)
        }
    },[dropDown,emailData,passwordData,errors,codeExpired])

    const handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (errors[name]){
            setErrors((prevErrors)=>{
                let copy = {...prevErrors}
                delete copy[name]
                return copy
            })
        }
        if (dropDown === "email"){
            setEmailData({...emailData,[name]:value})
        }
        else{
            setPasswordData({...passwordData,[name]:value})
        }
    }

    const cancel = () => {
        if (dropDown === "email"){
            setEmailData({current_password:"",new_email:"",confirmation_code:"",input_code:""})
        }
        else{
            setPasswordData({current_password:"",new_password:"",confirm_password:""})
        }

        setErrors({})
        setDropDown("")
    }

    const changePassword = () => {
        let formErrors = {}
        if (passwordData.new_password.length < 8){
            formErrors['new_password'] = "At least 8 characters"
        }
        if (passwordData.new_password !== passwordData.confirm_password){
            formErrors['confirm_password'] = "Passwords do not match"
        }
        if (Object.keys(formErrors).length){
            setErrors({...formErrors})
            return
        }
        setLoading(true)
        api.put("/profile/changePassword",{"current_password":passwordData.current_password,"new_password":passwordData.new_password})
        .then((_)=>{
            close()
        })
        .catch((err)=>{
            if (err?.response?.status === 400){
                setErrors({...err.response.data})
            }
            console.log("Error = ",err)
        })
        .finally(()=>{
            setLoading(false)
        })
    }

    const requestEmailChange = () => {
        if (!validator.isEmail(emailData.new_email)){
            setErrors({"new_email":"Invalid email address"})
            return
        }
        setLoading(true)
        let data = {"current_password":emailData.current_password,"new_email":emailData.new_email}
        api.post("/profile/requestEmailChange",data)
        .then((res)=>{
            setEmailData({...emailData,confirmation_code:res.data.code})
            codeRef.current.focus()
        })
        .catch((err)=>{
            if (err?.response?.status === 400){
                setErrors({...err.response.data})
            }
            console.log("Error = ",err)
        })
        .finally(()=>{
            setLoading(false)
        })
    }

    const confirmEmail = () => {
        if (emailData.input_code !== emailData.confirmation_code){
            setErrors({input_code:"Incorrect activation code"})
        }
        else{
            let formData = new FormData()
            formData.append('email',emailData.new_email)
            setLoading(true)
            api.put('/profile/edit',formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((res)=>{
                updateUserData({email:res.data.email})
                close()
            })
            .catch((err)=>{
                console.log("Error = ",err)
            })
            .finally(()=>{
                setLoading(false)
            })
        }
    }

    const resendEmailChange = () => {
        if (codeExpired){
            setCodeExpired(false)
        }
        setLoading(true)
        setEmailData((prevData)=>{return {...prevData,input_code:"",confirmation_code:""}})
        const encodedEmail = encodeURIComponent(emailData.new_email)
        api.post(`/profile/resendEmailChange?new_email=${encodedEmail}`)
        .then((res)=>{
            setEmailData((prevData)=>{return {...prevData,confirmation_code:res.data.code}})

            codeRef.current.focus()
        })
        .catch((err)=>{
            if (err?.response?.status === 400){
                setErrors({...err.response.data})
            }
            console.log("Error = ",err)
        })
        .finally(()=>{
            setLoading(false)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (dropDown === "password"){
            changePassword()
        }
        else{
            if (!emailData.confirmation_code){
                requestEmailChange()
            }
            else{
                confirmEmail()
            }
        }
    }

  return (
    <form className = {`${styles.box} ${styles.security}`} onSubmit = {handleSubmit} autoComplete='off'>
        <div className = {styles.content}>
            {dropDown !== "password" && <div className = {styles.container}>
                <div className = {styles.titleBox}>
                    <div className = {styles.titleIcon}><TbMail/></div>
                    <div className = {styles.titleText}>Email address</div>
                </div>
                <div className = {styles.subtitle}>Update the email you use for your <b>Astrooo</b> account. Your email is currently <b>{JSON.parse(localStorage.getItem("user",{})).email || ""}</b>.</div>
                {dropDown === "email" && <div className = {styles.inputs}>
                    <div className = {`${styles.section} ${errors.new_email && styles.errorBox}`}>
                        <label htmlFor='new_email'>New Email</label>
                        <input type = "text" value = {emailData.new_email} name = "new_email" onChange = {handleChange} disabled={emailData.confirmation_code} autoComplete='off'/>
                        {errors.new_email && <div className = {styles.error}>{errors.new_email}</div>}
                    </div>
                    <div className = {`${styles.section} ${errors.current_password && styles.errorBox}`}>
                        <label htmlFor='current_password'>Current Password</label>
                        <input type = "password" value = {emailData.current_password} name = "current_password" onChange = {handleChange} disabled={emailData.confirmation_code} autoComplete='off'/>
                        {errors.current_password && <div className = {styles.error}>{errors.current_password}</div>}
                    </div>
                    <div className = {`${styles.section} ${errors.input_code && styles.errorBox}`}>
                        <label htmlFor='input_code'>Confirm Email</label>
                        {emailData.confirmation_code && <ExpirtationDate resend = {resendEmailChange} disable={()=>setCodeExpired(true)}/>}
                        <input type = "text" attr = "code" ref = {codeRef} value = {emailData.input_code} name = "input_code" onChange = {handleChange} maxLength={5} disabled={!emailData.confirmation_code || codeExpired} placeholder= "- - - - -" autoComplete='off'/>
                        {errors.input_code && <div className = {styles.error}>{errors.input_code}</div>}
                    </div>
                </div>}
                {dropDown !== "email" && <button type = "button" className = {styles.changeBtn} onClick = {()=>setDropDown("email")}>Change email</button>}
            </div>}
            {!dropDown && <hr/>}
            {dropDown !== "email" && <div className = {styles.container}>
                <div className = {styles.titleBox}>
                    <div className = {styles.titleIcon}><TbLock/></div>
                    <div className = {styles.titleText}>Password</div>
                </div>
                <div className = {styles.subtitle}>Update the password you use for your <b>Astrooo</b> account. Your password must be at least 8 characters long</div>
                {dropDown === "password" && <div className = {styles.inputs}>
                    <div className = {`${styles.section} ${errors.current_password && styles.errorBox}`}>
                        <label htmlFor='current_password'>Current Password</label>
                        <input type = "password" value = {passwordData.current_password} name = "current_password" onChange = {handleChange} autoComplete='off'/>
                        {errors.current_password && <div className = {styles.error}>{errors.current_password}</div>}
                    </div>
                    <div className = {`${styles.section} ${errors.new_password && styles.errorBox}`}>
                        <label htmlFor='new_password'>New Password</label>
                        <input type = "password" value = {passwordData.new_password} name = "new_password" onChange = {handleChange} autoComplete='off'/>
                        {errors.new_password && <div className = {styles.error}>{errors.new_password}</div>}
                    </div>
                    <div className = {`${styles.section} ${errors.confirm_password && styles.errorBox}`}>
                        <label htmlFor='confirm_password'>Confirm Password</label>
                        <input type = "password" value = {passwordData.confirm_password} name = "confirm_password" onChange = {handleChange} autoComplete='off'/>
                        {errors.confirm_password && <div className = {styles.error}>{errors.confirm_password}</div>}
                    </div>
                </div>}
                {dropDown !== "password" && <button type = "button" className = {styles.changeBtn} onClick = {()=>setDropDown("password")}>Change password</button>}
            </div>}
        </div>
        {dropDown && <footer>
            <button type = "button" className = {styles.cancelBtn} onClick = {()=>cancel()}>Cancel</button>
            {loading ? <div className = {styles.loadingBtn}><div className = {styles.loader}></div></div> : <button type = "submit" className = {styles.coloredSaveBtn} disabled = {isDisabled()}>{btnText}</button>}
        </footer>}
    </form>
  )
}

const ExpirtationDate = ({resend,disable}) => {
    const [time, setTime] = useState(1 * 60); // Initial time in seconds (5 minutes)

    useEffect(() => {
      const interval = setInterval(() => {
        setTime(prevTime => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            disable()
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);
  
      return () => clearInterval(interval); // Cleanup function
    }, []);

    return (
        time ? <div className = {styles.expiryText}>Code expires in {Math.floor(time / 60)}:{time % 60 < 10 ? `0${time % 60}` : time % 60}. <span onClick = {()=>resend()}>Resend</span></div> : <div className = {`${styles.expiryText} ${styles.expired}`}>Code expired <span onClick = {()=>resend()}>Resend</span></div>
    )
}

export default Security