import React from 'react'
import styles from "../css/GameType.module.css"
import TarotHelper from "../media/tarotHelper.png"
import ManualSelector from "../media/manualSelection.png"
import Ribbon from "../../dreamGame/media/ribbon.png"
const GameType = ({goNext}) => {

  const select = () => {
    console.log(goNext)
    goNext()
  }

  return (
    <div className = {styles.wrapper}>
      <div className = {styles.box}>
        <div className = {styles.title} style = {{ backgroundImage: `url(${Ribbon})`}}>
          How to select tarot spread?
        </div>
        <div className = {styles.categories}>
        <div className = {styles.selectionWrapper} onClick = {()=>select()}>
          <div className = {styles.selectionBox}>
            <div className = {styles.front}>
              <div className = {styles.imageBox}  style = {{ backgroundImage: `url(${TarotHelper})`}}></div>
              <div className = {styles.btnWrapper}>
                <div className = {styles.btn}>Choose with Tarot Helper</div>
              </div>
            </div>
            <div className = {styles.back}>
              <div className = {styles.type}>Tarot Helper</div>
              <div className = {styles.descTitle}>Point Breakdown</div>
              <ul className = {styles.desc}>
                <li>*(?) x for interpretation + 1 following question</li>
                <li>Z for tarot helper</li>
                <li>D for next following questions</li>
              </ul>
            </div>
            </div>
        </div>
        <div className = {styles.selectionWrapper} onClick = {()=>select()}>
          <div className = {styles.selectionBox}>
            <div className = {styles.front}>
              <div className = {styles.imageBox} style = {{ backgroundImage: `url(${ManualSelector})`}}></div>
              <div className = {styles.btnWrapper}>
                <div className = {styles.btn}>Choose yourself</div>
              </div>
            </div>
            <div className = {styles.back}>
              <div className = {styles.type}>Manual Selection</div>
              <div className = {styles.descTitle}>Point Breakdown</div>
              <ul className = {styles.desc}>
                <li>*(?) x for interpretation + 1 following question</li>
                <li>D for next following questions</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   )
}

export default GameType