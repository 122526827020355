import React from 'react'
import styles from "../css/CrashPage.module.css"
import CrashImage from "../../auth/media/robot.png"

const CrashPage = ({msg}) => {
  return (
    <div className = {styles.wrapper}>
        <div className = {styles.box}>
            <div className = {styles.imageBox}>
                <img src = {CrashImage} alt = ""/>
            </div>
            <div className = {styles.info}>
                <div className = {styles.title}>ooops<span>, something went wrong</span></div>
                <div className = {styles.msg}>{msg}</div>
            </div>
        </div>
    </div>
  )
}

export default CrashPage