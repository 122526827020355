import React, { useState } from 'react'
import styles from "../css/ExitModal.module.css"
import { IoClose } from 'react-icons/io5'
import api from '../../../utils/api'

const ExitModal = ({game_id,close,exit,isComplete = false}) => {
  const [loading,setLoading] = useState(false)

  const quit = () => {
    if (!isComplete){
      setLoading(true)
      let startTime = performance.now()
      api.delete(`/dreamReadings/delete/${game_id}`)
      .then((_)=>{
          let endTime = performance.now()
          let duration = endTime - startTime
          setTimeout(()=>{
              setLoading(false)
              exit()
          },duration < 800 ? 800 - duration : 0)
      })
      .catch((err)=>{
        console.log("DELETION ERROR = ",err)
        setLoading(false)
      })
    }
    else{
      exit()
    }
  }
  return (
    <div className = {styles.wrapper}>
      <div className = {styles.boxWrapper}>
        <div className = {styles.box}>
            <div className = {styles.content}>Are you sure you want to exit this game{isComplete ? " Nothing will be lost" : " and lose all your progress?"}</div>
            <div className = {`${styles.buttons} ${loading && styles.loading}`}>
                <button type = "button" onClick = {()=>quit()}>
                  {!loading ? "Yes" : <div className = {styles.loader}></div>}
                </button>
                <button type = "button" onClick = {()=>close()}>No</button>
            </div>
        </div>
        <div className = {styles.closeIcon} onClick = {()=>close()}><IoClose/></div>
      </div>
    </div>
  )
}

export default ExitModal