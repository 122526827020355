import React, { useEffect, useRef, useState } from 'react'
import styles from "../css/History.module.css"
import { GiNightSleep } from 'react-icons/gi'
import { FaPlay } from 'react-icons/fa6'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const History = ({dreams,handleDreamView,closeHistory}) => {
    const [scrolling,setScrolling] = useState({left:{state:false,direction:"down",prevOffset:0,initial_check:false},right:{state:false,direction:"down",prevOffset:0,initial_check:false}})
    const leftPage = useRef(null)
    const rightPage = useRef(null)
  
    // Check overflow and direction on scroll
    useEffect(() => {
        let leftSide = leftPage.current
        let rightSide = rightPage.current

        const handleLeftScroll = () => {
            if (leftPage.current) {
                const state = leftPage.current.scrollHeight > leftPage.current.clientHeight;
                const currentScrollPosition = leftPage.current.scrollTop;
                var direction = currentScrollPosition > scrolling.left.prevOffset ? 'down' : 'up'
                if (direction === "up" && currentScrollPosition === 0){
                    direction = "down"
                }
                else if (direction === "down" && currentScrollPosition >= (leftPage.current.scrollHeight -  leftPage.current.clientHeight)){
                    direction = "up"
                }
                setScrolling((prevState)=>{
                    let copy = {...prevState}
                    copy.left = {state, direction, prevOffset: currentScrollPosition, initial_check:true }
                    return copy
                })
            }
        }

        const handleRightScroll = () => {
            if (rightPage.current) {
                const state = rightPage.current.scrollHeight > rightPage.current.clientHeight;
                const currentScrollPosition = rightPage.current.scrollTop;
                var direction = currentScrollPosition > scrolling.right.prevOffset ? 'down' : 'up';
                if (direction === "up" && currentScrollPosition === 0){
                    direction = "down"
                }
                else if (direction === "down" && Math.round(currentScrollPosition) >= rightPage.current.scrollHeight -  rightPage.current.clientHeight){
                    direction = "up"
                }
                setScrolling((prevState)=>{
                    let copy = {...prevState}
                    copy.right= {state, direction, prevOffset: currentScrollPosition, initial_check: true }
                    return copy
                })
            }
        }
  
        if (leftPage.current) {
            leftPage.current.addEventListener('scroll', handleLeftScroll)
            if (!scrolling.left.initial_check){
                handleLeftScroll()
            }
        }
    
        if (rightPage.current) {
            rightPage.current.addEventListener('scroll', handleRightScroll)
            if (!scrolling.right.initial_check){
                handleRightScroll()
            }
        }

        // Cleanup
        return () => {
            if (leftSide) {
            leftSide.removeEventListener('scroll', handleLeftScroll);
            }
            if (rightSide) {
                rightSide.removeEventListener('scroll', handleLeftScroll);
            }
        };
    }, [scrolling])

    const showDream = (dream_id) => {
        handleDreamView(dream_id)
        closeHistory()
    }

  return (
    <div className = {styles.bookArea}>
        <div className = {styles.leftContainer}>
            <div className = {styles.historyContent} ref = {leftPage}>
                {dreams.filter((_, index) => (index+1) % 2 !== 0).map((dream,index)=>(
                    <DreamBox dream = {dream} key = {index} select = {()=>showDream(dream.id)}/>
                ))}
            </div>
        </div>
        <div className = {styles.rightContainer}>
            <div className = {styles.historyContent} ref = {rightPage}>
                {dreams.filter((_, index) => (index+1) % 2 === 0).map((dream,index)=>(
                    <DreamBox dream = {dream} key = {index} select = {()=>showDream(dream.id)}/>
                ))}
            </div>
        </div>
        {scrolling.left.state && <div className = {`${styles.arrowWrapper} ${styles.left}`}>
            <div className = {styles.arrowBtn}>
            <div className = {styles.arrowIcon}>{scrolling.left.direction === "down"  ? <RiArrowDownSLine/> : <RiArrowUpSLine/> } </div>
            </div>
            </div>}
        {scrolling.right.state && <div className = {`${styles.arrowWrapper} ${styles.right}`}>
            <div className = {styles.arrowBtn}>
                <div className = {styles.arrowIcon}>{scrolling.right.direction === "down" ? <RiArrowDownSLine/> : <RiArrowUpSLine/> } </div>
            </div>
        </div>}
    </div>
  )
}

const DreamBox = ({dream,select}) => {
    return (
        <div className = {styles.dreamBox}>
            <div className = {styles.imageBox} style = {{ backgroundImage: `url(${process.env.REACT_APP_API_URL}/dreamImages/${dream.image})`}}></div>
            <div className = {styles.dreamContainer}>
                <div className = {styles.dreamBody}>
                <div className = {styles.historyTitle}>{dream.title}</div>
                <div className = {styles.dreamDateBox}>
                    <div className = {styles.moonIcon}><GiNightSleep/></div>
                    <div className = {styles.dreamtAtDate}>{months[new Date(dream.dreamt_at).getMonth()] + " " + new Date(dream.dreamt_at).getDate() +", " + new Date(dream.dreamt_at).getFullYear()}</div>
                </div>
                <div className = {styles.dreamContent}>{dream.content}</div>
                </div>
            </div>
            <div className = {styles.resumeBtnWrapper} onClick = {()=>select(dream.id)}>
                <div className = {styles.resumeBtn}><FaPlay/></div>
            </div>
        </div>
    )
}

export default History