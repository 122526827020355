import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from  "../css/Users.module.css"
import NavBar from './NavBar'
import { IoGiftSharp } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { TiArrowSortedDown } from "react-icons/ti";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import useFormatTable from './useFormatTable';
import { BiSortAlt2 } from 'react-icons/bi';
import axios from 'axios';
import { debounce } from 'lodash';

const Transactions = ({skeleton = false}) => {
    const [data,setData] = useState(null)
    const [searchValue,setSearchValue] = useState("")
    const [showSort,setShowSort] = useState(false)
    const [sortParams,setSortParams] = useState({field:"timestamp",order:"desc"})
    const [filtering,setFiltering] = useState(false)
    const [searching,setSearching] = useState(false)
    const searchTimer = useRef(null)
    const fetchParams = useRef({fetching:true,fetched:false})
    const [tab,setTab] = useState("all")
    const [page,setPage] = useState(1)
    const [pageInput,setPageInput] = useState("1")
    const pageRef = useRef(null)
    const tableHeader = useRef(null)
    const tableBody = useRef(null)
    const _ = useFormatTable(tableHeader.current,tableBody.current)
    const cancelToken = useRef(null)


    const fetchUsers = useCallback(()=>{
        if (cancelToken.current) {
            cancelToken.current.cancel();
          }
        const source = axios.CancelToken.source();
        cancelToken.current = source;
        setFiltering(true)
        let url = `${process.env.REACT_APP_API_URL}/transactions/?search=${encodeURIComponent(searchValue)}`;
        if (tab !== "all") {
            url += `&transaction_type=${tab}`
        }
        url += `&sort_by=${sortParams.field}&sort_order=${sortParams.order}`
        axios.get(url,{cancelToken: source.token})
        .then((res)=>{
            setData(res.data)
            setPage(res.data.page)
            setFiltering(false)
        })
        .catch((err)=>{
            if (!axios.isCancel(err)) {
                setFiltering(false)
            }
            console.log("Error - ",err)
        })
    },[searchValue,sortParams,tab,setFiltering])

    useEffect(()=>{
        if (fetchParams.current?.fetching){
            fetchParams.current.fetching = false
            axios.get(`${process.env.REACT_APP_API_URL}/transactions`)
            .then((res)=>{
                fetchParams.current.fetched = true
                setData(res.data)
                setPage(res.data.page)
            })
        }
        if (fetchParams.current?.fetched){
            const debouncedFetch = debounce(fetchUsers, 300)
            debouncedFetch()
        }
    },[fetchUsers])



    useEffect(() => {
        const input = pageRef.current;
        if (input) {
          input.style.width = `${input.value.length}ch`; 
        }
      }, [page]);

    const handlePageChange = (e) => {
        let value = e.target.value
        setPage(value)
    }

    const formatDate = useCallback((date)=>{
        date = new Date()
        const day = date.getDate().toString().padStart(2, '0');
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day} ${month} ${year} - ${hours}:${minutes}`;
    },[])

    const setTableBody = useCallback((el)=>{
        if (el){
            if (el?.previousElementSibling){
                const header = el.previousElementSibling
                if (el.scrollHeight > el.clientHeight) {
                    const scrollbarWidth = el.offsetWidth - el.clientWidth;
                    header.style.paddingRight = `${scrollbarWidth}px`;
                }
                else{
                    header.style.paddingRight = '0px';
                }
            }
            tableBody.current = el
        }
    },[])

  return (
    <div className = {`${styles.wrapper} ${styles.transactions}`}>
        <NavBar page = "transactions"/>
        <div className = {styles.box}>
            <header>
                <div className = {styles.mainHeader}>
                    <div className = {styles.giftIcon}><IoGiftSharp/></div>
                    <div className = {styles.title}>Transactions</div>
                </div>
                <div className = {styles.playerBtn}>
                    Switch to player mode
                </div>
            </header>
            {!data ? <SkeletonPage/> : <main>
                <div className = {styles.categories}>
                    <div className = {`${styles.category} ${tab === "all" && styles.selected} ${(searching || filtering) && styles.searching}`} onClick = {()=>setTab("all")}>
                        <div className = {styles.categoryTitle}>All</div>
                        <div className = {styles.categoryLength}>{(!filtering && !searching) && data.num_transactions}</div>
                    </div>
                    <div className = {`${styles.category} ${tab === "paying" && styles.selected} ${(searching || filtering) && styles.searching}`} onClick = {()=>setTab("paying")}>
                        <div className = {styles.categoryTitle}>Paying</div>
                        <div className = {styles.categoryLength}>{(!filtering && !searching) && data.num_payments}</div>
                    </div>
                    <div className = {`${styles.category} ${tab === "bonus" && styles.selected} ${(searching || filtering) && styles.searching}`} onClick = {()=>setTab("bonus")}>
                        <div className = {styles.categoryTitle}>Bonus</div>
                        <div className = {styles.categoryLength}>{(!filtering && !searching) && data.num_bonuses}</div>
                    </div>
                    <div className = {`${styles.category} ${tab === "charge" && styles.selected} ${(searching || filtering) && styles.searching}`} onClick = {()=>setTab("charge")}>
                        <div className = {styles.categoryTitle}>Charge</div>
                        <div className = {styles.categoryLength}>{(!filtering && !searching) && data.num_charges}</div>
                    </div>
                </div>
                <div className = {styles.searchWrapper}>
                    <div className = {styles.searchBox}>
                        <div className = {styles.searchIcon}><IoIosSearch/></div>
                        <input type = "text" name = "searchValue" value = {(e)=>setSearchValue(e.target.value)} placeholder = "Search transactions by invoice id or user email"/>
                    </div>
                    <div className = {styles.btns}>
                        <div className = {styles.sortBtnWrapper} tabIndex = {-1} onBlur = {()=>setShowSort(false)}>
                            <div className = {styles.sortBtn} onClick = {()=>setShowSort(!showSort)}>
                                <div className = {styles.text}>Sort by</div>
                                <div className = {styles.arrow}><TiArrowSortedDown/></div>
                                <div className = {styles.sortIcon}><BiSortAlt2/></div>
                            </div>
                            {showSort && <div className = {styles.sortMenu}>
                                { tab === "paying" && <div className = {styles.sortItem} onClick = {()=>setSortParams({field:"amount",order:"desc"})}>Amount Paid<span>- Highest To Lowest</span></div>}
                                { tab === "paying" && <div className = {styles.sortItem} onClick = {()=>setSortParams({field:"amount",order:"asc"})}>Amount Paid<span>- Lowest To Highest</span></div>}
                                <div className = {styles.sortItem} onClick = {()=>setSortParams({field:"num_diamonds",order:"desc"})}>N&deg; Diamonds<span>- Highest To Lowest</span></div>
                                <div className = {styles.sortItem} onClick = {()=>setSortParams({field:"num_diamonds",order:"asc"})}>N&deg; Diamonds<span>- Lowest To Highest</span></div>
                                <div className = {styles.sortItem} onClick = {()=>setSortParams({field:"timestamp",order:"desc"})}>Date Executed <span>- Newest to Oldest</span></div>
                                <div className = {styles.sortItem} onClick = {()=>setSortParams({field:"timestamp",order:"asc"})}>Date Executed <span>- Oldest to Newest</span></div>
                            </div>}
                        </div>
                    </div>
                </div>
                {(searching || filtering) ? <SkeletonTable/> : <div className = {styles.content}>
                    <div className = {styles.table}>
                        <div className = {styles.tableContent}>
                            <div className = {styles.tableHeader} ref = {tableHeader}>
                                <div className = {`${styles.headerCol} ${styles.invoice}`}>Invoice ID</div>
                                <div className = {`${styles.headerCol} ${styles.type}`}>Type</div>
                                <div className = {`${styles.headerCol} ${styles.user}`}>User</div>
                                <div className = {`${styles.headerCol} ${styles.amount}`}>Amount</div>
                                <div className = {`${styles.headerCol} ${styles.num_diamonds}`}>N&deg; diamonds</div>
                                <div className = {`${styles.headerCol} ${styles.timestamp}`}>Timestamp</div>
                            </div>
                            <div className = {styles.tableBody} ref = {setTableBody}>
                                {data.items.map((transaction, index) => (
                                    <div className = {styles.row} key={index}>
                                        <div data-label = "Invoice ID" className = {`${styles.bodyCol} ${styles.invoice}`}>{transaction.transaction_id}</div>
                                        <div data-label = "Type" className = {`${styles.bodyCol} ${styles.type}`}>{transaction.transaction_type}</div>
                                        <div data-label = "User" className = {`${styles.bodyCol} ${styles.user}`}>{transaction.user}</div>
                                        <div data-label = "Amount" className = {`${styles.bodyCol} ${styles.amount}`}>{transaction.transaction_type === "paying" ? `€ ${transaction.amount}` : "N/A"}</div>
                                        <div data-label = "N&deg; diamonds" className = {`${styles.bodyCol} ${styles.num_diamonds}`}>{transaction.num_diamonds}</div>
                                        <div data-label = "Timestamp" className = {`${styles.bodyCol} ${styles.timestamp}`}>{formatDate(transaction.timestamp)}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className = {styles.tableFooter}>
                            <div className = {styles.displayedLength}>Showing {data.items.length} out of {data.total}</div>
                            <div className = {styles.pager}>
                                <div className = {`${styles.pageArrow} ${styles.prev}`}><MdKeyboardArrowLeft/></div>
                                <div className = {styles.pagerBox}>
                                    <input ref = {pageRef} type = "text" name = "page" value = {page} onChange = {handlePageChange}/>
                                    / {data.pages}
                                </div>
                                <div className = {`${styles.pageArrow} ${styles.next}`}><MdKeyboardArrowRight/></div>
                            </div> 
                        </div>
                    </div>
                </div>}
            </main>}
       </div>
    </div>
  )
}

const SkeletonTable = () => {
    return (
    <div className = {`${styles.content} ${styles.skeleton}`}>
        <div className = {styles.table}>
            <div className = {styles.tableContent}>
                <div className = {styles.tableHeader}>
                    <div className = {`${styles.headerCol} ${styles.invoice}`}>Invoice ID</div>
                    <div className = {`${styles.headerCol} ${styles.type}`}>Type</div>
                    <div className = {`${styles.headerCol} ${styles.user}`}>User</div>
                    <div className = {`${styles.headerCol} ${styles.amount}`}>Amount</div>
                    <div className = {`${styles.headerCol} ${styles.num_diamonds}`}>N&deg; diamonds</div>
                    <div className = {`${styles.headerCol} ${styles.timestamp}`}>Timestamp</div>
                </div>
                <div className = {styles.tableBody}>
                    {[...Array(7).keys()].map((_, index) => (
                        <div className = {styles.row} key={index}>
                            <div data-label = "Invoice ID" className = {`${styles.bodyCol} ${styles.invoice}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "Type" className = {`${styles.bodyCol} ${styles.type}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "User" className = {`${styles.bodyCol} ${styles.user}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "Amount" className = {`${styles.bodyCol} ${styles.amount}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "N&deg; diamonds" className = {`${styles.bodyCol} ${styles.num_diamonds}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                            <div data-label = "Timestamp" className = {`${styles.bodyCol} ${styles.timestamp}`}>
                                <div className = {styles.itemWrapper}>
                                    <div className = {styles.item}></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className = {styles.tableFooter}>
                <div className = {styles.skeletonLength}></div>
            </div>
        </div>
    </div>
    )
}

const SkeletonPage = () => {
    return(
        <main className = {styles.skeleton}>
            <div className = {styles.categories}>
                <div className = {styles.skeletonCategory}></div>
                <div className =  {styles.skeletonCategory}></div>
                <div className =  {styles.skeletonCategory}></div>
                <div className =  {styles.skeletonCategory}></div>
            </div>
            <div className = {styles.searchWrapper}>
                <div className = {styles.searchBox}></div>
                <div className = {styles.btns}>
                    <div className = {styles.skeletonSortBtn}></div>
                </div>
            </div>
            <SkeletonTable/>
        </main>
    )
}


export default Transactions