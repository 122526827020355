import React from 'react'
import styles from "../css/SelectedIntention.module.css"
import Ink from "../media/ink.png"

const SelectedIntention = ({active,intention,disabled,reselect,min= false}) => {

    const handleReselect = () => {
        if (!disabled){
            reselect()
        }
    }

  return (
    active || !intention.trim() ? 
    <div className = {`${styles.box} ${active && !intention.trim() ? styles.active : ""} ${min && styles.min}`}>
        <div className = {styles.contentWrapper}>
            <div className = {styles.content}>
                <div className = {styles.boxTitle}>Intention</div>
                <div className = {styles.penWrapper}>
                    <img src = {Ink} alt = ""/>
                </div>
            </div>
        </div>
    </div> : 
    <div className = {`${styles.box} ${styles.completed} ${min && styles.min}`} onClick = {()=>handleReselect()}>
        <div className = {styles.container}>
            <div className = {styles.innerContent}>
                <div className = {styles.front}>
                    <div className = {styles.content}>
                        <div className = {styles.boxTitle}>Intention</div>
                        <div className = {styles.intention}>{intention}</div>
                    </div>
                </div>
                    <div className = {styles.back}>
                    <div className = {styles.content}>
                        <div className = {styles.boxTitle}>Intention</div>
                        <div className = {styles.intention}>{intention}</div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
  )
}

export default SelectedIntention