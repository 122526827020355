import React, { useEffect, useRef, useState } from 'react'
import styles from "../css/UserMenu.module.css"
import { TbCreditCard } from "react-icons/tb";
import { TbSettings } from "react-icons/tb";
import { TbLogout } from "react-icons/tb";
import Settings from '../../settings/js/Settings';
import { getUserData } from '../../../utils/auth';

const UserMenu = ({close}) => {
    const [data,setData] = useState({...getUserData()})
    const [showSettings,setShowSettings] = useState(false)

    useEffect(()=>{
      const handleClick = (e) => {
        let menu = document.getElementById("menu-box")
        if (!showSettings && !menu.contains(e.target)){
          close()
        }

      }

      const handleStorage = (e) => {
        if (e.key === "user"){
          setData({...getUserData()})
        }
      }
      window.addEventListener("mousedown",handleClick)
      window.addEventListener("storage",handleStorage)
      return () => {
        window.removeEventListener("mousedown",handleClick)
        window.removeEventListener("storage",handleStorage)
      }
    },[showSettings,close,setData])

  return (
    <div className = {styles.menuWrapper}>
        {!showSettings ? <div className = {styles.menu}>
            <div className = {styles.header}>
                <div className = {styles.imageCircle} style = {{ backgroundImage: data.avatar ? `url(${process.env.REACT_APP_API_URL}/profileImages/${data.avatar})` : "none"}}>{!data.avatar && data.fullname?.charAt(0)}</div>
                <div className = {styles.userInfo}>
                    <div className = {styles.name}>{data.fullname}</div>
                    <div className = {styles.email}>{data.email}</div>
                </div>
            </div>
            <div className = {styles.content}>
                <div className = {styles.menuItem} onClick = {()=>setShowSettings(true)}>
                    <div className = {styles.menuIcon}><TbSettings/></div>
                    <div className = {styles.menuText}>Account Settings</div>
                </div>
                <div className = {styles.menuItem}>
                    <div className = {styles.menuIcon}><TbCreditCard/></div>
                    <div className = {styles.menuText}>Billing Details</div>
                </div>
                <hr/>
                <div className = {styles.menuItem}>
                    <div className = {styles.menuIcon}><TbLogout/></div>
                    <div className = {styles.menuText}>Log out</div>
                </div>
            </div>
        </div> : <Settings close = {()=>close()}/>}
    </div>
    )
}

export default UserMenu