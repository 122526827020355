import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/CardResults.module.css"
import LeftButterfly from "../media/left_butterfly.png"
import RightButterfly from "../media/right_butterfly.png"
import Border from "../media/resultsBorder.png"
import data from "../data/cardInfo.json"
import images from "../data/images.json"
import { BsWechat } from 'react-icons/bs'
import Stone from "../media/stone2.PNG"
import { IoClose } from 'react-icons/io5'

const CardResults = ({cards,currentStep,close}) => {
  const [step,setStep] = useState(currentStep)
  const boxRef = useRef(null)

  useEffect(()=>{
    const handleClick = (e) => {
        if (!boxRef.current?.contains(e.target)){
            close()
        }
    }
    window.addEventListener("mousedown",handleClick)
    return () => {
        window.removeEventListener("mousedown",handleClick)
    }
},[close])

  const cardIndex = useMemo(() => {
    return cards[step].id
  },[step,cards])

  return (
    <div className = {styles.wrapper} ref = {boxRef}>
      <div className = {styles.titleWrapper}>
        <div className = {styles.leftDec} style = {{ backgroundImage: `url(${LeftButterfly})` }}></div>
        <div className = {styles.titleBox}>Card Results</div>
        <div className = {styles.rightDec} style = {{ backgroundImage: `url(${RightButterfly})` }}></div>
       </div>
       <div className = {styles.container} style = {{ backgroundImage: `url(${Border})`}}>
            <div className = {`${styles.card} ${cards[step].is_reversed && styles.reversed}`} style = {{ backgroundImage: `url(${images[cardIndex]?.src})` }}>
            </div>
            <div className = {styles.content}>
              <div className = {styles.cardTitle}>{cards[step].is_reversed ? "Reversed " : ""}
                {!cards[step].is_reversed && <span>Card - </span>}
                {data[(cardIndex).toString()].dataLabel}
              </div>
              <div className = {styles.cardShortDesc}>{cards[step].is_reversed ? data[(cardIndex).toString()].reversedDescription.short : data[(cardIndex).toString()].uprightDescription.short}</div>
              <div className = {styles.cardLongDesc}>{cards[step].is_reversed ? data[(cardIndex).toString()].reversedDescription.long : data[(cardIndex).toString()].uprightDescription.long}</div>
            </div>
            <div className = {styles.stepper}>
              {[...Array(cards.length).keys()].map((_,index)=>(
                <div className = {`${styles.dot} ${step === index ? styles.active : ""}`} onClick = {()=>setStep(index)}></div>
              ))}
            </div>
       </div>
        {/* <div className = {styles.chatWrapper}>
          <div className = {styles.chatButton} style = {{ backgroundImage: `url(${Stone}`}} onClick = {()=>nextStep()}>
              <BsWechat/>
          </div>
          <div className = {styles.tooltip}>Chat with us!</div>
        </div> */}
        <div className = {styles.closeIcon} onClick = {()=>close()}><IoClose/></div>
    </div>
  )
}

export default CardResults