import React from 'react'
import styles from "../css/SelectionType.module.css"
import SelectedSpread from './SelectedSpread'
import SelectedIntention from './SelectedIntention'
const SelectionType = ({spread,intention,setType,spread_length = 5}) => {
  console.log("Intention = ",intention)
  return (
    <div className = {styles.wrapper}>
      <div className = {styles.box}>
        <div className = {styles.gameTitle}>Tarots</div>
        <div className = {styles.menuBtn}>Menu</div>
        <div className = {styles.spread}><SelectedSpread spread = {spread}/></div>
            <div className = {styles.intention}><SelectedIntention intention={intention}/></div>
        <div className = {styles.cards} length = {spread_length}>
            {[...Array(spread_length)].map((_,index)=>(<div className = {styles.card} key = {index}></div>))}
        </div>
        <div className = {styles.content}>
          <div className = {styles.instructions}>Decide if you wish to choose tarot cards by yourself, or if you’d like our tarotist to do that for you…</div>
          <div className = {styles.btns}>
            <button type = "button" className = {styles.btn} onClick = {()=>setType("manual")}>
                <div className = {styles.innerBtn}>Pick cards</div>
            </button>
            <button type = "button" className = {styles.btn} onClick = {()=>setType("automatic")}>
                <div className = {styles.innerBtn}>Tarotlist</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectionType