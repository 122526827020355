import React, { useState } from 'react'
import styles from "../css/Game.module.css"
import SpreadSelection from './SpreadSelection'
import IntentionText from './IntentionText'
import Frame from "../media/frame.png"
import CardSelection from './CardSelection'
import CardResults from './CardResults'
import Chat from './Chat'
import Background from './Background'
import Stacks from "../media/card_stack.png"
import Cards from './Cards'
import CardCatalog from './CardCatalog'
import SelectedSpread from './SelectedSpread'
import SelectedIntention from './SelectedIntention'
import LoadingGame from './LoadingGame'
import GameMenu from './GameMenu'
import api from '../../../utils/api'
import ExitModal from './ExitModal'
import RestartModal from './RestartModal'
import SavingData from './SavingData'
import GameType from './GameType'
import SelectionType from './SelectionType'
import Step5 from './Step5'

const Game = ({close, id = null}) => {
    const [data,setData] = useState({id:null,spread:null,intention:"",cards:[],interpertation:"",answered_at:null,questions:[]})
    const [intentionChecked,setIntentionChecked] = useState(false)
    const [gameStarted,setGameStarted] = useState(false)
    const [cardPopUp,setCardPopUp] = useState(null)
    const [step,setStep] = useState(1)
    const [cardsSelected,setCardsSelected] = useState(false)
    const [showExit,setShowExit] = useState(false)
    const [showRestart,setShowRestart] = useState(false)
    const [proccessingIntention,setProccessingIntention] = useState(false)
    const [type,setType] = useState("")

    const addCard = (card) => {
        setData((prevState)=>{
            let copy = [...prevState.cards]
            copy.push(card)
            return {...prevState,cards:copy}
            
        })
    }

    const restart = () => {
        setData({...data,spread:null,intention:"",cards:[],interpertation:"",answered_at:null,questions:[]})
        if ('intentions' in localStorage){
            localStorage.removeItem('intentions')
        }
        setCardsSelected(false)
        setStep(1)
        setShowRestart(false)
    }

    const newGame = (id) => {
        setData({id:id,spread:null,intention:"",cards:[],interpertation:"",answered_at:null,questions:[]})
        if ('intentions' in localStorage){
            localStorage.removeItem('intentions')
        }
        setCardsSelected(false)
        setStep(1) 
        setShowRestart(false)
    }

    const selectSpread = (new_data) => {
        console.log("new data = ",new_data)
        setData(new_data)
        setStep(2)
    }

    // const submitIntention = () => {
    //     if (!intentionChecked){
    //         let startTime = performance.now()
    //         setProccessingIntention(true)
    //         api.put(`/tarotGame/${data.id}/saveIntention`,{intention:data.intention})
    //         .then((_)=>{
    //             let endTime = performance.now()
    //             let duration = endTime - startTime
    //             setTimeout(()=>{
    //                 setProccessingIntention(false)
    //                 setIntentionChecked(true)
    //             },duration < 700 ? 700 - duration : 0)
    //             })
    //         .catch((err)=>{
    //             setProccessingIntention(false)
    //         })
    //     }
    //     setStep(3)
    // }

    const handleResume = (tarot_data) => {
        setData(tarot_data)
        if (tarot_data.interpertation){
            setStep(5)
        }
        else{
            setStep(4)
        }
        setCardsSelected(true)
        setGameStarted(true)
    }

  return (
    !gameStarted ? <LoadingGame id = {id} startGame={()=>setGameStarted(true)} setID = {(val)=>setData({...data,id:val})} handleResume = {(data)=>handleResume(data)} /> : 
    <div className = {styles.wrapper}>
            <div className = {styles.container}>
                {step === 1 && <GameType goNext = {()=>setStep(2)}/>}
                {step === 2 && <SpreadSelection game_id = {data.id} tarotData={data} handleTarotData={selectSpread} goNext = {()=>{console.log("NEXT");setStep(3)}}/>}
                {step === 3 && <IntentionText intention={data.intention} setIntention={(value)=>{setData({...data,intention:value});setStep(4)}} handleCheck = {()=>setIntentionChecked(false)}/>}
                {step === 4 && <SelectionType length = {data.spread.ordered_field_names.length} spread = {data.spread} intention = {data.intention} setType = {(value)=>{setType(value);setStep(5)}}/>}
                {step === 5 && <Step5 selection_type = {type} spread_length = {data.spread.ordered_field_names.length} spread = {data.spread} intention = {data.intention} setCards = {(value)=>setData({...data,cards:value})} goNext = {()=>setStep(6)}/>}
                {step === 6 && <Chat spread = {data.spread} intention = {data.intention} cards = {data.cards}/>}
                {/* {step === 4 && <Chat  game_id = {data.id} intention={data.intention} chatData = {[{question:data.intention,asked_at:new Date(),answer:data.interpertation,answered_at:data.answered_at},...data.questions]}/>} */}
                {/* {step === 5 && <CardResults cards = {data.cards} nextStep={()=>setStep(5)}/>} */}
                {/* {(step !== 5 && step !== 1) ? <div className = {`${styles.frame} ${step === 3 ? styles.third : ""} ${step === 4 ? styles.cardResults : ""}`}>
                    {step === 1 && <GameType goNext = {()=>setStep(2)}/>}
                    {step === 2 && <SpreadSelection game_id = {data.id} tarotData={data} handleTarotData={selectSpread}/>}
                    {step === 3 && <IntentionText intention={data.intention} setIntention={(value)=>setData({...data,intention:value})} handleCheck = {()=>setIntentionChecked(false)}/>}
                    {step === 4 && <CardSelection game_id = {data.id} spread_length={data.spread.ordered_field_names.length} startSelection = {()=>setCardsSelected(true)} addCard = {(card)=>addCard(card)} nextStep = {()=>setStep(4)}/>}
                    {step === 5 && <CardResults cards = {data.cards} nextStep={()=>setStep(5)}/>}
                </div> : (step === 1 ? <GameType/> : <Chat game_id = {data.id} intention={data.intention} chatData = {[{question:data.intention,asked_at:new Date(),answer:data.interpertation,answered_at:data.answered_at},...data.questions]}/>)}*/}
                {/* {step >= 4 && <SelectedSpread spread = {data.spread}  disabled = {cardsSelected} reselect = {()=>setStep(1)}/>}
                {step >= 4 && <SelectedIntention active = {step === 2} intention={data.intention} disabled = {cardsSelected} reselect = {()=>setStep(2)}/>} */}
                {/* {step >= 4 && <div className = {styles.stacks} onClick = {()=>submitIntention()}>
                <img src = {Stacks} alt = ""/>
                </div>} */}
                {/* {step === 5 && <Cards cards = {data.cards} card_length={data.spread.ordered_field_names.length}  showResult={(index)=>setCardPopUp(index)}/>}
                {cardPopUp !== null && <CardCatalog card_index={cardPopUp} cards = {data.cards} close = {()=>setCardPopUp(null)}/>} */}
                {/* <GameMenu restart = {()=>setShowRestart(true)} exit = {()=>setShowExit(true)} isComplete={step > 3}/> */}
                {showExit && <ExitModal game_id = {data.id} close = {()=>setShowExit(false)} exit = {()=>close()} isComplete={step > 3}/>}
                {showRestart && <RestartModal game_id = {data.id} restartGame={()=>restart()} newGame={(id)=>newGame(id)} exit = {()=>close()} isComplete={step > 3} close = {()=>setShowRestart(false)}/>}
                {proccessingIntention && <SavingData text = "Saving Written Intention"/>}
            </div>
    </div>
  )
}

export default Game