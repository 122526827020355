import React, { useEffect, useRef, useState } from 'react'
import styles from "../css/NavBar.module.css"
import { AiFillDollarCircle } from "react-icons/ai";
import { AiFillTags } from "react-icons/ai";
import { LiaUserCircle } from "react-icons/lia";
import { GiCardRandom } from "react-icons/gi";
import { IoMoon } from "react-icons/io5";
import { TbSettings } from "react-icons/tb";
import { IoGridOutline } from "react-icons/io5";
import { TbLogout } from "react-icons/tb";
import Logo from "../../auth/media/logo.png"
import { useNavigate } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import { RiBankFill } from 'react-icons/ri';
import { IoClose } from "react-icons/io5";

const NavBar = ({page}) => {
    const navigate = useNavigate()
    const [showNav,setShowNav] = useState(false)
    const [windowWidth,setWindowWidth] = useState(window.innerWidth)
    const navBar = useRef(null)

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
          if (window.innerWidth <= 600 && windowWidth > 600) {
            setShowNav(false);
          }
        };
        const handleClick = (e) => {
          if (window.innerWidth <= 600 && showNav && !navBar?.current?.contains(e.target)){
              setShowNav(false)
          }
        }
        window.addEventListener('resize', handleResize);
        window.addEventListener("mousedown",handleClick)
          return () => {
              window.removeEventListener('resize', handleResize);
              window.removeEventListener("mousedown",handleClick)
          }
      }, [windowWidth,showNav]);
  
  return (
    <div className = {`${styles.wrapper} ${!showNav && styles.hide}`}>
        <div className = {styles.box} ref = {navBar}>
            <div className = {styles.closeIcon} onClick = {()=>setShowNav(false)}><IoClose/></div>
            <header className = {page === "dashboard" && styles.dashboard}>
                <div className = {styles.icon}>
                    <img src = {Logo} alt = ""/>
                </div>
                <div className = {styles.appName}>Astroora</div>
            </header>
            <main>
                <div className = {`${styles.navItem} ${page === "dashboard" && styles.selected}`} onClick = {()=>navigate('/admin/dashboard')}>
                    <div className = {`${styles.navIcon} ${styles.dashboard}`}><IoGridOutline/></div>
                    <div className = {styles.navText}>Dashboard</div>
                </div>
                <div className = {`${styles.navItem} ${page === "transactions" && styles.selected}`} onClick = {()=>navigate('/admin/transactions')}>
                    <div className = {`${styles.navIcon} ${styles.bank}`}><RiBankFill/></div>
                    <div className = {styles.navText}>Transactions</div>
                </div>
                <div className = {`${styles.navItem} ${page === "pricing" && styles.selected}`} onClick = {()=>navigate('/admin/pricing')}>
                    <div className = {`${styles.navIcon} ${styles.pricing}`}><AiFillDollarCircle/></div>
                    <div className = {styles.navText}>Pricing</div>
                </div>
                <div className = {`${styles.navItem} ${page === "promocodes" && styles.selected}`} onClick = {()=>navigate('/admin/promocodes')}>
                    <div className = {`${styles.navIcon} ${styles.promo}`}><AiFillTags/></div>
                    <div className = {styles.navText}>Promo Codes</div>
                </div>
                <div className = {`${styles.navItem} ${page === "users" && styles.selected}`} onClick = {()=>navigate('/admin/users')}>
                    <div className = {`${styles.navIcon} ${styles.users}`}><LiaUserCircle/></div>
                    <div className = {styles.navText}>Users</div>
                </div>
                <div className = {`${styles.navItem} ${page === "dreams" && styles.selected}`} onClick = {()=>navigate('/admin/dreams')}>
                    <div className = {`${styles.navIcon} ${styles.dreams}`}><IoMoon/></div>
                    <div className = {styles.navText}>Dreams</div>
                </div>
                <div className = {`${styles.navItem} ${page === "tarots" && styles.selected}`} onClick = {()=>navigate('/admin/tarots')}>
                    <div className = {`${styles.navIcon} ${styles.tarots}`}><GiCardRandom/></div>
                    <div className = {styles.navText}>Tarots</div>
                </div>
            </main>
            <footer>
                <div className = {styles.navItem}>
                    <div className = {`${styles.navIcon} ${styles.settings}`}><TbSettings/></div>
                    <div className = {styles.navText}>Settings</div>
                </div>
                <div className = {styles.navItem}>
                    <div className = {`${styles.navIcon} ${styles.logout}`}><TbLogout/></div>
                    <div className = {styles.navText}>Sign out</div>
                </div>
            </footer>
        </div>
        <div className = {styles.navMenu}>
            <div className = {styles.menuIcon} onClick = {()=>setShowNav(!showNav)}><FiMenu/></div>
        </div>
    </div>
  )
}

export default NavBar