import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from "../css/DeleteModal.module.css"
import { IoClose } from 'react-icons/io5'
import { HiMiniCurrencyDollar } from "react-icons/hi2";
import { getName } from 'country-list';

const DeletePricing = ({id,closeModal}) => {
    const [data,setData] = useState(null)
    const [confirm,setConfirm] = useState("")
    const [loading,setLoading] = useState(false)
    const fetching = useRef(true)

    useEffect(()=>{
        if (fetching.current){
            fetching.current = false
            setTimeout(()=>{
                setData({
                    country: "US",
                    exchange_rate:2,
                    start_date:new Date(2024,4,3,12,45),
                    end_date:new Date(2024,9,25,17,3)
                })
            },500)
        }
    },[])

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
    }

    const formatDate = useCallback((date)=>{
        const day = date.getDate().toString().padStart(2, '0');
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day} ${month} ${year} - ${hours}:${minutes}`;
    },[])

  return (
    <div className = {styles.wrapper}>
        {data ? <form className = {styles.box} onSubmit = {handleSubmit}>
            <header>
                <div className = {styles.title}>Permanently Delete<span>&nbsp;-</span> <div className = {styles.currencyIcon}><HiMiniCurrencyDollar/></div> Currency</div>
                <div className = {styles.closeIcon} onClick = {()=>closeModal()}><IoClose/></div>
            </header>
            <main className = {styles.body}>
                <div className = {styles.text}>You are about to permanently delete the pricing currency for <span>{getName(data.country)}</span> effective from<span className = {styles.date}>&nbsp;{formatDate(data.start_date)}</span> to<span className = {styles.date}>&nbsp;{formatDate(data.end_date)}</span>&nbsp; with exchange rate of&nbsp;<span>{data.exchange_rate}</span>&nbsp;.You will not be able to recover this pricing currency. <span>This action cannot be undone</span></div>
                <label htmlFor = "code">Please Type &ldquo;CONFIRM&rdquo;</label>
                <input type = "text" name = "confirm" value = {confirm} onChange = {(e)=>setConfirm(e.target.value)}/>
            </main>
            <footer>
                {loading ? 
                <div className = {styles.loadingBtn}><div className = {styles.spinner}></div></div> :
                <button type = "submit" disabled = {confirm.toLowerCase().trim() !== "confirm"}>Delete Currency</button>
                }
            </footer>
        </form> : <div className = {styles.box}>
            <header>
                <div className = {styles.title}>Permanently Delete<span>&nbsp;-</span> <div className = {styles.giftIcon}><HiMiniCurrencyDollar/></div> Currency</div>
                <div className = {styles.closeIcon} onClick = {()=>closeModal()}><IoClose/></div>
            </header>
            <div className = {styles.loadingArea}>
                <div className = {styles.loadingSpinner}></div>
            </div>
        </div>}
    </div>
  )
}

export default DeletePricing