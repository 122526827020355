import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { NavLink  } from 'react-router-dom'
import validator from 'validator'
import styles from "../css/SignUpForm.module.css"
import { PiWarningCircleFill } from "react-icons/pi";
import { FaLocationDot } from "react-icons/fa6";
import { AiOutlineCheck } from "react-icons/ai";
import { TbMail } from "react-icons/tb";
import { TbLock } from "react-icons/tb";
import { FaCheck } from "react-icons/fa6";
import { TiArrowSortedDown } from "react-icons/ti";
import { TbLocationFilled } from "react-icons/tb";
import { BsGenderAmbiguous } from "react-icons/bs";
import { BiUser } from "react-icons/bi";
import EmailImage from "../media/emailImage.png"
import { getName } from 'country-list';
import { RiErrorWarningFill } from "react-icons/ri";
import Logo from "../media/logo.png"
import DarkLogo from "../media/darkLogo.png"
import AstroBackground from "../media/astrology_background.png"
import TarotLayout from "../media/tarot_layout.png"
import DreamLayout from "../media/dream_layout.png"

const monthDays = {
    0 : 31,
    1 : 29,
    2 : 31,
    3 : 30,
    4 : 31,
    5 : 30,
    6 : 31,
    7 : 31,
    8 : 30,
    9 : 31,
    10 : 30,
    11 : 31
}

const steps = [
    "Login Details",
    "Personal Info",
    "Email Verification"
]

const SignUpForm = () => {
    const [user,setUser]       = useState({email:"",password:"",confirm_password:"",fullname:"",gender:"",birthdate:{month:null,day:null,year:null},country:""})
    const [dropDown,setDropDown] = useState("")
    const [errors,setErrors]   = useState({email:"",password:"",confirm_password:"",submission:""})
    const [emailChecked,setEmailChecked] = useState("")
    const [loading,setLoading] = useState(false)
    const [locFetching,setLocFetching] = useState(false)
    const [nameFocused,setNameFocused] = useState(false)
    const [step,setStep] = useState(1)
    const menus = useRef({})


    useEffect(()=>{

        const handleClick = (e) => {
            if (dropDown){
                if (!menus.current[dropDown]?.contains(e.target)){
                    setDropDown("")
                }
            }
        }

        const handleResize = () => {
            if (dropDown){
                setDropDown("")
            }
        }
        window.addEventListener("mousedown",handleClick)
        window.addEventListener("resize",handleResize)
        return () => {
            window.removeEventListener("mousedown",handleClick)
            window.removeEventListener("resize",handleResize)
        }
    },[dropDown])


    const handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (name === "fullname"){
            if (value.length > 25){
                e.target.parentNode.classList.add(styles.shake)
                setTimeout(()=>{
                    e.target.parentNode.classList.remove(styles.shake)
                },1000)
                value = value.substring(0,25)
            }
        }
        setUser({...user,[name]:value})
        if (errors[name]){
            let copy = {...errors}
            delete copy[name]
            setErrors(copy)
        }
    }

    const formatDate = () => {
        let month = (user.birthdate.month + 1).toString().padStart(2,'0')
        let day = (user.birthdate.day + 1).toString().padStart(2,'0')
        return user.birthdate.year + '-' + month + '-' + day
    }

    const signUp = () => {
        setLoading(true)
        axios.post(process.env.REACT_APP_API_URL + '/register', {...user,birthdate: formatDate()})
        .then((_)=>{
            setStep(step+1)
            setLoading(false)
        })
        .catch((err)=>{
            if (err?.response?.status === 400){
                setErrors({...errors,...err.response.data})
            }
            else{
                setErrors({...errors,submission:"Unable to register now. Try later!"})
            }
            setLoading(false)
            setStep(1)

        })
    }

    const isDisabled = useCallback(() => {
        if (step === 1){
            return ["email","password","confirm_password"].some(key => errors[key] || key === "email" ? !user[key].trim : !user[key])
        }
        else{
            return ["fullname","gender","birthdate","country"].some(key => key !== "birthdate" ? !user[key].trim() : (user[key].month === null|| user[key].day === null || !user[key].year)) || locFetching
        }
    },[step,user,errors,locFetching])

    const goPrev = () => {
        setStep(step-1)
        if (errors.submission){
            setErrors((prevErrors)=> {
                let copy = {...prevErrors}
                delete copy.submission
                return copy
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (errors.submission){
            setErrors((prevErrors)=> {
                let copy = {...prevErrors}
                delete copy.submission
                return copy
            })
        }
        if (step === 1){
            let valid = true
            let recheck = errors.email || errors.password || errors.confirm_password
            //validating Form
            if (!validator.isEmail(user.email)){
                setErrors((prevErrors)=>{return {...prevErrors,email:"Invalid email address"}})
                valid = false
            }
            if (user.password.length < 8){
                setErrors((prevErrors)=>{return {...prevErrors,password:"At least 8 characters"}})
                valid = false
            }
            if (user.password !== user.confirm_password){
                setErrors((prevErrors)=>{return {...prevErrors,confirm_password:"Passwords do not match"}})
                valid = false
            }
            if ((user.email !== emailChecked) || recheck){
                setLoading(true)
                axios.get(`${process.env.REACT_APP_API_URL}/validate-email/${user.email}`)
                .then((_)=>{
                    setEmailChecked(user.email)
                    setLoading(false)
                    if (valid){
                        setStep(2)
                    }
                })
                .catch((err)=>{
                    setLoading(false)
                    if (err.response?.status === 400){
                        setErrors((prevErrors)=>{return {...prevErrors,email:"Email is already registered"}})
                    }
                    else{
                        setErrors((prevErrors)=>{return {...prevErrors,submission:"Something unexpected occured"}})
                    }
                })
            }
            else{
                if (valid){
                    setStep(2)
                }
            }
        }
        else{
            setLoading(true)
            signUp()
        }
    }

    const resendLink = () => {
        setLoading(true)
        if (errors.submission){
            setErrors((prevErrors)=> {
                let copy = {...prevErrors}
                delete copy.submission
                return copy
            })
        }
        axios.post(`${process.env.REACT_APP_API_URL}/resend-activation`,{"email":user.email})
        .then((_)=>{
            setLoading(false)
        })
        .catch((err)=>{
            setLoading(false)
            if (err.response?.status === 400){
                setStep(1)
            }
            if (err.response?.data?.email_status){
                setErrors({...errors,submission:err.response.data.email_status})
            }
            setErrors({...errors,submission:"Something went wrong. Try later"})

        })
    }

    const isStepCompleted = useCallback((step_num)=>{
        if (step_num === 1){
            return user.email && user.password && user.confirm_password
        }
        else{
            return user.fullname && user.gender && user.country && !Object.values(user.birthdate).some(value => value === null)
        }
    },[user])

    const days = useMemo(()=>{
        if (user.birthdate.month === null){
            return 31
        }
        else{
            let num_days
            if (user.birthdate.month === 1 && user.birthdate.year){
                num_days = new Date(user.birthdate.year, 2, 0).getDate()
            }
            else{
                num_days = monthDays[user.birthdate.month]
            }
            if (num_days < user.birthdate.day){
                setUser((prevData)=>{
                    let copy = {...prevData.birthdate}
                    copy.day = null
                    return {...prevData,birthdate:copy}
                })
            }

            return num_days
        }
    },[user.birthdate])

    const years = useMemo(()=>{
        let current_year = new Date().getFullYear()
        let start_year = current_year - 120
        return Array.from({ length: 121}, (_, index) => start_year + index).reverse()
    },[])

    const setMaxHeight = useCallback((el)=>{
        if (el){
            if (window.innerHeight - el.previousElementSibling.getBoundingClientRect().bottom < el.scrollHeight){
                if (el.previousElementSibling.getBoundingClientRect().top * 0.8 > (window.innerHeight - el.previousElementSibling.getBoundingClientRect().bottom)){
                    el.classList.add(styles.top)
                    el.style.maxHeight = `${el.previousElementSibling.getBoundingClientRect().top - 25 > 280 ? 280 : el.previousElementSibling.getBoundingClientRect().top - 25}px`
                    return
                }
            }
            el.style.maxHeight = `${window.innerHeight - el.previousElementSibling.getBoundingClientRect().bottom - 25}px`
        }
    },[])

    const setMenuRef = useCallback((el,key)=>{
        if (el){
            menus.current[key] = el
        }
    },[])

    const getLocation = () => {
        setLocFetching(true)
        axios.get('https://api.ipify.org?format=json')
        .then((res) => {
            axios.get(`${process.env.REACT_APP_API_URL}/get-location/${res.data.ip}`)
            .then((res)=>{
                setUser({...user,country:res.data})
                setLocFetching(false)
                if (errors.country){
                    let copy = {...errors}
                    delete copy.country
                    setErrors(copy)
                }
            })
            .catch((err)=>{
                setErrors({...errors,country:"Unable to get location"})
                setLocFetching(false)
            })
        })
        .catch((_)=>{
            setErrors({...errors,country:"Unable to get location"})
            setLocFetching(false)
        })
    }

    const selectGender = (gender) => {
        setUser((prevData)=>{
            return {...prevData,gender:gender}
        })
        setDropDown("")
    }

    const selectDate = (key,value) => {
        setUser({...user,birthdate:{...user.birthdate,[key]:value}})
        setDropDown("")
    }

  return (
    <div className = {styles.wrapper}>
        <div className = {styles.box}>
            <div className = {styles.leftSection}>
                <div className = {styles.appTitle}>
                    <div className = {styles.logo}>
                        <img src = {DarkLogo} alt = "/"></img>
                    </div>
                    <div className = {styles.name}>Astrooo</div>
                </div>
                <Slider/>
            </div>
            <div className = {styles.rightSection}>
                <div className = {styles.linkWrapper}>Already have an account? <NavLink to = "/login">Log in</NavLink></div>
                <div className = {styles.appTitle}>
                    <div className = {styles.logo}>
                        <img src = {Logo} alt = "/"></img>
                    </div>
                    <div className = {styles.name}>Astrooo</div>
                </div>
                <div className = {styles.contentWrapper}>
                    {step !== 3 ? <div className = {styles.content}>
                        <div className = {styles.title}>Create account</div>
                        <div className = {styles.stepper}>
                            {steps.map((stepName,index)=>(
                                <div className = {styles.stepBox} key = {index}>
                                    {(!isStepCompleted(index+1) || step === index+1) ? <div className = {`${styles.stepNum} ${step === index+1 && styles.current}`}>{index+1}</div> :
                                    <div className = {styles.check}><AiOutlineCheck/></div>}
                                    <div className = {styles.stepName}>{stepName}</div>
                                </div>
                            ))}
                        </div>
                        {errors.submission && <div className = {styles.warningBox}>
                            <div className = {styles.errorIcon}><RiErrorWarningFill/></div>
                            <div className = {styles.warningText}>{errors.submission}</div>
                        </div>}
                        {step !== 3 ?  <form onSubmit = {handleSubmit} autoComplete="off">
                            {step === 1 && <div className = {styles.inputs}>
                                <div className = {styles.inputContainer}>
                                    <label htmlFor = "email">Email address</label>
                                    {errors.email && <div className = {styles.errorBox}>{errors.email}</div>}
                                    <div className = {`${styles.inputBox} ${errors.email ? styles.error : ""}`}>
                                        <div className = {styles.innerInput}>
                                            <div className = {`${styles.inputIcon} ${styles.email} ${!user.email ? styles.emptyInput : ""}`}><TbMail/></div>
                                            <input type = "text" name = "email" placeholder = "example@email.com" value = {user.email} onChange = {handleChange} autoComplete="off"/>
                                            {errors.email && <div className = {styles.warningIcon}><PiWarningCircleFill/></div>}
                                        </div>
                                    </div>
                                </div>
                                <div className = {styles.inputContainer}>
                                    <label htmlFor = "password">Password</label>
                                    {errors.password && <div className = {styles.errorBox}>{errors.password}</div>}
                                    <div className = {`${styles.inputBox} ${errors.password ? styles.error : ""}`}>
                                        <div className = {styles.innerInput}>
                                            <div className = {`${styles.inputIcon} ${styles.password} ${!user.password ? styles.emptyInput : ""}`}><TbLock/></div>
                                            <input type = {user.password  ? "password" : "text"} name = "password" placeholder = "Minimum 8 characters" value = {user.password} onChange = {handleChange} autoComplete="off"/>
                                            {errors.password && <div className = {styles.warningIcon}><PiWarningCircleFill/></div>}
                                        </div>
                                    </div>
                                </div>
                                <div className = {`${styles.inputContainer} ${styles.fullname}`}>
                                    <label htmlFor = "confirm_password">Confirm Password</label>
                                    {errors.confirm_password && <div className = {styles.errorBox}>{errors.confirm_password}</div>}
                                    <div className = {`${styles.inputBox} ${errors.confirm_password ? styles.error : ""}`}>
                                        <div className = {styles.innerInput}>
                                            <div className = {`${styles.inputIcon} ${styles.password} ${!user.confirm_password ? styles.emptyInput : ""}`}><TbLock/></div>
                                            <input type = {user.confirm_password ? "password" : "text"} name = "confirm_password" placeholder = "Repeat your password" value = {user.confirm_password} onChange = {handleChange} autoComplete="off"/>
                                            {errors.confirm_password && <div className = {styles.warningIcon}><PiWarningCircleFill/></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {step === 2 && <div className = {styles.inputs}>
                            <div className = {styles.inputContainer}>
                                <label htmlFor = "fullname">Full name</label>
                                <div className = {styles.inputBox}>
                                    <div className = {styles.innerInput}>
                                        <div className = {`${styles.inputIcon} ${styles.fullname} ${!user.fullname ? styles.emptyInput : ""}`}> <BiUser/></div>
                                        <input type = "text" name = "fullname" placeholder = "Type your name here" value = {user.fullname} onChange = {handleChange} onFocus = {()=>setNameFocused(true)} onBlur = {()=>setNameFocused(false)} autoComplete="off"/>
                                    </div>
                                </div>
                                {nameFocused && <div className = {styles.lengthBox}>{user.fullname.length}/25</div>}
                                </div>
                                <div className = {styles.doubleWrapper}>
                                    <div className = {styles.inputContainer}>
                                        <label htmlFor = "gender">Gender</label>
                                        <div className = {styles.selectorWrapper} ref = {(el)=>setMenuRef(el,"gender")}>
                                            <div className = {`${styles.selectorBox} ${!user.gender && styles.emptyInput}`} onClick = {()=>setDropDown(dropDown === "gender" ? "" : "gender")}>
                                                <div className = {styles.selectorInput}>
                                                    <div className = {styles.genderIcon}><BsGenderAmbiguous/></div>
                                                    {user.gender ? user.gender : "Select Gender"}
                                                </div>
                                                <div className = {styles.arrow}><TiArrowSortedDown/></div>
                                            </div>
                                            {dropDown === "gender" && <div className = {styles.menu}>
                                                <div className = {styles.menuItem} onClick = {()=>selectGender("male")}>
                                                    <div className = {styles.menuCheck}>{user.gender === "male" && <FaCheck/>}</div>
                                                    <div className = {styles.menuValue}>Male</div>
                                                </div>
                                                <div className = {styles.menuItem} onClick = {()=>selectGender("female")}>
                                                    <div className = {styles.menuCheck}>{user.gender === "female" && <FaCheck/>}</div>
                                                    <div className = {styles.menuValue}>Female</div>
                                                </div>
                                                <div className = {styles.menuItem} onClick = {()=>selectGender("other")}>
                                                    <div className = {styles.menuCheck}>{user.gender === "other" && <FaCheck/>}</div>
                                                    <div className = {styles.menuValue}>Other</div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className = {styles.inputContainer}>
                                        <label htmlFor = "birthdate">Date of birth</label>
                                        <div className = {styles.dateInputs}>
                                            <div className = {styles.selectorWrapper}  ref = {(el)=>setMenuRef(el,"months")}>
                                                <div className = {`${styles.selectorBox} ${user.birthdate.month === null && styles.emptyInput}`} onClick = {()=>setDropDown(dropDown === "months" ? "" : "months")}>
                                                    {user.birthdate.month !== null ? <div className = {styles.selectorInput}>{(user.birthdate.month+1).toString().padStart(2, '0')}</div> : <div className = {styles.placeholder}>MM</div>}
                                                    <div className = {styles.arrow}><TiArrowSortedDown/></div>
                                                </div>
                                                {dropDown === "months" && <div className = {styles.menu} ref = {setMaxHeight}>
                                                    {[...Array(12).keys()].map((_,index)=>(
                                                        <div className = {styles.menuItem} key = {index} onClick = {()=>selectDate("month",index)}>{(index+1).toString().padStart(2, '0')}</div>
                                                    ))}
                                                </div>}
                                            </div>
                                            <div className = {styles.selectorWrapper} ref = {(el)=>setMenuRef(el,"days")}>
                                                <div className = {`${styles.selectorBox} ${user.birthdate.day === null && styles.emptyInput}`} onClick = {()=>setDropDown(dropDown === "days" ? "" : "days")}>
                                                    {user.birthdate.day != null ? <div className = {styles.selectorInput}>{(user.birthdate.day).toString().padStart(2, '0')}</div> : <div className = {styles.placeholder}>DD</div>}
                                                    <div className = {styles.arrow}><TiArrowSortedDown/></div>
                                                </div>
                                                {dropDown === "days" && <div className = {styles.menu} ref = {setMaxHeight}>
                                                    {[...Array(days).keys()].map((_,index)=>(
                                                        <div className = {styles.menuItem} key = {index} onClick = {()=>selectDate("day",index+1)}>{(index+1).toString().padStart(2, '0')}</div>
                                                    ))}
                                                </div>}
                                            </div>
                                            <div className = {styles.selectorWrapper} ref = {(el)=>setMenuRef(el,"years")}>
                                                <div className = {`${styles.selectorBox} ${!user.birthdate.year && styles.emptyInput}`} onClick = {()=>setDropDown(dropDown === "years" ? "" : "years")}>
                                                    {user.birthdate.year ? <div className = {styles.selectorInput}>{user.birthdate.year}</div> : <div className = {styles.placeholder}>YYYY</div>}
                                                    <div className = {styles.arrow}><TiArrowSortedDown/></div>
                                                </div>
                                                {dropDown === "years" && <div className = {styles.menu} ref = {setMaxHeight}>
                                                    {years.map((year,index)=>(
                                                        <div className = {styles.menuItem} key = {index} onClick = {()=>selectDate("year",year)}>{year}</div>
                                                    ))}
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className = {styles.inputContainer}>
                                    <label htmlFor = "country">Country</label>
                                    <div className = {`${styles.locationBox} ${!user.country && styles.emptyInput} ${errors.country && styles.errorBox}`} onClick = {()=>getLocation()}>
                                        <div className = {styles.selectorInput}>
                                            {errors.country ? <div className = {styles.locWarningIcon}><PiWarningCircleFill/></div> : <div className = {styles.locationIcon}><FaLocationDot/></div>}
                                            {errors.country ? errors.country : (user.country ? getName(user.country) : "Get Location")}
                                        </div>
                                        <div className = {styles.locationBtn}>{locFetching ? <div className = {styles.smallLoader}></div> : <TbLocationFilled/>}</div>
                                    </div>
                                </div>
                            </div>}
                            <div className = {`${styles.btns} ${step === 1 && styles.expandedBtn}`}>
                                {step !== 1 && <button type = "button" className = {styles.backBtn} onClick = {()=>goPrev()}>Back</button>}
                                {loading ? <div className = {styles.loadingBtn}><div className = {styles.loader}></div></div> : <button type = "submit" className = {styles.submitBtn} disabled = {isDisabled()}>{step !== 3 ? "Continue" : "Sign up"}</button>}
                            </div>
                        </form> :  <div></div>}
                    </div> : <div className = {`${styles.successBox} ${errors.submission && styles.error}`}>
                        <div className = {styles.mailImage}>
                            <img src = {EmailImage} alt = ""/>
                        </div>
                        <div className = {styles.verificationTitle}>Check your email address</div>
                        {errors.submission && <div className = {styles.warningBox}>
                            <div className = {styles.errorIcon}><RiErrorWarningFill/></div>
                            <div className = {styles.warningText}>{errors.submission}</div>
                        </div>}
                        <div className = {styles.subtext}>We sent a verification email to <b>{user.email}</b>. If you didn't receive an email yet, check your span or click on the resend button below. Please, note that the veritication link expires after 7 days</div>
                        <div className = {styles.buttons}>
                            <NavLink to = "/login" className = {styles.loginBtn}>Go to login</NavLink>
                            <button type = "button" className = {styles.resendBtn} onClick = {()=>resendLink()}>{loading ? <div className = {styles.loader}></div> : "Resend Link"}</button>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    </div>

  )
}

export const Slider = () => {
    const [step,setStep] = useState(1)
    const step_dict = {
        1 : {title:"Mystical journal",desc:"Combine your tarot readings, dream interpertations in a personalised journal"},
        2 : {title:"Tarot Readings",desc:"Get reliable insights about your life with Astrooo"},
        3 : {title:"Dream Interpretations",desc:"Unlock the internal meaning of your dreams with Astrooo"},
    }
    const timer = useRef(null)

    useEffect(()=>{
        timer.current = setInterval(()=>{
            setStep((prevStep)=>{
                return prevStep === 3 ? 1 : prevStep+1
            })
        },300000)
        return () => {
            clearInterval(timer.current)
        }
    },[step])

    const goToStep = (index) => {
        setStep(index+1)
    }
    return (
        <div className = {styles.imageSection}>
            <div className = {`${styles.repImage} ${styles[(step).toString()]}`}>
                {step === 1 && <img src = {AstroBackground} alt = ""/>}
                {step === 2 && <img src = {TarotLayout} alt = ""/>}
                {step === 3 && <img src = {DreamLayout} alt = ""/>}
            </div>
            <div className = {styles.imageTitle}>{step_dict[step].title}</div>
            <div className = {styles.imageDesc}>{step_dict[step].desc}</div>
            <div className = {styles.dotStepper}>
                {[...Array(3).keys()].map((_,index)=>(
                    <div className = {`${styles.dot} ${step === index+1 && styles.active}`} key = {index} onClick = {()=>goToStep(index)}></div>
                ))}
            </div>
        </div>
    )
}

const Background = ({children}) => {
    return (
        <div className = {styles.wrapper}>

        </div>
    )
}

export default SignUpForm