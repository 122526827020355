import React, { useMemo, useState } from 'react'
import styles from "../css/DatePicker.module.css"
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const days = ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"]

const DatePicker = ({selectedDate,selectDate,close}) => {
    const today = new Date()
    const [currentDate,setCurrentDate] = useState({month:!selectedDate ? today.getMonth() : selectedDate.getMonth() ,year:!selectedDate ? today.getFullYear() : selectedDate.getFullYear()})
    // const box = useRef(null)

    // useEffect(()=>{
    //   const handleClick = (e) => {
    //     if (!box.current.contains(e.target)){
    //       close()
    //     }

    //   }
    //   window.addEventListener("mousedown",handleClick)
    //   return () => {
    //     window.removeEventListener("mousedown",handleClick)
    //   }
    // },[close])

    const currentData = useMemo(()=>{
      const firstDateOfMonth = new Date(currentDate.year, currentDate.month , 1);
      const firstDayOfMonth = firstDateOfMonth.getDay() === 0 ? 7 : firstDateOfMonth.getDay();
      const startDate = new Date(firstDateOfMonth);
      startDate.setDate(startDate.getDate() - (firstDayOfMonth - 1));
      const lastDateOfMonth = new Date(currentDate.year, currentDate.month + 1, 0)
      const monthArray = []
      let currentValue = new Date(startDate)
      while (true) {
          monthArray.push(new Date(currentValue))
          if (currentValue.getDay() === 0 && lastDateOfMonth.getDate() === currentValue.getDate()){
            break
           }
          else if (currentValue.getDay() === 0 && currentDate.month !== currentValue.getMonth()){
            break
          }
          currentValue.setDate(currentValue.getDate() + 1);
      }
      let data = []
      for (let i = 0; i < monthArray.length; i += 7) {
        let chunk = monthArray.slice(i, i + 7);
        data.push(chunk);
      }


      return data
    },[currentDate])

    const goPrev = () => {
      if (currentDate.month === 0){
        setCurrentDate({month:11,year:currentDate.year - 1})
      }
      else{
        setCurrentDate({...currentDate, month:currentDate.month - 1})
      }
    }

    const goNext = () => {
      if (currentDate.month === 11){
        setCurrentDate({month:0,year:currentDate.year + 1})
      }
      else{
        setCurrentDate({...currentDate, month:currentDate.month + 1})
      }
    }

    const handleDate = (date) => {
      selectDate(date)
      close()
    }

    const isSelectedDate = (current_date) => {
      const selected_date = new Date(selectedDate)
      selected_date.setHours(0,0,0,0)
      const current_date_copy = new Date(current_date)
      current_date_copy.setHours(0,0,0,0)
      return selected_date.getTime() === current_date_copy.getTime()
    }
    
  return (
    <div className = {styles.wrapper}>
        <div className = {`${styles.box} ${currentData.length === 6 ? styles.min : ""}`}>
          <div className = {styles.closeIcon} onClick = {()=>close()}><IoMdClose/></div>
              <div className = {styles.titleBox}>When did you have this dream?</div>
              <div className = {styles.calendarContent}>
                <div className = {styles.calendarHeader}>
                  <div className = {`${styles.arrowIcon} ${styles.prev}`} onClick = {()=>goPrev()}><div className = {styles.innerIcon}><BiSolidLeftArrow/></div></div>
                  <div className = {styles.month}>{months[currentDate.month] + " " + currentDate.year}</div>
                  <div className = {`${styles.arrowIcon} ${styles.next}`} onClick = {()=>goNext()}><div className = {styles.innerIcon}><BiSolidRightArrow/></div></div>
                </div>
                <div className = {styles.dayRow}>
                  {days.map((day,index)=>(
                    <div className = {styles.dateText} key = {index}>{day}</div>
                  ))}
                </div>
                <div className = {styles.days}>
                {currentData.map((row, rowIndex) => (
                    <div className = {styles.row} key={rowIndex}>
                        {row.map((day, itemIndex) => (
                          <div className = {`${styles.dateBox} ${(new Date(day).getMonth() !== currentDate.month) ? styles.blur : ""} ${isSelectedDate(day) ? styles.selected : ""}`} key={itemIndex} onClick = {()=>handleDate(day)}>
                            <div className = {styles.dateInnerBox}>{new Date(day).getDate()}</div>
                          </div>
                        ))}
                    </div>
                ))}
                </div>
              </div>
            </div>
    </div>
  )
}

export default DatePicker