import React, { useState } from 'react'
import styles from "../css/RestartModal.module.css"
import api from '../../../utils/api'
import { IoClose } from 'react-icons/io5'

const RestartModal = ({game_id,isComplete,restartGame,newGame,close}) => {
  const [loading,setLoading] = useState(false)
  
  const restart = () => {
    if (!isComplete){
      setLoading(true)
      let startTime = performance.now()
      api.put(`/dreamGame/restart/${game_id}`)
      .then((_)=>{
          let endTime = performance.now()
          let duration = endTime - startTime
          setTimeout(()=>{
              setLoading(false)
              restartGame()
          },duration < 800 ? 800 - duration : 0)
      })
      .catch((err)=>{
        console.log("DELETION ERROR = ",err)
        setLoading(false)
      })
    }
    else{
      const startTime = performance.now()
      api.post(`/dreamGame/add`,{})
      .then((res)=>{
          let endTime = performance.now()
          let duration = endTime - startTime
          setTimeout(()=>{
              setLoading(false)
              newGame(res.data.id)
          },duration < 800 ? 800 - duration : 0)
      })
      .catch((err)=>{
        console.log("New Game Error = ",err)
        setLoading(false)
      })
    }
        
  }
  return (
    <div className = {styles.wrapper}>
      <div className = {styles.boxWrapper}>
        <div className = {styles.box}>
            <div className = {`${styles.title} ${isComplete && styles.complete}`}>{isComplete ? "New Game" : "Restart Game"}</div>
            <div className = {`${styles.content} ${isComplete && styles.complete}`}>{isComplete ? "Are you sure you want to exit this game and start a new game?" : "Are you sure you want restart this game and delete all your progress so far?"}</div>
            <div className = {`${styles.buttons} ${loading && styles.loading}`}>
                <button type = "button" onClick = {()=>restart()}>
                  {!loading ? <div className = {styles.btnText}>Yes</div> : <div className = {styles.loadingDots}>
                    <div className = {styles.dot}></div>
                    <div className = {styles.dot}></div>
                    <div className = {styles.dot}></div>
                  </div>}
                </button>
                <button type = "button" onClick = {()=>close()}><div className = {styles.btnText}>No</div></button>
            </div>
        </div>
        <div className = {styles.closeIcon} onClick = {()=>close()}><IoClose/></div>
      </div>
    </div>
  )
}

export default RestartModal