import React, { useEffect, useRef, useState } from 'react'
import styles from "../css/SortMenu.module.css"
import { MdOutlineEditCalendar } from "react-icons/md";
import { LuCalendarPlus } from "react-icons/lu";
import { BsQuestionDiamond } from "react-icons/bs";
import { IoArrowDownOutline } from "react-icons/io5";
import { IoMdArrowRoundUp } from "react-icons/io";
import { BsCheckLg } from "react-icons/bs";

const sort_fields = {
  created_at: {icon:<LuCalendarPlus/>,name:"Creation Date"},
  updated_at: {icon:<MdOutlineEditCalendar/>,name:"Update Date"},
  num_questions: {icon:<BsQuestionDiamond/>,name:"Number of questions"},
}

const SortMenu = ({sortValue,onChangeSortValue,close}) => {
  const [sortObject,setSortObject] = useState(Object.assign({},{...sortValue}))
  const menu = useRef(null)

  useEffect(()=>{

    const handleClick = (e) => {
        if (!menu.current.parentNode.contains(e.target)){
            close()
        }
    }
    window.addEventListener("mousedown",handleClick)
    return () => {
        window.removeEventListener("mousedown",handleClick)
    }
  },[close])

  const setOrder = (e,order) => {
    setSortObject((prevData)=>{
      let copy = {...prevData}
      copy.order = order
      return copy
    })
  }

  const selectField = (key) => {
    setSortObject((prevData)=>{
      let copy = {...prevData}
      copy.field = key
      return copy
    })
  }

  const reset = () => {
    onChangeSortValue({field:"updated_at",order:"-"})
    close()
  }

  const save = () => {
    onChangeSortValue(sortObject)
    close()
  }
  
  return (
    <div className = {styles.menu} ref = {menu}>
      <header>Sorting</header>
      <div className = {styles.content}>
      {Object.keys(sort_fields).map((key,index)=>(
        <div className = {`${styles.fieldItem} ${key} ${sortObject.field === key && styles.selected}`} onClick = {()=>selectField(key)} key = {index}>
            <div className = {styles.fieldRep}>
              <div className = {styles.check}>{sortObject.field === key && <BsCheckLg/>}</div>
              <div className = {styles.fieldIcon}>{sort_fields[key].icon}</div>
              <div className = {styles.fieldName}>{sort_fields[key].name}</div>
            </div>
            <div className = {styles.orderWrapper}>
              <div className = {`${styles.order} ${key} ${(sortObject.order === "-" && sortObject.field === key) && styles.selected}`} onClick = {(e)=>setOrder(e,"-")}><IoArrowDownOutline/></div>
              <div className = {`${styles.order} ${key} ${(sortObject.order === "+" && sortObject.field === key) && styles.selected}`} onClick = {(e)=>setOrder(e,"+")}><IoMdArrowRoundUp/></div>
            </div>
        </div>
      ))}
      </div>
      <footer>
        <button type = "button" className = {styles.resetBtn} onClick = {()=>reset()}>Reset</button>
        <button type = "button" className = {styles.saveBtn} onClick = {()=>save()}>Apply Sort</button>
      </footer>
    </div>
  )
}

export default SortMenu