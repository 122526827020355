import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/CountryDropDown.module.css"
import { getData } from 'country-list';
import ReactCountryFlag from 'react-country-flag';
import { IoFlag } from "react-icons/io5";

const CountryDropDown = ({closeMenu,selectedCountry,selectCountry,forBilling=false}) => {
    const [searchValue,setSearchValue] = useState("")
    const countries = getData()
    const menu = useRef(null)

    useEffect(()=>{
        const handleClick = (e) => {
            if (!menu.current?.contains(e.target) && !menu.current?.previousElementSibling?.contains(e.target)){
                closeMenu()
            }
        }
        const handleResize = () => {
            closeMenu()
        }
        window.addEventListener("resize",handleResize)
        window.addEventListener("mousedown",handleClick)
        return () => {
            window.removeEventListener("resize",handleResize)
            window.removeEventListener("mousedown",handleClick)
        }
    },[closeMenu])

    const results = useMemo(()=>{
        let query = searchValue.toLowerCase().replace(/\s+/g, '')
        return countries.filter(country => country.name.toLowerCase().replace(/\s+/g, '').includes(query))
    },[searchValue,countries])


    const setMenuPosition = useCallback((el)=>{
        if (el){
            el.style.maxHeight = ""
            let btnCoords = el.parentNode.getBoundingClientRect()
            let aboveHeight = btnCoords.top
            let belowHeight = window.innerHeight - btnCoords.top - btnCoords.height
            if (belowHeight > aboveHeight){
                el.style.top = `${btnCoords.bottom + 5}px`
                el.style.bottom = ""
                el.style.left = `${btnCoords.left}px`
                el.style.maxHeight = `${btnCoords.bottom - 10}px`
            }
            else{
                let bottomRect = window.innerHeight - btnCoords.top + 5
                el.style.bottom = `${bottomRect}px`
                el.style.top = ""
                el.style.left = `${btnCoords.left}px`
                el.style.maxHeight = `${window.innerHeight - bottomRect - 20}px` 
            }
            if (forBilling){
                el.style.width = `${btnCoords.width}px`
            }
            menu.current = el
        }
    },[])

    const setCountry = (code) => {
        selectCountry(code)
        closeMenu()
    }

  return (
    <div className = {styles.menu} ref = {setMenuPosition}>
        <div className = {styles.searchBox}>
            <input type = "text" value = {searchValue} onChange = {(e)=>setSearchValue(e.target.value)} placeholder = "Search country"/>
        </div>
        {results.length > 0 ? <div className = {styles.countries}>
            {results.map((country,index)=>(
                <div className = {`${styles.countryItem} ${selectedCountry === country.code && styles.selected}`} key = {index} onClick = {()=>setCountry(country.code)}>
                    <div className = {styles.flag}>
                        <ReactCountryFlag countryCode={country.code} svg />
                    </div>
                    <div className = {styles.name}>{country.name}</div>
                </div>
            ))}
        </div> : 
        <div className = {styles.emptyContent}>
            <div className = {styles.flagIcon}><IoFlag/></div>
            <div className = {styles.text}>No match was found</div>
        </div>}
    </div>
  )
}

export default CountryDropDown