import jwt_decode from "jwt-decode"

export const isExpired = (token) => {
    try{
        let decoded_token = jwt_decode(token)
        return decoded_token.exp * 1000 < new Date().getTime()
    }
    catch{
        return true
    }
}

export const checkLogin = () => {
    const tokens = JSON.parse(localStorage.getItem('tokens',null)) 
    let refresh_token = tokens ? tokens.refresh_token : ""
    return refresh_token && !isExpired(refresh_token)
}


export const setUserData = (data) => {
    let tokens = {access_token:data.access_token,refresh_token:data.refresh_token}
    let user   = {fullname:data.fullname,email:data.email,birthdate:data.birthdate,language:data.language,weekstart:data.weekstart}
    localStorage.setItem("tokens",JSON.stringify(tokens))
    localStorage.setItem("user",JSON.stringify(user))

    const e = new StorageEvent('storage', {
        storageArea: window.localStorage,
        key: "tokens",
        oldValue: "",
        newValue: JSON.stringify(tokens),
        url: window.location.href,
      });
      window.dispatchEvent(e);
}

export const getUserData = (data) => {
    let user   = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    return {
        fullname: user.fullname,
        email: user.email,
        avatar: user.avatar
    }
}


export const clearUserData = () => {
    const tokens = localStorage.getItem('tokens')
    localStorage.removeItem("tokens")
    localStorage.removeItem("user")
    const e = new StorageEvent('storage', {
        storageArea: window.localStorage,
        key: "tokens",
        oldValue: tokens,
        newValue: null,
        url: window.location.href,
      });
      window.dispatchEvent(e);  
}

export const updateUserData = (new_data) => {
    const old_data = JSON.parse(localStorage.getItem("user"))
    let updated_data = {...old_data,...new_data}
    localStorage.setItem("user",JSON.stringify(updated_data))
}