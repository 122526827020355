import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from "../css/Chat.module.css"
import { BsFillSendFill } from "react-icons/bs";
import Ribbon from "../media/chatBanner.png"
import { debounce } from "lodash"
import api from '../../../utils/api';
import ReactMarkdown from 'react-markdown';
import SelectedSpread from './SelectedSpread';
import SelectedIntention from './SelectedIntention';
import Images from "../data/images.json"
import TarotCards from "../data/cardInfo.json"
import CardResults from './CardResults';
// import Images from "../data/images.json"
const tarotCards = Object.values(TarotCards).sort(() => Math.random() - 0.5)

const cards_five = [{id:75,name:"Knight of Coins",is_reversed:false},{id:71,name:"Eight of Coins",is_reversed:true},{id:26,name:"Five of Wands",is_reversed:false},{id:18,name:"The Moon",is_reversed:true},{id:42,name:"Eight of Cups",is_reversed:false}]
const cards_three = [{id:75,name:"Knight of Coins",is_reversed:false},{id:71,name:"Eight of Coins",is_reversed:true},{id:26,name:"Five of Wands",is_reversed:false}] 
const Chat = ({game_id,chatData = [],intention,spread={name: "Health & Wellness",description: "Examines aspects of health and wellness."},cards = cards_five}) => {
  const [tarotQuestions,setTarotQuestions] = useState([...chatData])
  const [newQuestion,setNewQuestion] = useState("")
  const [focused,setFocused] = useState(false)
  const [overflow,setOverflow] = useState(false)
  const chatRef = useRef(null)
  const inputRef = useRef(null)
  const textRef = useRef(null)
  const loading = useRef(!chatData[0]?.answer)
  const [showCards,setShowCards] = useState(null)


  useEffect(()=>{
    if (loading.current){
      loading.current = false
      // api.put(`/tarotGame/${game_id}/getInterpertation`)
      // .then((res)=>{
      //   setTarotQuestions((prevData)=>{
      //     let data = [...prevData]
      //     data[0].answer = res.data.interpertation
      //     data[0].answered_at = res.data.answered_at
      //     return data
      //   })
      // })
    }
  },[game_id])

  const handleResize = useCallback(
    debounce(()=>{
      textRef.current.style.height = "auto";
      const scrollHeight = textRef.current.scrollHeight
      textRef.current.style.height = scrollHeight + "px";
      if (textRef.current.scrollHeight > textRef.current.offsetHeight){
        textRef.current.style.height = textRef.current.scrollHeight + "px";
      }
    },60)
,[])

  useEffect(() => {
    const element = chatRef?.current;

    if (!element) return;

    const observer = new ResizeObserver(() => {

      if (element.scrollHeight > element.offsetHeight){
        setOverflow(true)
      }
      else{
        setOverflow(false)
      }
    });

    observer.observe(element);
    window.addEventListener("resize",handleResize)
    return () => {
      observer.disconnect();
      window.removeEventListener("resize",handleResize)
    };

  }, [])

  useEffect(() => {
    // Scroll to the bottom of the chat box whenever a sent message is added
    if (tarotQuestions.length){
      const lastMessage = tarotQuestions[tarotQuestions.length - 1];
      if (lastMessage.question && !lastMessage.answer) {
        chatRef.current.scrollTop = chatRef.current.scrollHeight
      }
    }
  }, [tarotQuestions]);


  const send = () => {
    if (!newQuestion?.trim()?.length){
      inputRef.current.classList.add(styles.shake)
      setTimeout(()=>{
        inputRef.current.classList.remove(styles.shake)
      },350)
      return
    }
    let asking_time = new Date()
    setTarotQuestions([...tarotQuestions,{question:newQuestion,answer:"",asked_at:asking_time}])
    api.post(`/tarotGame/${game_id}/question`,{question:newQuestion,asked_at:asking_time})
    .then((res)=>{
      setTarotQuestions((prevData)=>{
        let data = [...prevData]
        let index = data.length - 1
        data[index].answer = res.data.answer
        data[index].answered_at = res.data.answered_at
        return data
      })
    })
    setNewQuestion("")
    textRef.current.style.height = "auto";
  }

  const timestamp = useCallback((timestamp) => {
      let am_pm   = timestamp.getHours() >= 12 ? 'PM' : 'AM';
      let hours   = timestamp.getHours()  % 12;
      hours       = hours ? hours : 12;
      let minutes = timestamp.getMinutes()
      minutes     = minutes < 10 ? '0'+ minutes : minutes
      return hours + ":" + minutes + " " + am_pm
  },[])

  const handleChange = (e) => {
    let value = e.target.value
    if (value.length > 270){
      inputRef.current.classList.add(styles.shake)
      setTimeout(()=>{
        inputRef.current.classList.remove(styles.shake)
      },850)
    }
    value = value.substring(0,270)
    e.target.value = value

   e.target.style.height = "auto";
    const scrollHeight = e.target.scrollHeight
    e.target.style.height = scrollHeight + "px";
    if (e.target.scrollHeight > e.target.offsetHeight){
      e.target.style.height = e.target.scrollHeight + "px";
    }

    let textHeight = e.target.offsetHeight
    let lineHeight = parseFloat(window.getComputedStyle(e.target).lineHeight.replace("px",""))
    let lines = Math.floor(textHeight/lineHeight)
    if (lines > 1){
      inputRef.current.classList.add(styles.multiline)
    }
    else{
      inputRef.current.classList.remove(styles.multiline)
    }

    setNewQuestion(value)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter'){
      e.preventDefault()
      send()
    }
  }

  return (
    <div className = {styles.wrapper}>
      <div className = {styles.box}>
        <div className = {styles.leftArea}>
          <div className = {styles.titleWrapper} style = {{ backgroundImage: `url(${Ribbon})`}}>Tarot Chat</div>
          <div className = {styles.container}>
            <div className = {styles.chatBox}>
              <div className = {styles.innerBox}>
              <div className = {styles.chatContent} ref = {chatRef}>
                {tarotQuestions.map((followup,index)=>(
                  <div className = {styles.textWrapper} key = {index}>
                    <div className = {styles.userWrapper}>
                      <div className = {styles.userBox}>
                        <div className = {styles.timestamp}>{timestamp(new Date(followup.asked_at))}</div>
                        <div className = {styles.text}>{followup.question}</div>
                      </div>
                    </div>
                    {followup.answer ? <div className = {styles.appWrapper}>
                      <div className = {styles.appBox}>
                        <div className = {styles.timestamp}>{timestamp(new Date(followup.answered_at))}</div>
                        <div className = {styles.text}><ReactMarkdown>{followup.answer}</ReactMarkdown></div>
                      </div>
                    </div> :
                    <div className = {styles.loadingDots}>
                      <div className = {styles.dot}></div>
                      <div className = {styles.dot}></div>
                      <div className = {styles.dot}></div>
                    </div>}
                  </div>
                ))}
              </div>
              {overflow && <div className = {styles.seperator}></div>}
              {( focused && newQuestion.trim()) && <div className = {styles.characterLength}>{newQuestion.length}/270</div>}
              <div className = {`${styles.typerWrapper} ${overflow ? styles.overflowed : ""}`} ref = {inputRef}>
                <div className = {styles.textarea}>
                  <textarea ref = {textRef} rows = {1} name = "newQuestion" placeholder='Type your intention here' value = {newQuestion} onFocus = {()=>setFocused(true)} onChange = {handleChange} onKeyDown={handleKeyDown} onBlur = {()=>setFocused(false)}/>
                </div>
                <div className = {styles.sendBtn} onClick = {()=>send()}><BsFillSendFill /></div>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div className = {styles.rightArea}>
          <div className = {styles.gameTitle}>Tarots</div>
          <div className = {styles.menuBtn}>Menu</div>
          <div className = {styles.spread}><SelectedSpread spread = {spread} min = {true}/></div>
            <div className = {styles.intention}><SelectedIntention intention= {intention} min = {true}/></div>
            <div className = {styles.cards} length = {cards.length.toString()}>
              {cards.map((card,index)=>(
                <div className = {`${styles.card} ${card.is_reversed && styles.reversed}`} key = {index} onClick = {()=>setShowCards(index)}>
                  <img src = {Images[card.id].src} alt = ""/>
                  <div className = {styles.cardName}>{tarotCards[card.id].dataLabel}</div>
                </div>
              ))}
            </div>
        </div>
      </div>
      {showCards !== null && <CardResults cards = {cards} currentStep = {showCards} close = {()=>setShowCards(null)}/>}
    </div>
      /* <div className = {styles.titleWrapper} style = {{ backgroundImage: `url(${Ribbon})`}}>Tarot Reading</div>
        <div className = {styles.container}>
          <div className = {styles.box}>
            <div className = {styles.chatContent} ref = {chatRef}>
              {tarotQuestions.map((followup,index)=>(
                <div className = {styles.textWrapper} key = {index}>
                  <div className = {styles.userWrapper}>
                    <div className = {styles.userBox}>
                      <div className = {styles.timestamp}>{timestamp(new Date(followup.asked_at))}</div>
                      <div className = {styles.text}>{followup.question}</div>
                    </div>
                  </div>
                  {followup.answer ? <div className = {styles.appWrapper}>
                    <div className = {styles.appBox}>
                      <div className = {styles.timestamp}>{timestamp(new Date(followup.answered_at))}</div>
                      <div className = {styles.text}><ReactMarkdown>{followup.answer}</ReactMarkdown></div>
                    </div>
                  </div> :
                  <div className = {styles.loadingDots}>
                    <div className = {styles.dot}></div>
                    <div className = {styles.dot}></div>
                    <div className = {styles.dot}></div>
                  </div>}
                </div>
              ))}
            </div>
            {overflow && <div className = {styles.seperator}></div>}
            {( focused && newQuestion.trim()) && <div className = {styles.characterLength}>{newQuestion.length}/270</div>}
            <div className = {`${styles.typerWrapper} ${overflow ? styles.overflowed : ""}`} ref = {inputRef}>
              <div className = {styles.textarea}>
                <textarea ref = {textRef} rows = {1} name = "newQuestion" placeholder='Type your intention here' value = {newQuestion} onFocus = {()=>setFocused(true)} onChange = {handleChange} onKeyDown={handleKeyDown} onBlur = {()=>setFocused(false)}/>
              </div>
              <div className = {styles.sendBtn} onClick = {()=>send()}><BsFillSendFill /></div>
            </div>

              </div> */
  )
}

export default Chat