import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/Profile.module.css"
import { TiArrowSortedDown } from "react-icons/ti";
import { TfiUser } from "react-icons/tfi";
import { RiEdit2Fill } from "react-icons/ri";
import { BsCheckLg } from "react-icons/bs";
import { getName } from 'country-list';
import api from '../../../utils/api';
import { updateUserData } from '../../../utils/auth';

const monthDays = {
    0 : 31,
    1 : 29,
    2 : 31,
    3 : 30,
    4 : 31,
    5 : 30,
    6 : 31,
    7 : 31,
    8 : 30,
    9 : 31,
    10 : 30,
    11 : 31
}
const months_short = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const Profile = () => {
    const [oldData,setOldData] = useState(null)
    const [newData,setNewData] = useState(null)
    const fetching = useRef(true)
    const [showMenu,setShowMenu] = useState(0)
    const dateMenus = useRef({})
    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        if (fetching.current){
            fetching.current = false
            api.get('/profile/get-details')
            .then((res)=>{
                let birthdate = new Date(res.data.birthdate)
                let data = {
                    ...res.data,
                    birthdate: {day: birthdate.getDate(), month: birthdate.getMonth(), year: birthdate.getFullYear()},
                    country: getName(res.data.country)
                }
                setOldData(data)
                setNewData(Object.assign({},{...data}))
            })
            .catch((err)=>{
                console.log("Error = ",err)
            })
        }

        const handleClick = (e) => {
            if (showMenu){
                if (!dateMenus.current[showMenu]?.contains(e.target)){
                    setShowMenu("")
                }
            }
        }

        const handleResize = () => {
            if (showMenu){
                setShowMenu("")
            }
        }
        window.addEventListener("mousedown",handleClick)
        window.addEventListener("resize",handleResize)
        return () => {
            window.removeEventListener("mousedown",handleClick)
            window.removeEventListener("resize",handleResize)
        }
    },[showMenu])

    const days = useMemo(()=>{
        if (newData?.birthdate){
            if (newData.birthdate.month === null){
                return 31
            }
            else{
                let num_days
                if (newData.birthdate.month === 1 && newData.birthdate.year){
                    num_days = new Date(newData.birthdate.year, 2, 0).getDate()
                }
                else{
                    num_days = monthDays[newData.birthdate.month]
                }
                if (num_days < newData.birthdate.day){
                    setNewData((prevData)=>{
                        let copy = {...prevData.birthdate}
                        copy.day = null
                        return {...prevData,birthdate:copy}
                    })
                }
    
                return num_days
            }
        }
    },[newData?.birthdate])

    const years = useMemo(()=>{
        let current_year = new Date().getFullYear()
        let start_year = current_year - 120
        return Array.from({ length: 121}, (_, index) => start_year + index).reverse()
    },[])

    const setMenuRef = useCallback((el,key)=>{
        if (el){
            dateMenus.current[key] = el
        }
    },[])

    const setMaxHeight = useCallback((el)=>{
        if (el){
            if (window.innerHeight - el.previousElementSibling.getBoundingClientRect().bottom < el.scrollHeight){
                if (el.previousElementSibling.getBoundingClientRect().top * 0.8 > (window.innerHeight - el.previousElementSibling.getBoundingClientRect().bottom)){
                    el.classList.add(styles.top)
                    el.style.maxHeight = `${el.previousElementSibling.getBoundingClientRect().top - 25 > 280 ? 280 : el.previousElementSibling.getBoundingClientRect().top - 25}px`
                    return
                }
            }
            el.style.maxHeight = `${window.innerHeight - el.previousElementSibling.getBoundingClientRect().bottom - 25}px`
        }
    },[])

    const setProfileImage = (e) =>{
        let image = e.target.files[0]
        setNewData({...newData,avatar:image})
        e.target.value = ""
    }

    const avatarSrc = useMemo(() => {
        if (newData?.avatar){
            if (newData.avatar !== oldData.avatar){
                return URL.createObjectURL(newData.avatar)
            }
            else{
                return `${process.env.REACT_APP_API_URL}/profileImages/${oldData.avatar}`
            }
        }
        else{
            return null
        }
    },[newData?.avatar,oldData?.avatar])

    const modifyDate = (key,value) => {
        setNewData({...newData,birthdate:{...newData.birthdate,[key]:value}})
        setShowMenu("")
    }

    const selectGender = (gender) => {
        setNewData({...newData,gender:gender})
        setShowMenu("")
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let formData = new FormData()
        if (newData.fullname !== oldData.fullname){
            formData.append('fullname',newData.fullname)
        }
        if (newData.gender !== oldData.gender){
            formData.append('gender',newData.gender)
        }
        if (JSON.stringify(newData.birthdate) !== JSON.stringify(oldData.birthdate)){
            let month = (newData.birthdate.month + 1).toString().padStart(2,'0')
            let day = (newData.birthdate.day + 1).toString().padStart(2,'0')
            let formattedDate = newData.birthdate.year + '-' + month + '-' + day
            formData.append('birthdate',formattedDate)
        }


        if (newData.avatar !== oldData.avatar){
            if (!newData.avatar){
                formData.append('avatar','')
                formData.append('avatar_removed',true)
            }
            else{
                formData.append('avatar',newData.avatar)
            }
        }
        setLoading(true)
        api.put('/profile/edit',formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res)=>{
            let birthdate = new Date(res.data.birthdate)
            let data = {
                ...res.data,
                birthdate: {day: birthdate.getDate(), month: birthdate.getMonth(), year: birthdate.getFullYear()},
                country: getName(res.data.country)
            }
            setOldData(data)
            setNewData(Object.assign({},{...data}))
            updateUserData({avatar:res.data.avatar,fullname:res.data.fullname,email:res.data.email})
        })
        .catch((err)=>{
            console.log("Error = ",err)
        })
        .finally(()=>{
            setLoading(false)
        })
    }


  return (
    newData ? <form className = {styles.box} onSubmit={handleSubmit}>
        <div className = {styles.content}>
            <div className = {styles.section}>
                <div className = {styles.label}>Profile Picture</div>
                <div className = {styles.photoWrapper}>
                    {!newData.avatar ?
                        <div className = {styles.photoBox}>
                            <label htmlFor='avatar'><TfiUser/></label>
                            <input type = "file" id = "avatar" onChange={(e)=>setProfileImage(e)} accept="image/*"/>
                        </div> : 
                        <div className = {styles.photo}>
                            <img src = {avatarSrc} alt = ""/>
                            <label htmlFor='avatar'><RiEdit2Fill/></label>
                            <input type = "file" id = "avatar" onChange={(e)=>setProfileImage(e)} accept="image/*"/>
                        </div>
                    }
                    {newData.avatar && <div className = {styles.removeWrapper}><div className = {styles.removeBtn} onClick = {()=>setNewData({...newData,avatar:null})}>Remove Picture</div></div>}
                </div>
            </div>
            <div className = {styles.doubleWrapper}>
                <div className = {styles.section}>
                    <label htmlFor='fullname'>Full name</label>
                    <input type = "text" value = {newData.fullname} name = "fullname" onChange = {(e)=>setNewData({...newData,fullname:e.target.value})}/>
                </div>
                <div className = {styles.section}>
                    <label htmlFor='email'>Email</label>
                    <input type = "text" value = {newData.email} name = "email" disabled/>
                </div>
            </div>
            <div className = {styles.doubleWrapper}>
                <div className = {styles.section}>
                    <label htmlFor='gender'>Gender</label>
                    <div className = {`${styles.selectorWrapper} ${styles.gender}`} ref = {(el)=>setMenuRef(el,"gender")}>
                        <div className = {styles.selectorInput} onClick = {()=>setShowMenu( showMenu === "gender" ? "" : "gender")}>
                            <div className = {styles.value}>{newData.gender}</div>
                            <div className = {styles.arrow}><TiArrowSortedDown/></div>
                        </div>
                        {showMenu === "gender" && <div className = {styles.dropDown} ref = {setMaxHeight}>
                            {["male","female","other"].map((gender,index)=>(
                                <div className = {styles.genderBox} key = {index} onClick = {()=>selectGender(gender)}>
                                    <div className = {styles.genderCheck}>{gender === newData.gender && <BsCheckLg/>}</div>
                                    <div className = {styles.genderText}>{gender}</div>
                                </div>
                            ))}
                        </div>}
                    </div>
                </div>
                <div className = {styles.section}>
                    <label htmlFor='email'>Country</label>
                    <input type = "text" value = {newData.country} name = "country" disabled/>
                </div>
            </div>
            <div className = {styles.doubleWrapper}>
                <div className = {styles.section}>
                    <label htmlFor='birthdate'>Date of Birth</label>
                    <div className = {styles.dateBoxes}>
                        <div className = {styles.selectorWrapper} ref = {(el)=>setMenuRef(el,"months")}>
                            <div className = {styles.selectorInput} onClick = {()=>setShowMenu( showMenu === "months" ? "" : "months")}>
                                {newData.birthdate.month !== null ? <div className = {styles.value}>{months[newData.birthdate.month]}</div> : <div className = {styles.placeholder}>month</div>}
                                <div className = {styles.arrow}><TiArrowSortedDown/></div>
                            </div>
                            {showMenu === "months" && <div className = {styles.dropDown} ref = {setMaxHeight}>
                                {months.map((month,index)=>(
                                    <div className = {styles.month} key = {index} onClick ={()=>modifyDate("month",index)}>{month}</div>
                                ))}
                            </div>}
                        </div>
                        <div className = {styles.selectorWrapper} ref = {(el)=>setMenuRef(el,"days")}>
                            <div className = {styles.selectorInput} onClick = {()=>setShowMenu( showMenu === "days" ? "" : "days")}>
                                {newData.birthdate.day ? <div className = {styles.value}>{String(newData.birthdate.day).padStart(2, '0')}</div> : <div className = {styles.placeholder}>dd</div>}
                                <div className = {styles.arrow}><TiArrowSortedDown/></div>
                            </div>
                            {showMenu === "days" && <div className = {styles.dropDown} ref = {setMaxHeight}>
                                {[...Array(days).keys()].map((_,index)=>(
                                    <div className = {styles.day} key = {index} onClick ={()=>modifyDate("day",index+1)}>{(index+1).toString().padStart(2, '0')}</div>
                                ))}
                            </div>}
                        </div>
                        <div className = {styles.selectorWrapper} ref = {(el)=>setMenuRef(el,"years")}>
                            <div className = {styles.selectorInput} onClick = {()=>setShowMenu( showMenu === "years" ? "" : "years")}>
                                {newData.birthdate.year ? <div className = {styles.value}>{newData.birthdate.year}</div> : <div className = {styles.placeholder}>yyyy</div>}
                                <div className = {styles.arrow}><TiArrowSortedDown/></div>
                            </div>
                            {showMenu === "years" && <div className = {styles.dropDown} ref = {setMaxHeight}>
                                {years.map((year,index)=>(
                                    <div className = {styles.year} key = {index} onClick ={()=>modifyDate("year",year)}>{year}</div>
                                ))}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                {loading ? <div className = {styles.loadingBtn}><div className = {styles.loader}></div></div> : <button type = "submit" className = {styles.saveBtn} disabled = {JSON.stringify(oldData) === JSON.stringify(newData)}>Save Changes</button>}
            </footer>
        </div>
    </form> : <div className = {styles.fetchingLoader}><div className = {styles.spinner}></div></div>
  )
}

export default Profile