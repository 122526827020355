import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate, } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import ActivatePage from './pages/ActivatePage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import { useEffect, useState } from 'react';
import { checkLogin } from './utils/auth';
import DashboardPage from './pages/DashboardPage';
import DreamPage from './pages/DreamPage';
import TarotPage from './pages/TarotPage';
import Landing from './components/LandingPage/LandingPage';
import { default as AdminLoginPage} from "./components/admin/js/LoginPage"
import { default as AdminDashboard} from "./components/admin/js/Dashboard"
import Users from './components/admin/js/Users';
import Dreams from './components/admin/js/Dreams';
import Tarots from './components/admin/js/Tarots';
import PromoCodes from './components/admin/js/PromoCode';
import Pricing from './components/admin/js/Pricing';
import Transactions from './components/admin/js/Transactions';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    const [isLoggedIn,setIsLoggedIn] = useState(checkLogin())

    useEffect(()=>{
        const handleStorage = (e) => {
            if (e.key === "tokens"){
                let login_status = checkLogin()
                setIsLoggedIn(login_status)
            }
        }
        window.addEventListener('storage',handleStorage)
        return () => {
            window.removeEventListener("storage",handleStorage)
        }
    },[])

    const ProtectedRoute = ({children}) => {
        return (
            isLoggedIn ? children : <Navigate to = "/login" replace = {true}/>
        )
    }

    const PublicRoute = ({children}) => {
        return (
            !isLoggedIn ? children : <Navigate to = "/dashboard" replace = {true}/>
        )
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Landing/>} />
                <Route path="/login" element={<PublicRoute><LoginPage/></PublicRoute>} />
                <Route path="/signup" element={<PublicRoute><SignUpPage/></PublicRoute>} />
                <Route path="/forgot-password" element={<ForgotPasswordPage/>} />
                <Route path="/reset-password" element = {<ResetPasswordPage/>}/>
                <Route path = "/activate" element = {<ActivatePage/>}/>
                <Route path = "/dashboard" element = {<ProtectedRoute><DashboardPage/></ProtectedRoute>}/>
                <Route path = "/tarots" element = {<ProtectedRoute><TarotPage/></ProtectedRoute>}/>
                <Route path = "/tarots/skeleton" element = {<ProtectedRoute><TarotPage skeleton = {true}/></ProtectedRoute>}/>
                <Route path = "/dreams" element = {<ProtectedRoute><DreamPage/></ProtectedRoute>}/>
                <Route path = "/dreams/skeleton" element = {<ProtectedRoute><DreamPage skeleton = {true}/></ProtectedRoute>}/>
                <Route path = "/admin/login" element = {<AdminLoginPage/>}/>
                <Route path = "/admin/dashboard" element = {<AdminDashboard/>}/>
                <Route path = "/admin/transactions" element = {<Transactions/>}/>
                <Route path = "/admin/users" element = {<Users/>}/>
                <Route path = "/admin/dreams" element = {<Dreams/>}/>
                <Route path = "/admin/tarots" element = {<Tarots/>}/>
                <Route path = "/admin/promocodes" element = {<PromoCodes/>}/>
                <Route path = "/admin/pricing" element = {<Pricing/>}/>
                <Route path = "*" element = {<Landing/>}/>
            </Routes>
        </Router>
    );
}

export default App;