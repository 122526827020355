import React, { useEffect,  useRef, useState } from 'react'
import styles from "../css/LoadingGame.module.css"
import MysticLady from "../media/mysticLady.png"
import api from '../../../utils/api'
import cards from "../data/cardInfo.json"
import spread_data from "../data/spread.json"

const spreads = {...spread_data['three_cards'],...spread_data['five_cards']}

const LoadingGame = ({startGame,setID,handleResume,id = null}) => {
    const [progress,setProgress] = useState(0)
    const [starting,setStarting] = useState(false)
    const fetching = useRef(true)

    useEffect(()=>{
        let random_number = Math.floor(Math.random() * 10 + 4)
        let index = 0
        var interval
        interval = setInterval(()=>{
            setProgress((preVal)=>{
                return index === 0 ? 0 : preVal + random_number <= 100 ? preVal + random_number : 100
            })
            index++
        },50)

        return () => {
            clearInterval(interval)
        }
    },[])

    useEffect(()=>{
        if (id === null){
            if (progress === 100){
                setTimeout(()=>{
                    setStarting(true)
                    if ('intentions' in localStorage){
                        localStorage.removeItem('intentions')
                    }
                    // api.post(`/tarotGame/add`)
                    // .then((res)=>{
                    //     setID(res.data.id)
                    //     startGame()
                    // })
                    startGame()
                },400)
            }
        }
        else{
            if (fetching.current){
                fetching.current = false
                let startTime = performance.now()
                api.get(`/tarotReadings/${id}`)
                .then((res)=>{
                    let endTime = performance.now()
                    let duration = endTime - startTime
                    setTimeout(()=>{
                        handleResume({
                            ...res.data,
                            cards: res.data.cards.map(item => ({...cards[item.card_id],is_reversed:item.is_reversed})),
                            spread: spreads[res.data.spread]
                        })
                    },duration < 2000 ? 2000 - duration : 0)
                })
            }
        }
    },[progress,startGame,setID,id,handleResume])

  return (
    <div className = {styles.container}>
        <div className = {`${styles.box} ${starting ? styles.starting : ""}`}>
            <div className = {styles.content}>
                <div className = {`${styles.mysticCircle} ${styles.front}`}>
                    <div className = {styles.innerCircle}>
                        <img src = {MysticLady} alt = ''/>
                    </div>
                </div>
                <div className = {`${styles.mysticCircle} ${styles.back}`}>
                    <div className = {styles.innerCircle}>
                        <img src = {MysticLady} alt = ''/>
                    </div>
                </div>
            </div>
        </div>
        {!starting && <div className = {styles.loader}>
            <div className = {styles.innerLoader}>
                <div className = {`${styles.progressBar} ${progress === 100 ? styles.completed : (progress <= 4 ? styles.min : (progress >= 95 ? styles.max : ""))}`} style = {{ width : `${progress}%`}}></div>
                <div className = {styles.progress}>Loading - {progress}%</div>
            </div>
        </div>}
    </div>
  )
}

export default LoadingGame