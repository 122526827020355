import React, { useEffect, useRef, useState } from 'react'
import styles from "../css/NavigationBar.module.css"
import { RiHome4Fill } from "react-icons/ri";
import { GiCardRandom } from "react-icons/gi";
import { IoMoon } from "react-icons/io5";
import { IoExit } from "react-icons/io5";
import { LuArrowRightToLine } from "react-icons/lu";
import { FiMenu } from "react-icons/fi";
import { NavLink, useNavigate } from 'react-router-dom';
import api from '../../../utils/api';
import { clearUserData } from '../../../utils/auth';

const NavigationBar = ({page,loading}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [showNav,setShowNav]  = useState(false)
    const navbar = useRef(null)
    const navigate = useNavigate()

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
        if (window.innerWidth <= 600 && windowWidth > 600) {
          setShowNav(false);
        }
      };
      const handleClick = (e) => {
        if (window.innerWidth <= 600 && showNav && !navbar?.current?.contains(e.target)){
            setShowNav(false)
        }
      }
      window.addEventListener('resize', handleResize);
      window.addEventListener("mousedown",handleClick)
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener("mousedown",handleClick)
        }
    }, [windowWidth,showNav]);

    const logout = () => {
      let tokens = JSON.parse(localStorage.getItem('tokens',{}))
      api.post('/logout',{refresh_token:tokens.refresh_token})
      .finally(()=>{
          clearUserData()
          navigate('/login')
      })
    }

  return (
    <div className = {`${styles.navWrapper} ${!showNav && styles.hide}`}>
        <div className = {styles.navBar} ref = {navbar}>
            <div className = {styles.closeIcon} onClick = {()=>setShowNav(false)}><LuArrowRightToLine/></div>
            <div className = {styles.appName}>Astrooo</div>
            <div className = {`${styles.appName} ${styles.min}`}>A</div>
            <div className = {styles.navItems}>
                <NavLink to = "/dashboard" className = {`${styles.navItem} ${page === "dashboard" && styles.selected}`}>
                    <div className = {styles.navIcon}><RiHome4Fill/></div>
                    <div className = {styles.navText}>Dashboard</div>
                </NavLink>
                <NavLink to = "/tarots" className = {`${styles.navItem} ${page === "tarots" && styles.selected}`}>
                    <div className = {styles.navIcon}><GiCardRandom/></div>
                    <div className = {styles.navText}>Tarots</div>
                </NavLink>
                <NavLink to = "/dreams" className = {`${styles.navItem} ${page === "dreams" && styles.selected}`}>
                    <div className = {styles.navIcon}><IoMoon/></div>
                    <div className = {styles.navText}>Dreams</div>
                </NavLink>
                <div className = {styles.navItem} onClick = {()=>logout()}>
                    <div className = {styles.navIcon}><IoExit/></div>
                    <div className = {styles.navText}>Logout</div>
                </div>
            </div>
        </div>
        {!loading && <div className = {styles.navMenu}>
            <div className = {styles.menuIcon} onClick = {()=>setShowNav(!showNav)}><FiMenu/></div>
        </div>}
    </div>
  )
}

export default NavigationBar