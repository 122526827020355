import React from 'react'
import styles from "../css/LoadingReadings.module.css"

const LoadingReadings = () => {
  return (
    <div className = {styles.wrapper}>
        <div className = {styles.box}>
            <div className = {styles.header}>
                <div className = {styles.title}></div>
                <div className = {styles.searchBox}></div>
                <div className = {styles.profileArea}></div>
            </div>
            <div className = {styles.subHeader}>
                <div className = {styles.lengthBox}></div>
                <div className = {styles.btns}>
                    <div className = {styles.sortBtn}></div>
                    <div className = {styles.filterBtn}></div>
                    <div className = {styles.addBtn}></div>
                </div>
            </div>
            <div className = {`${styles.searchWrapper} ${styles.min}`}>
                <div className = {`${styles.searchBox} ${styles.min}`}></div>
            </div>
            <div className = {styles.grid}>
                <div className = {styles.dreams}>
                    {[...Array(7).keys()].map((_,index)=>(
                        <div className = {styles.dreamBox}>
                            <LoadingDream key = {index}/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export const LoadingDream = () => {
    return (
        <div className = {styles.dreamContainer}>
            <div className = {styles.imageSection}></div>
            <div className = {styles.content}>
                <div className = {styles.dateBox}></div>
                <div className = {styles.dreamContent}>
                    <div className = {styles.titleBox}></div>
                    <div className = {styles.dreamLine}></div>
                    <div className = {styles.dreamLine}></div>
                </div>
            </div>
            <div className = {styles.footer}>
                <div className = {styles.dateDiv}></div>
            </div>
        </div>
    )
}

export default LoadingReadings