import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from "../css/ViewTarots.module.css"
import spread_data from "../../tarotGame/data/spread.json"
import images from "../../tarotGame/data/images.json"
import tarot_data from "../../tarotGame/data/cardInfo.json"
import { FaEllipsis } from "react-icons/fa6";
import { IoIosBookmarks } from "react-icons/io";
import { BsQuestionDiamondFill } from "react-icons/bs";
import { TfiAlarmClock } from "react-icons/tfi";
import { HiMiniArrowLongRight } from "react-icons/hi2";
import { TbChartPieFilled } from "react-icons/tb";
import { TbTargetArrow } from "react-icons/tb";
import { GiCardRandom } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md";
import { FaPlay } from "react-icons/fa";
import { BsHourglassTop } from "react-icons/bs";
import { GrYoga } from "react-icons/gr";
import { GrAction } from "react-icons/gr";
import { TbCirclesRelation } from "react-icons/tb";
import { FaPlaneDeparture } from "react-icons/fa6";
import { GiProgression } from "react-icons/gi";
import { MdAttachMoney } from "react-icons/md";
import { MdHealthAndSafety } from "react-icons/md";
import { PiLightbulbFill } from "react-icons/pi";
import { BiSolidBriefcase } from "react-icons/bi";
import { FaTree } from "react-icons/fa6";
import { PiTreeStructureFill } from "react-icons/pi";
import { PiSunHorizonFill } from "react-icons/pi";
import { MdSmartDisplay } from "react-icons/md";
import { GiArchiveResearch } from "react-icons/gi";
import { RiAlarmWarningFill } from "react-icons/ri";
import { GiBrain } from "react-icons/gi";
import { PiNumberSquareFiveFill } from "react-icons/pi";
import api from '../../../utils/api'
import ReactMarkdown from 'react-markdown';
import TarotMenu from './TarotMenu'
import CrashModal from '../../errors/js/CrashModal'
import NotFoundTarot from '../../errors/js/NotFoundTarot'


const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const spreads = Object.assign({}, spread_data['three_cards'], spread_data['five_cards'])
export const icons = { 
  "Past_Present_Future": <BsHourglassTop/>,
  "Mind_Body_Spirit": <GrYoga/>,
  "Situation_Action_Outcome": <GrAction/>,
  "Strengths_Weaknesses_Advice": <GiArchiveResearch/>,
  "You_OtherPerson_Relationship": <TbCirclesRelation/>,
  "Stop_Start_Continue": <MdSmartDisplay/>,
  "Option1_Option2_Outcome": <PiTreeStructureFill/>,
  "Morning_Afternoon_Evening": <PiSunHorizonFill/>,
  "Obstacle_Action_Resolution": <RiAlarmWarningFill/>,
  "Thoughts_Feelings_Actions": <GiBrain/>,
  "Basic_Five_Card": <PiNumberSquareFiveFill/>,
  "Elements_Spread": <FaTree/>,
  "Career_Focus": <BiSolidBriefcase/>,
  "Relationship_Status": <TbCirclesRelation/>,
  "Spiritual_Path": <GrYoga/>,
  "Decision_Making": <PiLightbulbFill/>,
  "Health_Wellness": <MdHealthAndSafety/>,
  "Financial_Overview": <MdAttachMoney/>,
  "Personal_Growth": <GiProgression/>,
  "Travel_Plans": <FaPlaneDeparture/>
}

const ViewTarots = ({id,handleMenu,close}) => {
    const [tarot,setTarot] = useState(null)
    const [notFound,setNotFound] = useState(false)
    const [crashError,setCrashError] = useState("")
    const box = useRef(null)
    const fetching = useRef(true)

  useEffect(()=>{
    if (fetching.current){
        fetching.current = false
        let startTime = performance.now()
        api.get(`/tarots/game/${id}`)
        .then((res)=>{
            let endTime = performance.now()
            let duration = endTime - startTime
            setTimeout(()=>{
                setTarot(res.data)
            },duration < 1000 ? 1000 - duration : 0)
        })
        .catch((err)=>{
          if (err.response?.status === 404){
            setNotFound(true)
          }
          else{
            setCrashError("It looks like something unexpected occured when trying to retrieve the selected tarot reading.")
          }
        })
    }
  },[close,id])

  const getDateString = useCallback((date)=>{
    let date_field
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0)
    const inputDate = new Date(date)
    inputDate.setHours(0, 0, 0, 0)
    if (inputDate.getTime() === today.getTime()) {
        date_field = "Today";
    }
    else if (inputDate.getTime() === yesterday.getTime()) {
        date_field = "Yesterday";
    }
    else {
        date_field = months[inputDate.getMonth()] + " " + inputDate.getDate() + ", " + inputDate.getFullYear()
    }

    const hours = new Date(date).getHours().toString().padStart(2, '0');
    const minutes = new Date(date).getMinutes().toString().padStart(2, '0');

    return date_field + " at " + hours + ":" + minutes
    },[])

const [showMenu,setShowMenu] = useState(false)
  return (
    crashError ? <CrashModal msg = {crashError} close = {()=>close()}/> : notFound ? <NotFoundTarot close = {()=>close()}/> : <div className = {styles.wrapper}>
        <div className = {styles.box} ref = {box}>
            <header>
                <div className = {styles.title}>Tarot View</div>
                <div className = {styles.btns}>
                    {tarot && <div className = {styles.resumeBtn} onClick = {()=>handleMenu("resume")}><div className = {styles.resumeIcon}><FaPlay/></div></div>}
                    {tarot && <div className = {styles.menuWrapper}>
                        <div className = {`${styles.menuIcon} ${showMenu && styles.active}`} onClick = {()=>setShowMenu(!showMenu)}><FaEllipsis/></div>
                        {showMenu && <TarotMenu viewModal = {true} handleMenu = {handleMenu} close = {()=>setShowMenu(false)}/>}
                    </div>}
                    <div className = {styles.closeIcon} onClick = {()=>close()}><IoClose/></div>
                </div>
            </header>
            {tarot ? <div className = {styles.content}>
                <div className = {styles.dateItem}>
                    <div className = {styles.dateTitle}>Creation Date</div>
                    <div className = {styles.dateValue}>{getDateString(tarot.created_at)}</div>
                </div>
                <div className = {styles.section}>
                    <div className = {styles.titleBox}>
                        <div className = {styles.sectionIcon}></div>
                        <div className = {styles.sectionTitle}>Selected Spread</div>
                        <div className = {styles.icon}><TbChartPieFilled/></div>
                    </div>
                    <div className = {styles.spreadWrapper}>
                        <div className = {styles.spreadBox}>
                            <div className = {styles.spreadIcon}>{icons[tarot.spread]}</div>
                            <div className = {styles.spreadName}>{spreads[tarot.spread].name}</div>
                        </div>
                        <div className = {styles.spreadDesc}><div className = {styles.spreadArrow}><MdOutlineSubdirectoryArrowRight/></div>{spreads[tarot.spread].description}</div>
                    </div>
                </div>
                {tarot.intention && <div className = {styles.section}>
                    <div className = {styles.titleBox}>
                        <div className = {styles.sectionTitle}>Intention</div>
                        <div className = {styles.icon}><TbTargetArrow/></div>
                    </div>
                    <div className = {styles.text}>{tarot.intention}</div>
                </div>}
                <div className = {styles.section}>
                    <div className = {styles.titleBox}>
                        <div className = {styles.sectionTitle}>Card Results</div>
                        <div className = {styles.icon}><GiCardRandom/></div>
                    </div>
                    <div className = {styles.cards}>
                        {tarot.cards.map((card,index)=>(
                            <div className = {styles.cardBox} key = {index}> 
                                <div className = {styles.imageWrapper}><div className = {styles.cardImage} style = {{ backgroundImage: `url(${images[card.card_id].src})`}}></div></div>
                                <div className = {styles.cardContent}>
                                    <div className = {styles.cardName}>{tarot_data[card.card_id].dataLabel}</div>
                                    <div className = {styles.cardDesc}>{tarot_data[card.card_id].uprightDescription.long}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {tarot.interpertation && <div className = {styles.section}>
                    <div className = {styles.titleBox}>
                        <div className = {styles.sectionTitle}>Interperation</div>
                        <div className = {styles.icon}><IoIosBookmarks/></div>
                    </div>
                    <div className = {styles.text}><ReactMarkdown>{tarot.interpertation}</ReactMarkdown></div>
                </div>}
                {tarot.questions.length > 0 && <div className = {styles.section}>
                    <div className = {styles.titleBox}>
                        <div className = {styles.sectionTitle}>Follow-up Questions</div>
                        <div className = {styles.icon}><BsQuestionDiamondFill/></div>
                    </div>
                    <div className = {styles.questions}>
                        {tarot.questions.map((instance,index)=>(
                            <div className = {styles.questionBox} key = {index}>
                                <div className = {styles.question}>{instance.question}</div>
                                <div className = {styles.dateBox}>
                                    <div className = {styles.timeIcon}><TfiAlarmClock/></div>
                                    <div className = {styles.date}>{getDateString(instance.asked_at)}</div>
                                </div>
                                <div className = {styles.answer}><div className = {styles.arrowIcon}><HiMiniArrowLongRight/></div>
                                    <ReactMarkdown>{instance.answer}</ReactMarkdown>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}
            </div> : <div className = {styles.fetchingLoader}><div className = {styles.spinner}></div></div>}
        </div>
    </div>
  )
}

export default ViewTarots