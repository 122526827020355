import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from "../css/SortMenu.module.css"
import { MdOutlineSortByAlpha } from "react-icons/md";
import { GiNightSleep } from "react-icons/gi";
import { MdOutlineEditCalendar } from "react-icons/md";
import { LuCalendarPlus } from "react-icons/lu";
import { BsQuestionDiamond } from "react-icons/bs";
import { TbArrowsSort } from "react-icons/tb";
import { TiArrowUnsorted } from "react-icons/ti";

const sort_fields = {
    title: {icon:<MdOutlineSortByAlpha/>,name:"Title"},
    dreamt_at: {icon:<GiNightSleep/>,name:"Dream Date"},
    created_at: {icon:<LuCalendarPlus/>,name:"Creation Date"},
    updated_at: {icon:<MdOutlineEditCalendar/>,name:"Update Date"},
    num_questions: {icon:<BsQuestionDiamond/>,name:"Number of questions"},
}
const SortMenu = ({sortValue,onChangeSortValue,close}) => {
    const [sortObject,setSortObject] = useState(Object.assign({},{...sortValue}))
    const [showDropDown,setShowDropDown] = useState(false)
    const menu = useRef(null)
    const fieldMenu = useRef(null)

    const handleResize = useCallback(() => {
        if (showDropDown){
          setShowDropDown(false)
        }
      },[showDropDown])

    useEffect(()=>{

        const handleClick = (e) => {
            if (!menu.current.parentNode.contains(e.target)){
                close()
            }
            if (!fieldMenu.current.contains(e.target) && showDropDown){
                setShowDropDown(false)
            }
        }
        window.addEventListener("mousedown",handleClick)
        window.addEventListener("resize",handleResize)
        return () => {
            window.removeEventListener("mousedown",handleClick)
            window.removeEventListener("resize",handleResize)
        }
    },[close,showDropDown,handleResize])

    const save = () => {
        onChangeSortValue(sortObject)
        close()
    }

    const selectField = (key) => {
        setSortObject({...sortObject,field:key})
        setShowDropDown(false)
    }

    const setPositionMenu = useCallback((el)=>{
        const button = el?.previousElementSibling
        if (el && button){
          el.style.maxHeight = ""
          if (window.innerHeight - button.getBoundingClientRect().bottom > button.getBoundingClientRect().top){
            el.style.top = `${button.getBoundingClientRect().bottom + 5}px`
            el.style.maxHeight = `${window.innerHeight - 10 - button.getBoundingClientRect().bottom}`
          }
          else{
            const top = button.getBoundingClientRect().top - el.offsetHeight < 5 ? 5 : button.getBoundingClientRect().top - el.offsetHeight - 5
            el.style.top = `${top}px`
            el.style.maxHeight = `${top === 5 ? button.getBoundingClientRect().top - 10 : button.getBoundingClientRect().top - 5}px`
          }
          el.style.left = `${button.getBoundingClientRect().left}px`
          el.style.width = `${button.offsetWidth}px`
        }
      },[])

  return (
    <div className = {styles.menu} ref = {menu}>
        <div className = {styles.header}>
            <div className = {styles.headerIcon}><TbArrowsSort/></div>
            <div className = {styles.headerTitle}>Sort By</div>
        </div>
        <div className = {styles.content}>
            <div className = {styles.section}>
                <div className = {styles.label}>Field</div>
                <div className = {styles.selectorBox} ref = {fieldMenu}>
                    <div className = {`${styles.selectorInput} ${showDropDown && styles.active}`} onClick = {()=>setShowDropDown(!showDropDown)}>
                        <div className = {styles.valueBox}>
                            <div className = {styles.icon}>{sort_fields[sortObject.field].icon}</div>
                            <div className = {styles.value}>{sort_fields[sortObject.field].name}</div>
                        </div>
                        <div className = {styles.arrow}><TiArrowUnsorted/></div>
                    </div>
                    {showDropDown && <div className = {styles.fieldMenu} ref = {setPositionMenu}>
                        {Object.keys(sort_fields).map((key,index)=>(
                        <div className = {`${styles.fieldItem} ${key} ${sortObject.field === key && styles.selected}`} key = {index} onClick = {()=>selectField(key)}>
                            <div className = {styles.fieldIcon}>{sort_fields[key].icon}</div>
                            <div className = {styles.fieldName}>{sort_fields[key].name}</div>
                        </div>
                        ))}
                    </div>}
                </div>
            </div>
            <div className = {styles.section}>
                <div className = {styles.label}>Order</div>
                <div className = {styles.sectionContent}>
                    <div className = {styles.orderItem}>
                        <div className = {`${styles.checkCircle} ${sortObject.order === "asc" && styles.checked}`} onClick = {()=>setSortObject({...sortObject,order:sortObject.order === "asc" ? "desc": "asc"})}>
                            {sortObject.order === "asc" && <div className = {styles.innerCircle}></div>}
                        </div>
                        <div className = {styles.orderText}>Ascending</div>
                    </div>
                    <div className = {styles.orderItem}>
                        <div className = {`${styles.checkCircle} ${sortObject.order === "desc" && styles.checked}`} onClick = {()=>setSortObject({...sortObject,order:sortObject.order === "desc" ? "asc": "desc"})}>
                            {sortObject.order === "desc" && <div className = {styles.innerCircle}></div>}
                        </div>
                        <div className = {styles.orderText}>Descending</div>
                    </div>
                </div>
            </div>
        </div>
        <div className = {styles.footer}>
            <button type = "button" className = {styles.cancelBtn} onClick = {()=>close()}>Cancel</button>
            <button type = "button" className = {styles.saveBtn} disabled = {JSON.stringify(sortObject) === JSON.stringify(sortValue)} onClick = {()=>save()}>Save</button>
        </div>
    </div>
  )
}

export default SortMenu