import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from "../css/SpreadSelection.module.css"
import Ribbon from "../media/spreadBanner.png"
import Stone from "../media/stone2.PNG"
import { debounce } from "lodash"
import { BiSolidDownArrow, BiSolidLeftArrow, BiSolidRightArrow, BiSolidUpArrow } from 'react-icons/bi'
import data from "../data/spread.json"
import SpreadImage from "../media/stepCard.png"
import api from '../../../utils/api'
import SavingData from './SavingData'

const SpreadSelection = ({game_id,tarotData,handleTarotData,goNext}) => {
  const [width,setWidth] = useState(window.innerWidth)
  const spreads = [...Object.values(data['three_cards']),...Object.values(data['five_cards'])]
  const spread_keys = [...Object.keys(data['three_cards']),...Object.keys(data['five_cards'])]
//   const [activeSpread,setActiveSpread] = useState(!tarotData.spread ? 0: spreads.findIndex(spread => spread.tag === tarotData.spread.tag))
  const [activeSpread,setActiveSpread] = useState(null)
  const spreadRefs = useRef([])
  const spreadsArea = useRef(null)
  const [processing,setProcessing] = useState(false)
  const [scrolling,setScrolling] = useState({direction:"down",prevOffset:0})
  const box = useRef(null)
  const spreadContainer = useRef(null)

  useEffect(() => {
      let container = box.current

      const handleScroll = () => {
        if (box.current) {
            const currentScrollPosition = box.current.scrollTop;
            var direction = currentScrollPosition > scrolling.prevOffset ? 'down' : 'up'
            if (direction === "up" && currentScrollPosition === 0){
                direction = "down"
            }
            else if (direction === "down" && currentScrollPosition >= (box.current.scrollHeight -  box.current.clientHeight)){
                direction = "up"
            }
            setScrolling((prevState)=>{
                let copy = {...prevState}
                copy = {direction, prevOffset: currentScrollPosition}
                return copy
            })
        }
      }

      if (box.current) {
          box.current.addEventListener('scroll', handleScroll)
      }

      return () => {
          if (container) {
          container.removeEventListener('scroll', handleScroll);
          }
      };
  }, [scrolling])


    const handleResize = useCallback(
        debounce(()=>{
        setWidth(window.innerWidth)
        },40)
    ,[])

    useEffect(()=>{
    window.addEventListener("resize",handleResize)
    return () => {
        window.removeEventListener("resize",handleResize)
    }
    })

    const currentSpreads = useMemo(()=>{
        let spreads = []
        spreads = spreads.fill(null,0,7)
        for (let i = 2; i >= 0; i--){
            if (i === 2){
                spreads[i] = activeSpread ? activeSpread - 1 : 19
            }
            else{
                spreads[i] = spreads[i+1] ? spreads[i+1]  - 1 : 19
            }
        }

        spreads[3] = activeSpread

        for (let i = 4; i < 7; i++){
            if (i === 4){
                spreads[i] = activeSpread === 19 ? 0 : activeSpread + 1
            }
            else{
                spreads[i] = spreads[i-1] === 19  ? 0 : spreads[i-1] + 1
            }
        }

        return spreads

    },[activeSpread])

const goPrev = () => {
    var new_index
    if (activeSpread === 0){
        new_index = 19
    }
    else{
        new_index = activeSpread - 1
    }

    if (spreadRefs.current[2]){
        spreadRefs.current[2].classList.add(styles["sliding"])
    }
    setTimeout(()=>{
        spreadRefs.current[2].classList.remove(styles["sliding"])
        setActiveSpread(new_index)
        spreadRefs.current[3].classList.add(styles["flipping"])
        setTimeout(()=>{
            spreadRefs.current[3].classList.remove(styles["flipping"])
        },300)
    },300)
}

    // const goNext = () => {
    //     var new_index;
    //     if (activeSpread === 19){
    //         new_index = 0;
    //     }
    //     else{
    //         new_index = activeSpread +1
    //     }

    //     if (spreadRefs.current[4]){
    //         spreadRefs.current[4].classList.add(styles["sliding"])
    //     }
    //     setTimeout(()=>{
    //         spreadRefs.current[4].classList.remove(styles["sliding"])
    //         setActiveSpread(new_index)
    //         spreadRefs.current[3].classList.add(styles["flipping"])
    //         setTimeout(()=>{
    //             spreadRefs.current[3].classList.remove(styles["flipping"])
    //         },300)
    //     },300)
    // }

    const viewSpread = (spread_id,index) => {
        if (activeSpread !== spread_id){
            if (spreadRefs.current[index]){
                spreadRefs.current[index].classList.add(styles["sliding"])
                setTimeout(()=>{
                    spreadRefs.current[index].classList.remove(styles["sliding"])
                    setActiveSpread(spread_id)
                    spreadRefs.current[3].classList.add(styles["flipping"])
                    setTimeout(()=>{
                        spreadRefs.current[3].classList.remove(styles["flipping"])
                    },300)
                },300)
            }
        }
        else{
            let card = spreadRefs.current[index]
            let dragging_element = card.cloneNode(true);
            dragging_element.setAttribute('tabindex', '0');
            dragging_element.style.top = `${card.getBoundingClientRect().top}px`
            dragging_element.style.left = `${card.getBoundingClientRect().left}px`
            dragging_element.classList.add(styles['dragging'])

            dragging_element.setAttribute('id',`dragging${index}`)
            document.body.appendChild(dragging_element)        

            dragging_element.addEventListener("focus",function(){
                let spread_card = document.getElementById("spread-area")
                let final_top  = spread_card.getBoundingClientRect().top
                let final_left = spread_card.getBoundingClientRect().left
                let final_width  = spread_card.offsetWidth
                let final_height = spread_card.offsetHeight
                dragging_element.style.top = `${final_top}px`
                dragging_element.style.left = `${final_left}px`
                dragging_element.style.width = `${final_width}px`
                dragging_element.style.height = `${final_height}px`
                dragging_element.setAttribute('tabindex', '-1');
            })

            dragging_element.focus()

            let startTime = performance.now()
            let data = {spread:spread_keys[activeSpread]}
            var processed = false
            api.put(`/tarotGame/${game_id}/selectSpread`,data)
            .then((_)=>{
                let endTime = performance.now()
                let duration = endTime - startTime
                setTimeout(()=>{
                    let stored_data = 'intentions' in localStorage ? JSON.parse(localStorage.getItem('intentions')) : {}
                    let intention = stored_data[spreads[activeSpread].tag] ? stored_data[spreads[activeSpread].tag] : ""
                    handleTarotData({...tarotData,spread:spreads[activeSpread],intention:intention})
                    // }) 
                    processed = true
                    setProcessing(false)
                },duration < 700 ? 700 - duration : 0)
            })
            .catch((err)=>{
                console.log("Error = ",err)
                setProcessing(false)
            })

        
            setTimeout(()=>{
                document.body.removeChild(dragging_element)
                if (!processed){
                    setProcessing(true)
                }
                // let stored_data = 'intentions' in localStorage ? JSON.parse(localStorage.getItem('intentions')) : {}
                // if (tarotData.spread){
                //     localStorage.setItem("intentions",JSON.stringify({...stored_data,[tarotData.spread.tag]:tarotData.intention}))
                // }
                // let intention = stored_data[spreads[activeSpread].tag] ? stored_data[spreads[activeSpread].tag] : ""
                // handleTarotData((prevData)=>{
                //     return {...prevData,spread:spreads[activeSpread],intention:intention}
                // })
                // let startTime = performance.now()
                // let data = {spread:spread_keys[activeSpread]}
                // console.log("SPREAD = ",data)
                // api.put(`/tarotGame/${game_id}/selectSpread`,data)
                // .then((_)=>{
                //     let endTime = performance.now()
                //     let duration = endTime - startTime
                //     setTimeout(()=>{
                //         let intention = stored_data[spreads[activeSpread].tag] ? stored_data[spreads[activeSpread].tag] : ""
                //         handleTarotData((prevData)=>{
                //             return {...prevData,spread:spreads[activeSpread],intention:intention}
                //         })  
                //     },duration < 700 ? 700 - duration : 0)
                // })
                // .catch((err)=>{
                //     console.log("Error = ",err)
                // })
            },700)
        }
    }

    const handleRef = useCallback((el,index)=>{
        if (el && !spreadRefs.current.includes(el)){
            if (index === 3){
                el.classList.add(styles["flipping"])
                setTimeout(()=>{
                    el.classList.remove(styles["flipping"])
                },600)
            }
            spreadRefs.current[index] = el
        }

    },[width])

    const selectSpread = (spread,index) => {
        // let processed = false
        const rect = spreadContainer.current.parentNode.getBoundingClientRect();
        console.log("HEIGHT = ",rect)
        const containerRect = spreadContainer.current.getBoundingClientRect()
        const cardRect = spreadRefs.current[index].getBoundingClientRect();
        const centerX = (containerRect.width / 2) - (1.5 * cardRect.width / 2);
        console.log("WIFTH = ",containerRect.width,  1.5 * cardRect.width)
        const centerY = (rect.height / 2) - (1.5 * cardRect.height / 2);

        spreadRefs.current[index].style.left = `${cardRect.left - containerRect.left}px`;
        spreadRefs.current[index].style.top = `${cardRect.top - containerRect.top}px`;
        // spreadRefs.current[index].style.width = `${spreadRefs.current[index].offsetWidth}px`;
        // spreadRefs.current[index].style.height = `${spreadRefs.current[index].offsetHeight}px`;

        spreadRefs.current[index].classList.add(styles.active)
        box.current.classList.add(styles.selecting)
  
        spreadRefs.current[index].style.left = `${centerX}px`;
        spreadRefs.current[index].style.top = `${centerY}px`;
        spreadRefs.current[index].children[0].classList.add(styles.expanding)

        setTimeout(()=>{
            handleTarotData({...tarotData,spread:spread})
            goNext()
            // spreadRefs.current[index].style.left = `${-2 * spreadContainer.current.offsetWidth / 100}px`;
            // spreadRefs.current[index].style.top = `${0}px`;
            // spreadRefs.current[index].children[0].classList.add(styles.minimizing)
            // setTimeout(()=>{
            //     handleTarotData({...tarotData,spread:spread})
            // console.log("going next = ",goNext) 
            //  goNext()
            // },1800)
        },100)
        // spreadRefs.current[index].style.width = `${1.5 * spreadRefs.current[index].offsetWidth}px`;
        // spreadRefs.current[index].style.height = `${1.5 * spreadRefs.current[index].offsetHeight}px`;

        // setActiveSpread(spread)
        // const startTime = performance.now()
        // api.put(`/tarotGame/${game_id}/selectSpread`,data)
        // .then((_)=>{
        //     let endTime = performance.now()
        //     let duration = endTime - startTime
        //     setTimeout(()=>{
        //         let stored_data = 'intentions' in localStorage ? JSON.parse(localStorage.getItem('intentions')) : {}
        //         let intention = stored_data[spreads[activeSpread].tag] ? stored_data[spreads[activeSpread].tag] : ""
        //         handleTarotData((prevData)=>{
        //             return {...prevData,spread:spreads[activeSpread],intention:intention}
        //         }) 
        //         // processed = true
        //         setProcessing(false)
        //     },duration < 700 ? 700 - duration : 0)
        // })
        // .catch((err)=>{
        //     console.log("Error = ",err)
        //     setProcessing(false)
        // })

    
        // setTimeout(()=>{
        //     document.body.removeChild(dragging_element)
        //     if (!processed){
        //         setProcessing(true)
        //     }
        // },)
    }

    const addSpreadRefs = useCallback((el,index)=>{
        if (el){
            spreadRefs.current[index] = el
        }
    },[])


  return (
    <div className = {styles.wrapper} ref = {box}>
        <div className = {styles.box}>
            <div className = {styles.gameTitle}>Tarots</div>
            <div className = {styles.menuBtn}>Menu</div>
        <div className = {styles.container} ref = {spreadContainer}>
            {spreads.map((spread,index)=>(
                <div className = {`${styles.spreadWrapper} ${activeSpread && activeSpread?.name !== spread.name ? styles.fade : ""}`} key = {index} onClick = {()=>selectSpread(spread,index)} ref = {(el)=>addSpreadRefs(el,index)}>
                    <div className = {styles.spreadBox}>
                        <div className = {styles.front} style = {{ backgroundImage: `url(${SpreadImage})`}}>
                            <div className = {styles.spreadRep}>Spread</div>
                            <div className = {styles.title}>{spread.name}</div>
                        </div>
                        <div className = {styles.back} style = {{ backgroundImage: `url(${SpreadImage})`}}>
                            <div className = {styles.spreadRep}>Spread</div>
                            <div className = {styles.desc}>{spread.description}</div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        <div className = {styles.arrow}  style = {{ backgroundImage: `url(${Stone})`}}>{scrolling.direction === "up" ? <BiSolidUpArrow/> : <BiSolidDownArrow/>}</div>
      {/* <div className = {styles.titleWrapper}>
        <div className = {styles.titleBox} style = {{ backgroundImage: `url(${Ribbon})`}}><div className = {styles.title}>Spreads</div></div>
      </div>
        <div className = {styles.content}>
            <div className = {styles.prevArrow} onClick = {()=>goPrev()} style = {{ backgroundImage: `url(${Stone})`}}><BiSolidLeftArrow/></div>
            <div className = {styles.options}>
                <div className = {styles.spreads} ref = {spreadsArea}>
                    {currentSpreads.map((spread_id,index)=>(
                        <div className = {`${styles.spreadWrapper} ${index === 3 ? styles.active : ""}`} key = {index} onClick = {()=>viewSpread(spread_id,index)} ref = {(el)=>handleRef(el,index)}>
                            <div className = {styles.spreadBox}>
                                <div className = {styles.spreadInner}>
                                    <div className = {styles.front} style = {{ backgroundImage: `url(${SpreadImage})`}}></div>
                                    <div className = {styles.back} style = {{ backgroundImage: `url(${SpreadImage})`}}>
                                        <div className = {styles.spreadContent}>
                                            <div className = {styles.spreadDesc}>{spreads[spread_id].description}</div>
                                            <div className = {`${styles.spreadName} ${ spreads[spread_id].tag === "self_assessment" ? styles.min : "" }`}>{spreads[spread_id].name}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className = {styles.nextArrow} onClick = {()=>goNext()} style = {{ backgroundImage: `url(${Stone})`}}><BiSolidRightArrow/></div>
        </div>  */}
        {processing && <SavingData text = "Saving Selected Spread"/>}
        </div>
    </div>
  )
}

export default SpreadSelection